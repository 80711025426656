import React from 'react';
import config from '../../config';
import ProgramOfferedBooking from './ProgramOfferedBooking';

function ProgramOfferedOnline(props) {
  const { values, style, priceValidators, intl } = props;
  const oneOnOneHourlyMessage = intl.formatMessage({
    id: 'EditListingExperienceForm.oneOnOneHourlyOnlineLabel',
  });
  return (
    <div>
      <h3>{oneOnOneHourlyMessage}</h3>
      {config.custom.oneInOneOnline.map(program => (
        <ProgramOfferedBooking
          label={program.label}
          id={program.key}
          values={values}
          style={style}
          priceValidators={priceValidators}
        />
      ))}
    </div>
  );
}

export default ProgramOfferedOnline;
