import React from 'react';
import css from './MainContentSection.module.css';
import local_program from './Images/local_program.JPG';
import group_experience from './Images/group_experience.JPG';
import online_classes from './Images/online_classes.JPG';
import recieve_payment from './Images/recieve_payments.JPG';
import { useIntl } from 'react-intl';
import image1 from './ParentImage/parent_image1.JPG';
import image2 from './ParentImage/parent_image2.JPG';
import image3 from './ParentImage/parent_image3.JPG';
import image4 from './ParentImage/parent_image4.JPG';
import image5 from './ParentImage/parent_image5.JPG';
const MainContentSection = props => {
  const { educator = false } = props;
  const intl = useIntl();
  return educator ? (
    <div className={css.mainContentSection}>
      <div className={css.mainContainer}>
        <div className={css.imageContainer}>
          <img src={local_program} alt="local_program" className={css.image} />
        </div>
        <div className={css.textContainer}>
          <p className={css.text}>
            {intl.formatMessage({
              id: 'FeaturesForEducator.paragraphTextOne',
            })}
          </p>
        </div>
      </div>
      <div className={css.mainContainerReverse}>
        <div className={css.textContainer}>
          <p className={css.text}>
            {intl.formatMessage({
              id: 'FeaturesForEducator.paragraphTextTwo',
            })}
          </p>
        </div>
        <div className={css.imageContainer}>
          <img src={recieve_payment} alt="recieve_payment" className={css.image} />
        </div>
      </div>
      <div className={css.mainContainer}>
        <div className={css.imageContainer}>
          <img src={group_experience} alt="group_experience" className={css.image} />
        </div>
        <div className={css.textContainer}>
          <p className={css.text}>
            {intl.formatMessage({
              id: 'FeaturesForEducator.paragraphTextThree',
            })}
          </p>
        </div>
      </div>
      <div className={css.mainContainerReverse}>
        <div className={css.textContainer}>
          <p className={css.text}>
            {intl.formatMessage({
              id: 'FeaturesForEducator.paragraphTextFour',
            })}
          </p>
        </div>
        <div className={css.imageContainer}>
          <img src={online_classes} alt="online_classes" className={css.image} />
        </div>
      </div>
    </div>
  ) : (
    <div className={css.mainContentSection}>
      <div className={css.mainContainer}>
        <div className={css.imageContainer}>
          <img src={image1} alt="" className={css.image} />
        </div>
        <div className={css.textContainer}>
          <p className={css.text}>
            {intl.formatMessage({
              id: 'FeaturesForParents.paragraphTextOne',
            })}
          </p>
        </div>
      </div>
      <div className={css.mainContainerReverse}>
        <div className={css.textContainer}>
          <p className={css.text}>
            {intl.formatMessage({
              id: 'FeaturesForParents.paragraphTextTwo',
            })}
          </p>
        </div>
        <div className={css.imageContainer}>
          <img src={image2} alt="" className={css.image} />
        </div>
      </div>
      <div className={css.mainContainer}>
        <div className={css.imageContainer}>
          <img src={image3} alt="" className={css.image} />
        </div>
        <div className={css.textContainer}>
          <p className={css.text}>
            {intl.formatMessage({
              id: 'FeaturesForParents.paragraphTextThree',
            })}
          </p>
        </div>
      </div>
      <div className={css.mainContainerReverse}>
        <div className={css.textContainer}>
          <p className={css.text}>
            {intl.formatMessage({
              id: 'FeaturesForParents.paragraphTextFour',
            })}
          </p>
        </div>
        <div className={css.imageContainer}>
          <img src={image4} alt="" className={css.image} />
        </div>
      </div>
      <div className={css.mainContainer}>
        <div className={css.imageContainer}>
          <img src={image5} alt="group_experience" className={css.image} />
        </div>
        <div className={css.textContainer}>
          <p className={css.text}>
            {intl.formatMessage({
              id: 'FeaturesForParents.paragraphTextFive',
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MainContentSection;
