import moment from 'moment-timezone';
import React from 'react';
import { injectIntl } from 'react-intl';
import { NamedLink, PrimaryButton } from '../../components';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import css from './BookingTimeForm.module.css';
import DiscountInput from './DiscountInput';
const { Money } = sdkTypes;
function EventBooking({
  optionData,
  handleEventBookingSubmit,
  isSubscribed,
  currentUser,
  intl,
  bookingInfoMaybe,
  loadingSpinnerMaybe,
  bookingInfoErrorMaybe,
  values,
  failure,
  success,
  loading,
  updateState,
  coupon,
  showDiscountInput,
  onValidateDiscount,
}) {
  if (!optionData) return null;

  const { startDate, endDate, price, experienceType, priceType, show_date } = optionData;
  const { participants } = values;

  const eventStartDate = new Date(startDate).getTime();
  const currentDate = new Date().getTime();
  const errorMessage = intl.formatMessage({ id: 'ExperienceBooking.errorMessage' });
  if (show_date && currentDate > eventStartDate) {
    return <p className="leading-snug text-red-500">{errorMessage}</p>;
  }
  const submitMessage = intl.formatMessage({ id: 'ExperienceBooking.submitTitle' });
  const tilte = intl.formatMessage({ id: 'ExperienceBooking.dateLabel' });
  const onSubmit = () => {
    if (!optionData.key) return;
    handleEventBookingSubmit(optionData.key, values.participants, optionData);
  };

  return (
    <div className="px-8 md:px-0">
      {/* <div className="flex justify-between m-0">
        <p className={css.label}>Price:</p>
        <p className={css.field}>{formatMoney(intl, new Money(price, config.currency))}</p>
      </div> */}

      <div className="flex justify-between my-1.5">
        {show_date && (
          <>
            <p className={css.label}>{tilte}:</p>
            <p className={css.field}>
              {moment(new Date(startDate)).format('MM-DD-YYYY', 'America/Los_Angeles')} -{' '}
              {moment(new Date(endDate)).format('MM-DD-YYYY', 'America/Los_Angeles')}
            </p>
          </>
        )}
      </div>

      {showDiscountInput && (
        <DiscountInput
          loading={loading}
          onValidateDiscount={onValidateDiscount}
          success={success}
          failure={failure}
          onChange={e => updateState({ coupon: e.target.value })}
          value={coupon}
        />
      )}
      {priceType == 'free' ? (
        <>
          <div className={css.freeBreakdown}>
            <p className={css.label}>Price:</p>
            <p className={css.field}>Free</p>
          </div>
          <div className={css.freeBreakdown}>
            <p className={css.label}>participants:</p>
            <p className={css.field}>{participants}</p>
          </div>
        </>
      ) : (
        <>
          {bookingInfoMaybe}
          {loadingSpinnerMaybe}
          {bookingInfoErrorMaybe}
        </>
      )}
      <PrimaryButton type="button" className="mt-6" onClick={onSubmit}>
        <span>{submitMessage}</span>
      </PrimaryButton>

      {/* <NamedLink className={css.membershipButton} name="MembershipPage">
        <span>Book Experience</span>
      </NamedLink> */}
    </div>
  );
}

export default injectIntl(EventBooking);
