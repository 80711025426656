import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { createSlug } from '../../util/urlHelpers';
import { stripHtml } from 'string-strip-html';
import './BlogCard.css';
import defaultImage from '../../assets/default.png';

const STRAPI_URL = process.env.REACT_APP_STRAPI_BACKEND_URL;

function BlogCard(props) {
  const { id, title, body = '', image, categories, createdAt: created_at, slug, css } = props;
  let sanitizedBody = body.replace('\\', '');
  sanitizedBody = stripHtml(sanitizedBody).result;
  sanitizedBody = sanitizedBody.length > 140 ? sanitizedBody.slice(0, 140) + '...' : sanitizedBody;
  const imageURL = image?.formats?.thumbnail?.url ? image?.formats?.medium?.url : defaultImage;
  return (
    <div className="CardWrapper">
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src={imageURL} alt={title} />
        </div>
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            {categories?.map(c => (
              <p
                key={JSON.stringify(c)}
                className={classNames(
                  'text-sm font-medium text-indigo-600 inline-block mx-2 ml-0',
                  css.categories
                )}
              >
                <Link to={`/c/${c.slug}/${c.id}`} className="hover:underline text-indigo-600">
                  {c.Category}
                </Link>
              </p>
            ))}
            {/* <a href={post.href} className="block mt-2"> */}
            <div className="block mt-2">
              <p className="text-xl font-semibold text-gray-900">
                <Link to={`/post/${slug}`} className="text-gray-900">
                  {title}
                </Link>
              </p>
              <p className="mt-3 text-base text-gray-500">{sanitizedBody}</p>
            </div>
            {/* </a> */}
          </div>
          <div className="mt-2 flex items-center">
            <div>
              <p className="text-sm font-medium text-gray-900">
                <span className="hover:underline text-gray-900">Admin</span>
              </p>
              <div className="flex space-x-1 text-sm text-gray-500">
                <time dateTime="post.datetime">{moment(created_at).format('DD/MM/YYYY')}</time>
                <span aria-hidden="true">&middot;</span>
                <span>{moment(created_at).format('hh:mm A')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
// <div>
//   <img src={`${STRAPI_URL}${Image[0].formats.thumbnail.url}`} />
//   <h2>{Title}</h2>
//   <ul style={{ display: categories?.length > 0 ? 'block' : 'none' }}>
//     {categories?.map(c => <li key={c.Category}>{c.Category}</li>)}
//   </ul>
//   <p>{Body}</p>
// </div>

export default BlogCard;
