import React, { useState, useEffect } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm, EditListingParentDescriptionForm } from '../../forms';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
import css from './EditListingDescriptionPanel.module.css';
import { useDispatch } from 'react-redux';
import { clearPreviousListingData } from '../../containers/EditListingPage/EditListingPage.duck';
import EditListingExperienceDescriptionForm from './EditListingExperienceDescriptionForm';
import EditListingCurriculumForm from './EditListingCurriculumForm';
const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    isExperience,
    isTeacher,
    isCurriculum,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (dispatch && clearPreviousListingData) {
      dispatch(clearPreviousListingData());
    }
  }, []);
  const [initialProps, setInitialProps] = useState({});

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const parentPanelHeading = isPublished ? (
    <FormattedMessage
      id="EditListingParentDescriptionPanel.title"
      values={{
        listingTitle: <ListingLink listing={listing} />,
      }}
    />
  ) : (
    <FormattedMessage id="EditListingParentDescriptionPanel.createListingTitle" />
  );

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{
        listingTitle: <ListingLink listing={listing} />,
      }}
    />
  ) : isExperience ? (
    <FormattedMessage id="EditListingExperienceDescriptionPanel.createListingTitle" />
  ) : isCurriculum ? (
    <FormattedMessage id="EditListingCurriculumDescriptionPanel.createListingTitle" />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );
  const childOptions = data => {
    const result = data?.map(item => ({
      name: item?.name,
      gender: config.custom.gender?.find(k => item.gender === k.key),
      age: item.age,
    }));
    return result;
  };
  const initialValues = React.useMemo(() => {
    if (!initialProps)
      return {
        title,
        description,
      };
    return {
      title,
      description,
      ...initialProps,
    };
  }, [initialProps]);
  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
  return (
    <div className={classes}>
      <h1 className={css.title}>{isTeacher ? panelTitle : parentPanelHeading}</h1>
      {isTeacher ? (
        <>
          {isExperience ? (
            <EditListingExperienceDescriptionForm
              className={css.form}
              initialValues={initialValues}
              saveActionMsg={submitButtonText}
              onSubmit={values => {
                const { title, description } = values;
                const updateValues = {
                  title: title.trim(),
                  description,
                  publicData: {
                    category: 'experience',
                  },
                };
                setInitialProps({
                  ...updateValues,
                  title,
                  description,
                });
                onSubmit(updateValues);
              }}
              onChange={onChange}
              disabled={disabled}
              ready={ready}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              certificateOptions={certificateOptions}
            />
          ) : isCurriculum ? (
            <EditListingCurriculumForm
              className={css.form}
              initialValues={initialValues}
              saveActionMsg={submitButtonText}
              onSubmit={values => {
                const { title, description } = values;

                const updateValues = {
                  title: title.trim(),
                  description,
                  publicData: {
                    category: config.curriculum,
                  },
                };
                setInitialProps({
                  ...updateValues,
                  title,
                  description,
                });
                onSubmit(updateValues);
              }}
              onChange={onChange}
              disabled={disabled}
              ready={ready}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              certificateOptions={certificateOptions}
            />
          ) : (
            <EditListingDescriptionForm
              className={css.form}
              initialValues={initialValues}
              saveActionMsg={submitButtonText}
              onSubmit={values => {
                const { title, description } = values;

                const updateValues = {
                  title: title.trim(),
                  description,
                  publicData: {
                    category: 'teacher',
                  },
                };
                setInitialProps({
                  ...updateValues,
                  title,
                  description,
                });
                onSubmit(updateValues);
              }}
              onChange={onChange}
              disabled={disabled}
              ready={ready}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              certificateOptions={certificateOptions}
            />
          )}
        </>
      ) : (
        <EditListingParentDescriptionForm
          className={css.form}
          initialValues={{
            title,
            description,
            desired_program: publicData.desired_program,
            childs: childOptions(publicData?.child),
          }}
          saveActionMsg={submitButtonText}
          onSubmit={values => {
            const { title, description, childs, desired_program } = values;

            const childrens = childs?.map(item => ({
              name: item.name,
              gender: item?.gender.key,
              age: item.age,
            }));

            const updateValues = {
              title: title.trim(),
              description,
              publicData: { child: childrens, desired_program, category: 'parent' },
            };

            onSubmit(updateValues);
          }}
          onChange={onChange}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          certificateOptions={certificateOptions}
        />
      )}
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
