import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PropertyGroup } from '../../components';
import config from '../../config';

import css from './ListingPage.module.css';
const SectionCurriculum = props => {
  const { publicData } = props;
  if (!publicData) {
    return null;
  }
  const {
    curriculum_file_format,
    curriculum_grade_span,
    curriculum_resource_type,
    curriculum_style,
    curriculum_type,
    size,
    color,
  } = publicData || {};
  console.log(size, color);
  const sizeData = publicData?.size?.map(item =>
    config.custom.sizes?.find(item1 => item1.key == item)
  );
  const colorData = publicData?.color?.map(item =>
    config.custom.colors?.find(item1 => item1.key == item)
  );
  const sectionGradeSpan =
    Array.isArray(curriculum_grade_span) && curriculum_grade_span?.length ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.curriculumGradeSpanTitle" />
        </h2>
        <p className={css.description}>
          <PropertyGroup
            id="ListingPage.resourceType"
            options={curriculum_grade_span?.map(i =>
              config.custom.curriculumGradeSpan?.find(j => j.key === i)
            )}
            selectedOptions={curriculum_grade_span}
            twoColumns={config.custom.curriculumGradeSpan.length > 1}
          />
        </p>
      </div>
    ) : typeof curriculum_grade_span === 'string' ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.curriculumGradeSpanTitle" />
        </h2>
        <p className={css.description}>
          {config.custom.curriculumGradeSpan?.find(i => i.key === curriculum_grade_span)?.label}
        </p>
      </div>
    ) : null;
  const sectionCurriculumStyle = curriculum_style ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.curriculumStyleTitle" />
      </h2>
      <p className={css.description}>
        {config.custom.curriculumStyle?.find(i => i.key === curriculum_style)?.label}
      </p>
    </div>
  ) : null;
  const sectionResourceType = curriculum_resource_type?.length ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.resourceTypeTitle" />
      </h2>

      <PropertyGroup
        id="ListingPage.resourceType"
        options={curriculum_resource_type?.map(i =>
          config.custom.curriculumResourceType?.find(j => j.key === i)
        )}
        selectedOptions={curriculum_resource_type}
        twoColumns={config.custom.curriculumResourceType.length > 1}
      />
    </div>
  ) : null;
  const sectionCurriculumType = curriculum_type ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.curriculumTypeTitle" />
      </h2>
      <p className={css.description}>
        {config.custom.curriculumType?.find(i => i.key === curriculum_type)?.label}
      </p>
    </div>
  ) : null;
  const sectionCurriculumSize = size ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>Size</h2>
      <p className={css.description}>
        {sizeData?.map(l => <li style={{ marginLeft: '10px' }}>{l.label}</li>)}
      </p>
    </div>
  ) : null;
  const sectionCurriculumColor = color ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>Color</h2>
      <p className={css.description}>
        {colorData?.map(l => <li style={{ marginLeft: '10px' }}>{l.label}</li>)}
      </p>
    </div>
  ) : null;
  const sectionCurriculumFileFormat = curriculum_file_format ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.curriculumFileFormatTitle" />
      </h2>
      <p className={css.description}>
        {config.custom.curriculumFileFormat?.find(i => i.key === curriculum_file_format)?.label}
      </p>
    </div>
  ) : null;
  return (
    <div>
      {sectionGradeSpan}
      {sectionCurriculumStyle}
      {sectionResourceType}
      {sectionCurriculumType}
      {sectionCurriculumFileFormat}
      {sectionCurriculumSize}
      {sectionCurriculumColor}
    </div>
  );
};
export default SectionCurriculum;
