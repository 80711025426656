import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  requiredFieldArrayCheckbox,
  numberValidate,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckboxGroup,
  FieldSelectModern,
  SecondaryButton,
} from '../../components';
import { FieldArray } from 'react-final-form-arrays';
import config from '../../config';
import css from './EditListingParentDescriptionForm.module.css';
import arrayMutators from 'final-form-arrays';

const TITLE_MAX_LENGTH = 60;

const EditListingParentDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        certificateOptions,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingParentDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingParentDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingParentDescriptionForm.titleRequired',
      });

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingParentDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingParentDescriptionForm.descriptionPlaceholder',
      });

      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingParentDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingParentDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingParentDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingParentDescriptionForm.showListingFailed" />
        </p>
      ) : null;
      const ageFractionRequired = intl.formatMessage({
        id: 'EditListingParentDescriptionForm.ageFractionRequired',
      });
      const numberWithoutFractions = numberValidate(ageFractionRequired);
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const formatMessage = id =>
        intl.formatMessage({ id: `EditListingParentDescriptionForm.${id}` });

      const desiredProgramOfferedLabel = formatMessage('desiredProgramOfferedLabel');
      const desiredProgramOfferedRequiredMessage = formatMessage(
        'desiredProgramOfferedRequiredMessage'
      );
      const nameLabel = formatMessage('nameLabel');
      const namePlaceholder = intl.formatMessage({
        id: 'EditListingParentDescriptionForm.namePlaceholder',
      });
      const nameRequiredMessage = formatMessage('nameRequiredMessage');
      const genderLabel = formatMessage('genderLabel');
      const genderRequiredMessage = formatMessage('genderRequiredMessage');
      const ageLabel = intl.formatMessage({
        id: 'EditListingParentDescriptionForm.ageLabel',
      });
      const agePlaceholder = intl.formatMessage({
        id: 'EditListingParentDescriptionForm.agePlaceholder',
      });
      const ageRequired = intl.formatMessage({
        id: 'EditListingParentDescriptionForm.ageRequired',
      });
      const addChildLabel = intl.formatMessage({
        id: 'EditListingParentDescriptionForm.addChildLabel',
      });
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            validate={composeValidators(required(titleRequiredMessage))}
            autoFocus
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />
          <FieldCheckboxGroup
            className={css.title}
            id="desired_program"
            name="desired_program"
            label={desiredProgramOfferedLabel}
            options={config.custom.desiredProgram}
            validate={requiredFieldArrayCheckbox(desiredProgramOfferedRequiredMessage)}
          />
          <p className={css.child}>Children</p>
          <FieldArray name="childs">
            {({ fields }) => (
              <div>
                {fields.map((name, index) => (
                  <div key={name}>
                    <FieldTextInput
                      id={`${name}.name`}
                      name={`${name}.name`}
                      className={css.title}
                      type="text"
                      label={nameLabel}
                      placeholder={namePlaceholder}
                      validate={composeValidators(required(nameRequiredMessage))}
                      AutoFocus
                    />
                    <span className={css.close} onClick={() => fields.remove(index)}>
                      &times;
                    </span>
                    <FieldSelectModern
                      id={`${name}.gender`}
                      name={`${name}.gender`}
                      className={css.title}
                      options={config.custom.gender}
                      label={genderLabel}
                    />
                    <FieldTextInput
                      id={`${name}.age`}
                      name={`${name}.age`}
                      className={css.title}
                      type="number"
                      label={ageLabel}
                      placeholder={agePlaceholder}
                      validate={composeValidators(required(ageRequired), numberWithoutFractions)}
                    />
                  </div>
                ))}
                <SecondaryButton
                  type="button"
                  onClick={() => fields.push({ name: '', gender: '' })}
                >
                  {addChildLabel}
                </SecondaryButton>
              </div>
            )}
          </FieldArray>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingParentDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingParentDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingParentDescriptionFormComponent);
