import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldSelect, FieldTextInput } from '../../components';
import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;
export const checkIsPhysicalCurriculum = listing =>
  listing?.attributes?.publicData?.curriculum_file_format == 'physical';

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        listing,
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const isPhysicalCurriculum = checkIsPhysicalCurriculum(listing);

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            autoFocus
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />

          {isPhysicalCurriculum ? (
            <>
              <h3>
                {intl.formatMessage({ id: 'EditListingPricingForm.packageInformationTitle' })}
              </h3>

              <div className={'grid grid-cols-2 gap-4'}>
                <FieldTextInput
                  id="length"
                  name="length"
                  type="number"
                  className={css.input}
                  label={intl.formatMessage({ id: 'EditListingPricingForm.length' })}
                  placeholder={intl.formatMessage({
                    id: 'EditListingPricingForm.lengthInputPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({ id: 'EditListingPricingForm.lengthRequiredMessage' })
                  )}
                />
                <FieldTextInput
                  id="width"
                  name="width"
                  type="number"
                  className={css.input}
                  label={intl.formatMessage({ id: 'EditListingPricingForm.width' })}
                  placeholder={intl.formatMessage({
                    id: 'EditListingPricingForm.widthInputPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({ id: 'EditListingPricingForm.widthRequiredMessage' })
                  )}
                />
                <FieldTextInput
                  id="height"
                  name="height"
                  type="number"
                  className={css.input}
                  label={intl.formatMessage({ id: 'EditListingPricingForm.height' })}
                  placeholder={intl.formatMessage({
                    id: 'EditListingPricingForm.heightInputPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({ id: 'EditListingPricingForm.heightRequiredMessage' })
                  )}
                />
                <FieldSelect
                  id="distance_unit"
                  name="distance_unit"
                  className={css.input}
                  label={intl.formatMessage({ id: 'EditListingPricingForm.dimensionUnit' })}
                  placeholder={intl.formatMessage({
                    id: 'EditListingPricingForm.dimensionUnitPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({ id: 'EditListingPricingForm.dimensionRequiredMessage' })
                  )}
                >
                  <option value="">Select ...</option>
                  {config.custom.dimensionUnits.map(v => (
                    <option key={v.key} value={v.key}>
                      {v.label}
                    </option>
                  ))}
                </FieldSelect>
                <FieldTextInput
                  id="weight"
                  name="weight"
                  type="number"
                  className={css.input}
                  label={intl.formatMessage({ id: 'EditListingPricingForm.weight' })}
                  placeholder={intl.formatMessage({
                    id: 'EditListingPricingForm.weightInputPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({ id: 'EditListingPricingForm.weightRequiredMessage' })
                  )}
                />
                <FieldSelect
                  id="mass_unit"
                  name="mass_unit"
                  className={css.input}
                  label={intl.formatMessage({ id: 'EditListingPricingForm.massUnit' })}
                  placeholder={intl.formatMessage({
                    id: 'EditListingPricingForm.massUnitPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({ id: 'EditListingPricingForm.massUnitRequiredMessage' })
                  )}
                >
                  <option value="">Select ...</option>
                  {config.custom.massUnits.map(v => (
                    <option key={v.key} value={v.key}>
                      {v.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </>
          ) : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
