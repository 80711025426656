import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Button,
  FieldCheckboxGroup,
  FieldSelectModern,
  FieldTextInput,
  Form,
} from '../../components';

import css from './EditListingFeaturesForm.module.css';
import { required, requiredFieldArrayCheckbox } from '../../util/validators';
import { injectIntl } from '../../util/reactIntl';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        invalid,
        values,
        filterConfig,
        isTearcher,
        intl,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const formatMessage = id => intl.formatMessage({ id: `EditListingFeaturesForm.${id}` });
      const teachingLabel = formatMessage('teachingStyleLabel');
      const desiredTeachingStyleLabel = formatMessage('desiredTeachingStyleLabel');

      const teachingStyleLabel = isTearcher ? teachingLabel : desiredTeachingStyleLabel;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const options = findOptionsForSelectFilter('yogaStyles', filterConfig);

      const experienceRequiredMessage = formatMessage('experienceRequiredMessage');
      const gradeSpanRequiredMessage = formatMessage('gradeSpanRequiredMessage');
      const teachingStyleRequiredMessage = formatMessage('teachingStyleRequiredMessage');
      const otherTeachingStyleRequiredMessage = formatMessage('otherTeachingStyleRequiredMessage');

      const experienceLabel = formatMessage('experienceLabel');
      const gradeSpanLabel = formatMessage('gradeSpanLabel');
      const otherTeachingStyleLabel = formatMessage('otherTeachingStyleLabel');

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          {isTearcher ? (
            <FieldCheckboxGroup
              className={css.features}
              id="teaching_experience"
              name="teaching_experience"
              options={config.custom.backgroundOptions}
              label={experienceLabel}
              validate={requiredFieldArrayCheckbox(experienceRequiredMessage)}
            />
          ) : null}
          <FieldCheckboxGroup
            className={css.features}
            id="grade_span"
            name="grade_span"
            options={config.custom.gradeSpanOptions}
            label={gradeSpanLabel}
            validate={requiredFieldArrayCheckbox(gradeSpanRequiredMessage)}
          />
          <FieldCheckboxGroup
            className={css.features}
            id="teaching_style"
            name="teaching_style"
            label={teachingStyleLabel}
            options={config.custom.teachingStyles}
            validate={requiredFieldArrayCheckbox(teachingStyleRequiredMessage)}
            twoColumns={config.custom.teachingStyles.length > 3}
          />
          {values?.teaching_style?.includes('other') ? (
            <FieldTextInput
              id="other_teaching_style"
              name="other_teaching_style"
              label={otherTeachingStyleLabel}
              type="text"
              placeholder={otherTeachingStyleLabel}
              validate={required(otherTeachingStyleRequiredMessage)}
            />
          ) : null}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default injectIntl(EditListingFeaturesForm);
