import React from 'react';
import { NamedLink, PrimaryButton } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './BookingTimeForm.module.css';
import { injectIntl } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

function Contact({
  showContactUser,
  currentUser,
  onContactUser,
  service,
  optionData,
  intl,
  isSubscribed,
  type,
}) {
  // if (!showContactUser || !currentUser) {
  //   return null;
  // }
  let amount = optionData?.price;

  let interval;
  switch (optionData.variant) {
    case 'daily':
      interval = 'per day';
      break;
    case 'monthly':
      interval = 'per month';
      break;
    case 'weekly':
      interval = 'per week';
      break;
    default:
      interval = null;
  }
  if (optionData?.key === 'home_education_pod_trading_time') {
    interval = 'per day';
    amount = 1;
  }
  // if (!amount) return null;
  if (interval == null) return null;

  return (
    <div className={type === 'dropdown' ? `px-6 md:px-0` : `md:px-0`}>
      {type === 'dropdown' ? (
        <div className="my-2 text-gray-600 font-semibold text-base">
          {optionData?.key !== 'home_education_pod_trading_time' ? (
            <>
              {amount && (
                <>
                  <span>{formatMoney(intl, new Money(amount, config.currency))}</span>
                  <span> / {interval}</span>
                </>
              )}
            </>
          ) : (
            <p />
          )}
        </div>
      ) : null}

      <div>
        {isSubscribed ? (
          <PrimaryButton
            rootClassName={css.contactLink}
            type="button"
            onClick={() => onContactUser({ service, serviceData: optionData })}
            enforcePagePreloadFor="SignupPage"
          >
            <FormattedMessage id="ListingPage.contactUser" />
          </PrimaryButton>
        ) : (
          <div>
            <NamedLink name="MembershipPage" className={css.membershipButton}>
              <FormattedMessage id="ListingPage.contactUser" />
            </NamedLink>
          </div>
        )}
      </div>
    </div>
  );
}

export default injectIntl(Contact);
