import React from 'react';

import css from './ListingPage.module.css';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, injectIntl } from 'react-intl';
const { Money } = sdkTypes;

function SectionOneOnOneInPerson(props) {
  const { publicData, intl } = props;
  const { one_on_one_in_person } = publicData;
  // console.log('one_on_one_in_person', one_on_one_in_person);
  const options = one_on_one_in_person?.map(item => ({
    ...config.custom.oneInOneInPerson?.find(o => o.key === item),
    price: publicData[`${item}_price`],
  }));
  return one_on_one_in_person?.length > 0 ? (
    <div className={css.rootContainer}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.oneInOneInPersonTitle" />
      </h2>
      {options.map((item, index) => (
        <div className={css.oneInOneInPersonContainer} key={index}>
          <span>{item?.label}</span>
          <span className={css.price}>
            {formatMoney(intl, new Money(item?.price, config.currency))}
          </span>
        </div>
      ))}
    </div>
  ) : null;
}

export default injectIntl(SectionOneOnOneInPerson);
