import React from 'react';
import config from '../../config';
import { FormattedMessage, injectIntl } from 'react-intl';
import css from './ListingPage.module.css';
import { PropertyGroup } from '../../components';
function SectionExperiencesMaybe(props) {
  const { publicData, intl } = props;

  const { experienceCategory, priceType, experienceType, category } = publicData ?? {};
  const isCategoryExperience = category === 'experience';

  const sectionExperienceType =
    experienceType && isCategoryExperience ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.experienceTypeTitle" />
        </h2>

        <PropertyGroup
          id="ListingPage.experienceType"
          options={config.custom.experienceTypes.filter(i => i.key === experienceType)}
          selectedOptions={experienceType}
          twoColumns={config.custom.experienceTypes.length > 1}
        />
      </div>
    ) : null;
  const sectionExperienceCategory =
    experienceCategory && isCategoryExperience ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.experienceCategoryTitle" />
        </h2>

        <PropertyGroup
          id="ListingPage.experienceCategory"
          options={config.custom.experienceCategory?.filter(
            item => item?.key === experienceCategory
          )}
          selectedOptions={experienceCategory}
          twoColumns={config.custom.experienceCategory.length > 1}
        />
      </div>
    ) : null;
  const sectionPriceType =
    priceType && isCategoryExperience ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.priceTypeTitle" />
        </h2>
        <p className={css.description}>
          {config.custom.experienceOptions?.find(item => item?.key === priceType)?.label}
        </p>
        {/* <PropertyGroup
        id="ListingPage.experienceCategory"
        options={config.custom.experienceCategory?.filter(item => item?.key === experienceCategory)}
        selectedOptions={experienceCategory}
        twoColumns={config.custom.experienceCategory.length > 1}
      /> */}
      </div>
    ) : null;
  return (
    <div>
      {sectionExperienceType}
      {sectionExperienceCategory}
      {sectionPriceType}
    </div>
  );
}

export default injectIntl(SectionExperiencesMaybe);
