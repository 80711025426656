import React, { Component } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import noImage from './images/image.jpeg';
import css from './ListingCard.module.css';
import { InlineTextButton } from '../Button/Button';
import { ImLocation2 } from 'react-icons/im';
import { IoIosCheckmarkCircle } from 'react-icons/io';
const MIN_LENGTH_FOR_LONG_WORDS = 10;
import { IoLocationSharp } from 'react-icons/io5';
import { AiFillStar, AiOutlineUser } from 'react-icons/ai';
import { FaRupeeSign, FaUser } from 'react-icons/fa';
import communityMemberImage from '../../assets/community_badge.png';
const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
    currentUser,
    onContactUser,
    setListing,
    handleModalData,
  } = props;
  const isUserParent = currentUser?.attributes?.profile?.privateData?.userType === 'parent';
  const isUserTeacher = currentUser?.attributes?.profile?.privateData?.userType === 'teacher';
  const isSubscribed = currentUser?.attributes?.profile?.metadata?.membership;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing?.id?.uuid;
  const { title = '', price, publicData, metadata } = currentListing.attributes;
  const logoImageId = publicData?.logo_image;
  const isEducatorListing = publicData?.category === 'teacher';
  const authorAvailable = currentListing && currentListing.author;
  const currentAuthor = authorAvailable ? currentListing.author : null;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
  const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));
  const isMeetUpListing = publicData?.category == 'meet_up';
  const location = publicData?.location?.address;
  const authorImage = currentAuthor?.profileImage?.attributes?.variants['default']?.url;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const logoImage = currentListing?.images?.filter(item => item.id.uuid === logoImageId);
  const logoImg = logoImage?.length ? { ...logoImage[0] } : null;
  const isChilds = currentListing?.attributes?.publicData?.childs;
  const certificateOptions = findOptionsForSelectFilter('certificate', filtersConfig);
  const certificate = publicData
    ? getCertificateInfo(certificateOptions, publicData.certificate)
    : null;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const getTitle = title => (title?.length > 22 ? title?.substring(0, 18) + '...' : title);
  // console.log('location', location);
  const splitLocation = location?.length > 0 ? location.split(',')[0] : '';
  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';
  // const redirect = isMeetUpListing ? 'SearchPage' : 'ListingPage';
  // console.log('currentListing', currentListing);
  const getDescription = description =>
    description?.length > 22 ? description?.substring(0, 18) + '...' : description;
  return isMeetUpListing ? (
    <div className={css.card}>
      <div className={css.cardImageContainer}>
        {authorImage ? (
          <img src={authorImage} width="100%" className={css.cardImage} loading="lazy" />
        ) : (
          <div className="bg-marketplaceColor w-full h-full flex items-center justify-center rounded">
            <FaUser className={css.userProf} />
          </div>
        )}
      </div>
      <section className={css.right}>
        <div className={css.detailsTop}>
          <div>
            <h3 className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </h3>
            <div className={css.country}>
              <p>
                <IoLocationSharp className={css.locationIcon} />
                {location ? <span>{splitLocation}</span> : ' '}
              </p>
            </div>
          </div>
        </div>
        <div className={css.occupation}>
          {/* <p className={css.post}>{company_title}</p>&nbsp; */}
          <div className={css.description}>
            {getDescription(currentListing.attributes.description)}
          </div>
        </div>
        {/* {formattedPrice ? (
          <div className={css.priceContainer}>
            <h3 className={css.price}>{formattedPrice}/session</h3>
          </div>
        ) : null} */}
        <div className={css.priceContainer} style={{ paddingRight: '6px' }}>
          {isChilds && isChilds?.length > 0 ? (
            <div className={css.btnStuff}>
              <InlineTextButton
                onClick={() => handleModalData(currentListing)}
                className={css.topSkill}
                style={{ marginRight: '10px' }}
              >
                <FormattedMessage id="ListingPage.view" />
              </InlineTextButton>
            </div>
          ) : null}
          {(showContactUser && !currentUser) || (showContactUser && isUserParent) ? (
            <div className={css.btnStuff}>
              <InlineTextButton
                onClick={() => onContactUser(currentListing)}
                enforcePagePreloadFor="SignupPage"
                className={css.topSkill}
              >
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </div>
          ) : null}
        </div>
      </section>
    </div>
  ) : (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        {metadata?.community_member ? (
          <div
            className={`${css.memberImage} text-white absolute z-[1] font-semibold top-0 tracking-wider right-0 text-xs py-2 px-3 `}
          ></div>
        ) : null}
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={logoImg || firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {/* {formattedPrice} */}
          </div>
          <div className={css.perUnit}>{/* <FormattedMessage id={unitTranslationKey} /> */}</div>
        </div>

        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
            {metadata?.verified ? (
              <span title="Background Verified">
                <IoIosCheckmarkCircle className={css.validateLogo} />
              </span>
            ) : null}
            {/* {metadata?.community_member ? (
              <span title="Community member">
                <img src={communityMemberImage} className={css.communityMember} />
              </span>
            ) : null} */}
          </div>

          {location ? (
            <div>
              <ImLocation2 className={css.icon} />
              <span className={css.location}>{getTitle(location)}</span>
            </div>
          ) : (
            <div className={css.blank}></div>
          )}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
