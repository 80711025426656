import React from 'react';
import { useHistory } from 'react-router-dom';
import { ResponsiveImage } from '../../../components';
import { formatMoney } from '../../../util/currency';
import { createSlug } from '../../../util/urlHelpers';
import css from './ExperienceCard.module.css';
import classNames from 'classnames';

function ExperienceCard({ title, id, price, images, intl, defaultImage, publicData }) {
  const history = useHistory();
  const formattedPrice = price ? formatMoney(intl, price) : null;
  const firstImage = images?.find(i => i?.id?.uuid === publicData?.logo_image) || null;
  const handleClick = () =>
    history.push({
      pathname: `/l/${createSlug(title)}/${id}`,
    });
  const getTitle = title => (title?.length > 30 ? title?.substring(0, 20) + '...' : title);
  return (
    <div className={css.product} onClick={handleClick}>
      <div className={classNames(css.productImageWrapper, 'relative')}>
        <ResponsiveImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={['landscape-crop', 'landscape-crop2x']}
          defaultImage={defaultImage}
        />
      </div>
      <div className={css.productBodyWrapper}>
        <div className="flex items-center justify-between">
          <h3 className="text-gray-900 truncate text-base font-normal mr-4 my-0 py-3" title={title}>
            {getTitle(title)}
          </h3>
          <p className={css.price}>{formattedPrice}</p>
        </div>
      </div>
    </div>
  );
}

export default ExperienceCard;
