import { blogLoader, DEFAULT_PAGE_SIZE } from '../../util/blogLoader';
// ================ Action types ================ //

const FETCH_BLOG_POST_REQUEST = 'app/BlogPage/FETCH_BLOG_POST_REQUEST';
const FETCH_BLOG_POST_SUCCESS = 'app/BlogPage/FETCH_BLOG_POST_SUCCESS';
const FETCH_BLOG_POST_ERROR = 'app/BlogPage/FETCH_BLOG_POST_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchInProgressError: null,
  id: null,
  title: null,
  body: null,
  image: null,
  categories: null,
  created_at: null,
  updated_at: null,
};

const blogPostPageLoader = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_BLOG_POST_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        fetchInProgressError: null,
      };
    case FETCH_BLOG_POST_SUCCESS:
      return {
        ...state,
        fetchInProgress: false,
        ...payload,
      };
    case FETCH_BLOG_POST_ERROR:
      return {
        ...state,
        fetchInProgress: false,
        fetchInProgressError: payload,
      };
    default:
      return state;
  }
};

export default blogPostPageLoader;

const fetchBlogPostRequest = () => ({
  type: FETCH_BLOG_POST_REQUEST,
});
const fetchBlogPostSuccess = data => ({
  type: FETCH_BLOG_POST_SUCCESS,
  payload: data,
});
const fetchBlogPostError = error => ({
  type: FETCH_BLOG_POST_ERROR,
  payload: error,
});

const fetchBlogPost = slug => (dispatch, getState, sdk) => {
  dispatch(fetchBlogPostRequest());
  return blogLoader.get(`/posts/${slug}`);
};

export const loadData = (params, search) => dispatch => {
  const slug = params.slug;
  return Promise.all([dispatch(fetchBlogPost(slug))])
    .then(responses => {
      dispatch(fetchBlogPostSuccess(responses[0].data));
    })
    .catch(e => {
      dispatch(fetchBlogPostError(JSON.stringify(e)));
    });
  // return Promise.resolve();
};
