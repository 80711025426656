import classNames from 'classnames';
import React from 'react';
import { FieldDateInput, FieldSelect } from '../../components';
import {
  getMonthStartInTimeZone,
  nextMonthFn,
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
} from '../../util/dates';
import { bookingDateRequired } from '../../util/validators';
import DiscountInput from './DiscountInput';
import css from './FieldDateAndTimeInput.module.css';

function DateTimeInput({
  startDateInputProps,
  endDateInputProps,
  onMonthClick,
  currentMonth,
  timeZone,
  Next,
  Prev,
  setCurrentMonth,
  TODAY,
  onBookingEndDateChange,
  onBookingStartDateChange,
  isDayBlocked,
  endDateDisabled,
  onBookingStartTimeChange,
  availableStartTimes,
  bookingStartDate,
  placeholderTime,
  startTimeLabel,
  startTimeDisabled,
  endTimeLabel,
  endTimeDisabled,
  availableEndTimes,
  startTime,
  formId,
  isOutsideRange,
  bookingStartTime,
  failure,
  success,
  loading,
  updateState,
  coupon,
  showDiscountInput,
  onValidateDiscount,
  values,
}) {
  const showInput = showDiscountInput && values.bookingStartTime && values.bookingEndTime;

  return (
    <div>
      <div className={css.formRow}>
        <div className={classNames(css.field, css.startDate)}>
          <FieldDateInput
            className={css.fieldDateInput}
            name="bookingStartDate"
            id={formId ? `${formId}.bookingStartDate` : 'bookingStartDate'}
            label={startDateInputProps.label}
            placeholderText={startDateInputProps.placeholderText}
            format={v =>
              v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
            }
            parse={v =>
              v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
            }
            isDayBlocked={isDayBlocked}
            onChange={onBookingStartDateChange}
            onPrevMonthClick={() => onMonthClick(prevMonthFn)}
            onNextMonthClick={() => onMonthClick(nextMonthFn)}
            navNext={<Next currentMonth={currentMonth} timeZone={timeZone} />}
            navPrev={<Prev currentMonth={currentMonth} timeZone={timeZone} />}
            useMobileMargins
            showErrorMessage={false}
            validate={bookingDateRequired('Required')}
            onClose={event => setCurrentMonth(event?.date ?? TODAY, timeZone)}
          />
        </div>
      </div>

      <div className={css.formRow}>
        <div className={classNames(css.field, css.endDateHidden)}>
          <FieldDateInput
            {...endDateInputProps}
            name="bookingEndDate"
            id={formId ? `${formId}.bookingEndDate` : 'bookingEndDate'}
            className={css.fieldDateInput}
            label={endDateInputProps.label}
            placeholderText={endDateInputProps.placeholderText}
            format={v =>
              v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
            }
            parse={v =>
              v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
            }
            isDayBlocked={isDayBlocked}
            onChange={onBookingEndDateChange}
            onPrevMonthClick={() => onMonthClick(prevMonthFn)}
            onNextMonthClick={() => onMonthClick(nextMonthFn)}
            navNext={<Next currentMonth={currentMonth} timeZone={timeZone} />}
            navPrev={<Prev currentMonth={currentMonth} timeZone={timeZone} />}
            isOutsideRange={day =>
              isOutsideRange(day, bookingStartDate, selectedTimeSlot, timeZone)
            }
            useMobileMargins
            showErrorMessage={false}
            validate={bookingDateRequired('Required')}
            disabled={endDateDisabled}
            showLabelAsDisabled={endDateDisabled}
          />
        </div>

        <div className={css.field}>
          <FieldSelect
            name="bookingStartTime"
            id={formId ? `${formId}.bookingStartTime` : 'bookingStartTime'}
            className={bookingStartDate ? css.fieldSelect : css.fieldSelectDisabled}
            selectClassName={bookingStartDate ? css.select : css.selectDisabled}
            label={startTimeLabel}
            disabled={startTimeDisabled}
            onChange={onBookingStartTimeChange}
          >
            <option value="" selected hidden>
              Select Time:
            </option>

            {bookingStartDate ? (
              availableStartTimes.map(p => (
                <option key={p.timeOfDay} value={p.timestamp}>
                  {p.timeOfDay}
                </option>
              ))
            ) : (
              <option>{placeholderTime}</option>
            )}
          </FieldSelect>
        </div>

        <div className={bookingStartDate ? css.lineBetween : css.lineBetweenDisabled}>-</div>

        <div className={css.field}>
          <FieldSelect
            name="bookingEndTime"
            id={formId ? `${formId}.bookingEndTime` : 'bookingEndTime'}
            className={bookingStartDate ? css.fieldSelect : css.fieldSelectDisabled}
            selectClassName={bookingStartDate ? css.select : css.selectDisabled}
            label={endTimeLabel}
            disabled={endTimeDisabled}
          >
            <option value="" selected hidden>
              Select Time:
            </option>
            {bookingStartDate && (bookingStartTime || startTime) ? (
              availableEndTimes.map(p => (
                <option key={p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay} value={p.timestamp}>
                  {p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                </option>
              ))
            ) : (
              <option>{placeholderTime}</option>
            )}
          </FieldSelect>
        </div>
      </div>
      {showInput && (
        <DiscountInput
          loading={loading}
          onValidateDiscount={onValidateDiscount}
          success={success}
          failure={failure}
          onChange={e => updateState({ coupon: e.target.value })}
          value={coupon}
        />
      )}
    </div>
  );
}

export default DateTimeInput;
