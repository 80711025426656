import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import EditListingDetailsForm from './EditListingDetailsForm';
import config from '../../config';
import css from './EditListingExperienceDetailsPanel.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
const EditListingExperienceDetailsPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;
  const [initialProps, setInitialProps] = useState({});

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData, price } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingExperienceDetailsPanel.title"
      values={{
        listingTitle: <ListingLink listing={listing} />,
      }}
    />
  ) : (
    <FormattedMessage id="EditListingExperienceDetailsPanel.createListingTitle" />
  );

  const initialValues = React.useMemo(() => {
    if (!initialProps)
      return {
        priceType: config.custom.experienceOptions?.find(k => k.key === publicData?.priceType),
        experienceType: config.custom.experienceTypes?.find(
          k => k.key === publicData?.experienceType
        ),
        experienceCategory: config.custom.experienceCategory?.find(
          k => k.key === publicData?.experienceCategory
        ),
        price: price,
        //  publicData?.price ? new Money(publicData?.price, config.currency) : null,
      };
    return {
      priceType: config.custom.experienceOptions?.find(k => k.key === publicData?.priceType),
      experienceType: config.custom.experienceTypes?.find(
        k => k.key === publicData?.experienceType
      ),
      experienceCategory: config.custom.experienceCategory?.find(
        k => k.key === publicData?.experienceCategory
      ),
      price: price,

      ...initialProps,
    };
  }, [initialProps]);
  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDetailsForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { experienceType, experienceCategory, priceType, price } = values;

          const updateValues = {
            price: price,
            publicData: {
              experienceType: experienceType.key,
              experienceCategory: experienceCategory.key,
              priceType: priceType.key,
              // price: priceType.key == 'free' ? null : price?.amount,
            },
          };
          setInitialProps({
            ...updateValues,
            experienceType,
            experienceCategory,
            priceType,
            price,
          });
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        certificateOptions={certificateOptions}
      />
    </div>
  );
};

EditListingExperienceDetailsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingExperienceDetailsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingExperienceDetailsPanel;
