import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { ExternalLink, NamedLink } from '../../components';
import { injectIntl } from '../../util/reactIntl';

import css from './SectionBlogOne.module.css';

import one from './images/one.jpg';
import two from './images/two.jpg';
import three from './images/three.jpg';
import four from './images/four.jpg';
import community_members from './images/community_members.JPG';
class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = image => {
  return (
    <div className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={''} className={css.locationImage} />
        </div>
      </div>
    </div>
  );
};
const SectionBlogOne = props => {
  const { rootClassName, className, intl } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionBlogOne.title" />
      </div>
      <p className={css.subTitle}>
        <FormattedMessage id="SectionBlogOne.subtitle" />
      </p>
      <NamedLink
        name="SearchPage"
        to={{
          search: '?meta_community_member=true',
        }}
      >
        <div className={css.community_members}>
          <img src={community_members} alt="community_members" className={css.image} />
        </div>
      </NamedLink>

      {/* <div className={css.locations}>
        {locationLink(one)}
        {locationLink(two)}
        {locationLink(three)}
        {locationLink(four)}
      </div> */}
    </div>
  );
};

SectionBlogOne.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionBlogOne.propTypes = {
  rootClassName: string,
  className: string,
};

export default injectIntl(SectionBlogOne);
