import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { MdOutlineCallEnd, MdScreenShare, MdStopScreenShare } from 'react-icons/md';
import {
  BsFillMicFill,
  BsFillMicMuteFill,
  BsFillCameraVideoOffFill,
  BsFillCameraVideoFill,
} from 'react-icons/bs';

import { LocalVideoTrack } from 'twilio-video';

function StartVideoCallPage({ room, history, txn, currentUser }) {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [audioMuted, setAudioMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [screenShared, setScreenShared] = useState(false);
  const screenTrack = useRef(null);

  const participant = room?.localParticipant;

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null);
  useEffect(() => {
    const trackSubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => [...videoTracks, track]);
      } else {
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    };
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);
    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);
  useEffect(() => {
    const videoTrack = videoTracks[0];
    const audioTrack = audioTracks[0];
    if (videoTrack && audioTrack) {
      videoTrack.attach(videoRef.current);
      audioTrack.attach(audioRef.current);
      return () => {
        videoTrack.detach();
        audioTrack.detach();
      };
    }
  }, [videoTracks, audioTracks]);

  const disconnectCall = () => {
    participant.tracks?.forEach(function(trackPublication) {
      trackPublication.track.stop();
    });
    // JUDGE LINK ON THE BASIS OF USER ROLE
    const linkPath = txn.customer.id.uuid == currentUser.id.uuid ? 'order' : 'sale';
    history.replace(`/${linkPath}/${txn.id.uuid}/details`);
  };

  const onShareScreen = async () => {
    if (!room?.localParticipant) return;
    if (!screenShared)
      try {
        const stream = await navigator.mediaDevices.getDisplayMedia();
        screenTrack.current = new LocalVideoTrack(stream.getTracks()[0]);
        if (stream.getTracks?.()?.[0])
          stream.getTracks()[0].onended = function() {
            setScreenShared(false);
          };
        room.localParticipant.publishTrack(screenTrack.current);
        setScreenShared(true);
      } catch (e) {
        console.log(e);
        alert('Could not share the screen.');
      }
    else {
      room.localParticipant.unpublishTrack(screenTrack.current);
      screenTrack.current.stop();
      screenTrack.current = null;

      setScreenShared(false);
    }
  };

  return (
    <div className="relative h-screen w-screen bg-gray-900 overflow-hidden lg:overflow-auto">
      {/* Single Person View */}
      <h2 className="text-gray-400 text-center text-3xl mt-0 pt-6">Live Workshop</h2>
      <div className="flex h-3/4 w-full items-center justify-center mt-6">
        <video
          ref={videoRef}
          autoPlay={true}
          className="block  h-full object-contain rounded-md"
          style={{ transform: 'rotateY(180deg)' }}
        />
        <audio ref={audioRef} autoPlay={true} />
      </div>

      {/* Action Buttons */}
      <div className="absolute bottom-10 sm:bottom-5 left-1/2 -translate-x-1/2 flex gap-4">
        <button
          onClick={() => {
            const action = !videoMuted;
            participant?.videoTracks?.forEach(function(trackPublication) {
              trackPublication.track[action ? 'disable' : 'enable']();
            });
            setVideoMuted(action);
          }}
          type="button"
          role="control"
          className="flex items-center justify-center w-12 h-12 rounded-full border-none bg-white shadow cursor-pointer hover:bg-gray-200 transition duration-200"
        >
          {videoMuted ? (
            <BsFillCameraVideoOffFill className="text-lg" />
          ) : (
            <BsFillCameraVideoFill className="text-lg" />
          )}
        </button>
        <button
          onClick={() => {
            const action = !audioMuted;
            participant?.audioTracks?.forEach(function(trackPublication) {
              trackPublication.track[action ? 'disable' : 'enable']();
            });
            setAudioMuted(action);
          }}
          type="button"
          role="control"
          className="flex items-center justify-center w-12 h-12 rounded-full border-none bg-white shadow cursor-pointer hover:bg-gray-200 transition duration-200"
        >
          {audioMuted ? (
            <BsFillMicMuteFill className="text-lg" />
          ) : (
            <BsFillMicFill className="text-lg" />
          )}
        </button>
        <button
          type="button"
          role="control"
          onClick={disconnectCall}
          className="flex items-center justify-center w-12 h-12 rounded-full border-none bg-white shadow bg-red-500 cursor-pointer hover:bg-red-600 transition duration-200"
        >
          <MdOutlineCallEnd className="text-white text-lg" />
        </button>
        {/* <button
          onClick={onShareScreen}
          type="button"
          role="control"
          disabled={!room?.localParticipant}
          className="flex items-center justify-center w-12 h-12 rounded-full border-none bg-white shadow cursor-pointer hover:bg-gray-200 transition duration-200"
        >
          {screenShared ? (
            <MdStopScreenShare className="text-lg" />
          ) : (
            <MdScreenShare className="text-lg" />
          )}
        </button> */}
      </div>
    </div>
  );
}

export default withRouter(StartVideoCallPage);
