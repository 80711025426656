import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton } from '../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const MarkShippedButtonMaybe = props => {
  const {
    className,
    rootClassName,
    showButton,
    markShippedInProgress,
    markShippedError,
    onMarkShipped,
  } = props;

  const markShippedErrorMessage = markShippedError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.markShippedFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButton ? (
    <div className={classes}>
      <div className={css.actionErrors}>{markShippedErrorMessage}</div>
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          inProgress={markShippedInProgress}
          disabled={markShippedInProgress}
          onClick={onMarkShipped}
        >
          <FormattedMessage id="TransactionPanel.markShippedButton" />
        </PrimaryButton>
      </div>
    </div>
  ) : null;
};

export default MarkShippedButtonMaybe;
