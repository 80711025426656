import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPhotosForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import config from '../../config';
import css from './EditListingPhotosPanel.module.css';

class EditListingPhotosPanel extends Component {
  render() {
    const {
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      images,
      listing,
      onImageUpload,
      onUpdateImageOrder,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onRemoveImage,
      onManageDisableScrolling,
    } = this.props;
    console.log('images', images);
    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes;

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPhotosPanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />
    );
    let imagesData = images?.filter(k => k.isLogo != true)?.filter(r => r.isBanner != true);
    if (publicData?.logo_image) {
      imagesData = imagesData?.filter(s => s.id.uuid != publicData?.logo_image);
    }
    if (publicData?.bannerImageId) {
      imagesData = imagesData?.filter(b => b.id.uuid != publicData?.bannerImageId);
    }

    const logo = images?.find(k => k.isLogo === true)
      ? [images?.find(k => k.isLogo === true)]
      : publicData?.logo_image
      ? images?.filter(s => s.id.uuid === publicData?.logo_image)
      : [];
    const banner = images?.find(k => k.isBanner === true)
      ? [images?.find(k => k.isBanner === true)]
      : publicData?.bannerImageId
      ? images?.filter(s => s.id.uuid === publicData?.bannerImageId)
      : [];
    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingPhotosForm
          className={css.form}
          disabled={disabled}
          ready={ready}
          fetchErrors={errors}
          initialValues={{
            images: imagesData,
            bannerImage: config.custom.bannerImages?.find(k => k.id === publicData.bannerImage),
          }}
          images={imagesData}
          logo={logo}
          banner={banner}
          onImageUpload={onImageUpload}
          onSubmit={values => {
            let { addImage, addLogo, bannerImage, ...updateValues } = values;
            const logoImageId = logo[0]?.imageId?.uuid || logo[0]?.id.uuid;
            const bannerImageId = banner[0]?.imageId?.uuid || banner[0]?.id.uuid;
            //  updateValues?.logo?.[0]?.imageId?.uuid ?? publicData.logo_image;
            updateValues = {
              images: [...images],
              publicData: {
                logo_image: logoImageId,
                bannerImageId,
                bannerImage: bannerImage?.id,
              },
            };

            onSubmit(updateValues);
          }}
          onChange={onChange}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveImage}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          onManageDisableScrolling={onManageDisableScrolling}
        />
              
      </div>
    );
  }
}

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array, // We cannot use propTypes.listing since the listing might be a draft.

  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanel;
