import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks, Modal, PropertyGroup } from '../../components';
import css from './SearchResultsPanel.module.css';
import config from '../../config';
import { useIntl } from 'react-intl';
const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    // listings,
    pagination,
    search,
    setActiveListing,
    history,
    callSetInitialValues,
    setListing,
    currentUser,
    onManageDisableScrolling,
  } = props;
  const intl = useIntl();
  let { listings } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('');
  const [childs, setChilds] = useState([]);
  const [publicData, setPublicData] = useState({});
  const [description, setDescription] = useState('');
  const handleModalData = values => {
    setShowModal(true);
    const publicData = values?.attributes?.publicData;
    setPublicData(publicData);
    setTitle(values?.attributes?.title);
    setDescription(values?.attributes?.description);
    const { childs } = publicData ?? {};
    setChilds(childs);
  };
  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');
  const currentUserId = currentUser?.id?.uuid;

  listings = listings?.map(i => {
    if (i.attributes.publicData.category == 'meet_up') {
      if (i?.author?.id?.uuid != currentUserId) {
        return i;
      } else return null;
    } else {
      return i;
    }
  });

  const newListingsSet = listings?.filter(k => k != null);

  return (
    <div className={classes}>
      <div className={css.listingCards}>
        {newListingsSet.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l?.id?.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
            setActiveListing={setActiveListing}
            history={history}
            onContactUser={props.onContactUser}
            callSetInitialValues={callSetInitialValues}
            setListing={setListing}
            currentUser={currentUser}
            handleModalData={handleModalData}
          />
        ))}
        {props.children}
      </div>
      {paginationLinks}
      <Modal
        {...props}
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div style={{ margin: '1rem' }}>
          <h1 className={css.title}>{title}</h1>
          <p className={css.bio}>{description}</p>
          <h3>
            {intl.formatMessage({ id: 'SearchResultsPanel.SearchResultsPanel.LocalFriendsTitle' })}
          </h3>
          <div>
            <table className={css.table}>
              <tr className={css.tr}>
                <th className={css.th}>
                  {intl.formatMessage({
                    id: 'SearchResultsPanel.SearchResultsPanel.localFriendName',
                  })}
                </th>
                <th className={css.th}>
                  {intl.formatMessage({
                    id: 'SearchResultsPanel.SearchResultsPanel.localFriendGender',
                  })}
                </th>
                <th className={css.th}>
                  {intl.formatMessage({
                    id: 'SearchResultsPanel.SearchResultsPanel.localFriendsAge',
                  })}
                </th>
              </tr>
              {childs?.map(item => (
                <tr className={css.tr}>
                  <td className={css.td} style={{ padding: ' 0.5rem 2rem' }}>
                    {item?.name}
                  </td>
                  <td className={css.td} style={{ padding: ' 0.5rem 2rem' }}>
                    {config.custom.gender?.find(k => k.key === item.gender)?.label}
                  </td>
                  <td className={css.td} style={{ padding: ' 0.5rem 2rem' }}>
                    {item.age ?? 'N/A'}
                  </td>
                </tr>
              ))}
            </table>
            {publicData?.family_interest?.length > 0 ? (
              <div>
                <h3 className={css.descriptionTitle}>
                  {intl.formatMessage({
                    id: 'SearchResultsPanel.SearchResultsPanel.activeFamilyInterestsLabel',
                  })}
                </h3>
                <PropertyGroup
                  id="family_interest "
                  options={publicData?.family_interest?.map(i =>
                    config.custom.familyInterestOptions?.find(s => s.key == i)
                  )}
                  selectedOptions={publicData?.family_interest}
                  twoColumns={publicData?.family_interest?.length > 1}
                />
              </div>
            ) : null}
            {publicData?.favorite_family_outings?.length > 0 ? (
              <div>
                <h3 className={css.descriptionTitle}>
                  {intl.formatMessage({
                    id: 'SearchResultsPanel.SearchResultsPanel.favouriteFamilyOutingsLabel',
                  })}
                </h3>
                <PropertyGroup
                  id="favorite_family_outings "
                  options={publicData?.favorite_family_outings?.map(i =>
                    config.custom.favoriteFamilyOutingsOptions?.find(s => s.key == i)
                  )}
                  selectedOptions={publicData?.favorite_family_outings}
                  twoColumns={publicData?.favorite_family_outings?.length > 1}
                />
              </div>
            ) : null}
            {publicData?.indore_family_interest?.length > 0 ? (
              <div>
                <h3 className={css.descriptionTitle}>
                  {intl.formatMessage({
                    id: 'SearchResultsPanel.SearchResultsPanel.indoorFamilyInterestsLabel',
                  })}
                </h3>
                <PropertyGroup
                  id="indore_family_interest
 "
                  options={publicData?.indore_family_interest?.map(i =>
                    config.custom.indoreFamilyInterestOptions?.find(s => s.key == i)
                  )}
                  selectedOptions={publicData?.indore_family_interest}
                  twoColumns={publicData?.indore_family_interest?.length > 1}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
