/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { useLocation } from 'react-router-dom';
import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  OwnListingLink,
  ExternalLink,
} from '../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const location = useLocation();

  const user = ensureCurrentUser(currentUser);
  const isTearcher = currentUser?.attributes?.profile?.privateData?.userType === 'teacher';
  const isSubscribed = currentUser?.attributes?.profile?.metadata?.membership;
  if (!isAuthenticated) {
    const signup = (
      <NamedLink
        name="SignupPage"
        className={css.signupLink}
        to={{ state: { from: `${location.pathname}` } }}
      >
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );
    const blogLink = (
      <NamedLink name="BlogPostsPage" className={classNames(css.navigationLink)}>
        <span className={css.login}>
          {' '}
          <FormattedMessage id="TopbarDesktop.BlogLink" />
        </span>
      </NamedLink>
    );
    const sectionHelp = (
      <a className={css.navigationLink} href="http://help.hometribe.com" target="__blank">
        <span className={css.login}>
          <FormattedMessage id="TopbarDesktop.helpLink" />
        </span>
      </a>
    );
    const login = (
      <NamedLink
        name="LoginPage"
        className={css.loginLink}
        to={{ state: { from: `${location.pathname}` } }}
      >
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          {sectionHelp}
          {blogLink}
          <NamedLink className={classNames(css.navigationLink)} name="FeaturesForEducatorsPage">
            <FormattedMessage id="TopbarMobileMenu.featuresForEducatorsLink" />
          </NamedLink>

          <NamedLink className={classNames(css.navigationLink)} name="FeaturesForParentPage">
            <FormattedMessage id="TopbarMobileMenu.featuresForParentLink" />
          </NamedLink>
          <ExternalLink
            className={classNames(css.navigationLink)}
            href="https://help.hometribe.com/en/articles/6570383-pricing"
          >
            Pricing
          </ExternalLink>
        </div>

        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const blogLink = (
    <NamedLink
      name="BlogPostsPage"
      className={classNames(css.navigationLink, currentPageClass('BlogPostsPage'))}
    >
      <span className={css.login}>
        {' '}
        <FormattedMessage id="TopbarDesktop.BlogLink" />
      </span>
    </NamedLink>
  );
  const sectionHelp = (
    <a className={css.navigationLink} href="http://help.hometribe.com" target="__blank">
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.helpLink" />
      </span>
    </a>
  );
  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: isTearcher ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {isTearcher ? (
          <OwnListingLink
            listing={currentUserListing}
            listingFetched={currentUserListingFetched}
            className={css.navigationLink}
          />
        ) : (
          <NamedLink className={css.navigationLink} name="NewListingParientPage">
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.createParentListing" />
            </span>
          </NamedLink>
        )}
        {blogLink}
        {sectionHelp}

        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.yourListingsLink" />
        </NamedLink>

        {isTearcher ? (
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('NewListingPageWithCategories')
            )}
            name="NewListingPageWithCategories"
            params={{ category: 'curriculum' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.addCurriculum" />
          </NamedLink>
        ) : null}
        {isTearcher ? (
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('NewListingPageWithCategories')
            )}
            name="NewListingPageWithCategories"
            params={{ category: 'experience' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.addExperience" />
          </NamedLink>
        ) : null}

        {isTearcher ? (
          //teacher profile link

          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarDesktop.profileSettingsLinkEducator" />
          </NamedLink>
        ) : (
          //parent profileLink

          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarDesktop.profileSettingsLinkParent" />
          </NamedLink>
        )}
        {/* <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink> */}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('FeaturesForEducatorsPage'))}
          name="FeaturesForEducatorsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.featuresForEducatorsLink" />
        </NamedLink>

        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('FeaturesForParentPage'))}
          name="FeaturesForParentPage"
        >
          <FormattedMessage id="TopbarMobileMenu.featuresForParentLink" />
        </NamedLink>
        {isSubscribed ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('CountactUsPage'))}
            name="ContactUsPage"
            // subscribed={true}
            to={{ hash: 'subscribed' }}
          >
            <span className={css.navigationLink} />
            <FormattedMessage id="TopbarDesktop.cancelSubscriptionLink" />
          </NamedLink>
        ) : null}
      </div>
      <div className={css.footer}>
        {/* <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink> */}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
