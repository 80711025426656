import React from 'react';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description, publicData } = props;
  const { category } = publicData || {};
  const sectionDescription = description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" />
      </h2>
      <p className={css.description}>
        {richText(description, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
    </div>
  ) : null;
  const sectionCategory = category ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.categoryTitle" />
      </h2>
      <p className={css.description}>
        {config.custom.userTypes.find(i => i.key === category)?.label}
      </p>
    </div>
  ) : null;
  return (
    <div>
      {sectionCategory}
      {sectionDescription}
    </div>
  );
};

export default SectionDescriptionMaybe;
