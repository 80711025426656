import React from 'react';
import { useIntl } from 'react-intl';
import IconSpinner from '../IconSpinner/IconSpinner';

function Loading(props) {
  const { loadingText } = props;
  const intl = useIntl();
  return (
    <div className="flex items-center gap-4">
      <span className="text-base">
        Loading...
        <IconSpinner />
      </span>
    </div>
  );
}

Loading.defaultProps = {
  loadingText: 'Loading.loadingText',
};

export default Loading;
