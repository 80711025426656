import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
// import imageOne from './premium-membership.png';
import imageOne from './membershipPremium.jpg';
import imageTwo from './per-experience.png';
import { NamedLink } from '../../components';
import css from './SectionPriceCards.module.css';
const SectionPriceCards = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  const title = <FormattedMessage className={css.title} id="SectionPriceCards.Membership" />;
  return (
    <div className={classes}>
      <div className={css.title}>
        <span style={{ fontSize: '21px' }}>{title}</span>
      </div>
      <div className={css.stepTitle}>
        {/* <FormattedMessage id="SectionInformation.description" /> */}
      </div>
      <div className={css.imageContainer}>
        <img src={imageOne} className={css.image} />

        <img src={imageTwo} className={css.image} />
      </div>
    </div>
  );
};

SectionPriceCards.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionPriceCards.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionPriceCards;
