import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ActivityFeed, PrimaryButton, CurriculumActivityFeed } from '../../components';

import css from './TransactionPanel.module.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import config from '../../config';
import moment from 'moment';
import { showToast } from '../../util/toasts';
import { ToastContainer } from 'react-toastify';
import TrackingPanel from './TrackingPanel';
import ShippingInfoPanel from './ShippingInfoPanel';

const lastTransitions = ['transition/accept'];

// Functional component as a helper to build ActivityFeed section
const FeedSection = props => {
  const {
    className,
    rootClassName,
    currentTransaction,
    currentUser,
    fetchMessagesError,
    fetchMessagesInProgress,
    initialMessageFailed,
    messages,
    oldestMessagePageFetched,
    onShowMoreMessages,
    onOpenReviewModal,
    totalMessagePages,
    isProvider,
    isCurriculum,
    digitalMaterial,
    isDigital,
    listingId,
  } = props;

  const [curriculumLoading, setCurriculumLoading] = useState(false);
  const [material, setMaterial] = useState([]);
  useEffect(() => {
    const items = [];
    let size = 0;
    (async () => {
      try {
        setCurriculumLoading(true);
        const materialInDb = await axios.get(`/get-material?listingId=${listingId}`);
        materialInDb?.data?.data?.forEach((i, index) => {
          items.push({
            file: i.file,
            size: i.size,
            loading: false,
            index: i.index,
            label: i.label,
          });
          size += i.size;
        });
        setMaterial(items);
        setCurriculumLoading(false);
      } catch (e) {
        setCurriculumLoading(false);
        console.log(e);
      }
    })();
  }, []);
  const service = currentTransaction.attributes?.protectedData?.service;
  const experienceType = currentTransaction?.attributes?.metadata?.experienceType;

  const metadata = currentTransaction.attributes.metadata;

  const parsedServiceData = metadata?.serviceData ? JSON.parse(metadata.serviceData) : null;

  let isOnlineType = service?.endsWith('_online') || experienceType == 'online';
  let useListing = false;

  if (experienceType == 'online') {
    isOnlineType = true;
    useListing = true;
  } else if (
    typeof parsedServiceData?.experienceType != 'undefined' &&
    parsedServiceData?.experienceType == 'online'
  ) {
    isOnlineType = true;
    useListing = true;
  }

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const onJoinVideo = useCallback(() => {
    setLoading(true);

    history.push({
      pathname: '/workshop',
      state: {
        roomName: currentTransaction.id.uuid,
      },
    });

    // axios
    //   .post('/live-workshop', {
    //     transactionId: currentTransaction.id.uuid,
    //   })
    //   .then(response => {
    //     history.push({
    //       pathname: `/video-call`,
    //       state: {
    //         token: response.data.token,
    //         transaction: JSON.stringify(currentTransaction),
    //       },
    //     });
    //   })
    //   .catch(err => {
    //     showToast(
    //       err?.response?.data?.message ?? 'Something went wrong, please try again.',
    //       'error'
    //     );
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  }, []);

  const txTransitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const hasOlderMessages = totalMessagePages > oldestMessagePageFetched;

  const showFeed =
    messages.length > 0 || txTransitions.length > 0 || initialMessageFailed || fetchMessagesError;

  const classes = classNames(rootClassName || css.feedContainer, className);

  // const videoCall = config.custom.PAID_SERVICES.includes(service);

  // TODO: REPLACE HARDCODED WITH ACTUAL BOOKING DATES
  let bookingStart = currentTransaction?.booking?.attributes?.start;
  let bookingEnd = currentTransaction?.booking?.attributes?.end;
  // const bookingStart = new Date();
  // const bookingEnd = moment(new Date()).add(1, 'days');

  if (useListing) {
    bookingStart =
      experienceType == 'online' ? bookingStart : new Date(parsedServiceData.startDate);
    bookingEnd = experienceType == 'online' ? bookingEnd : new Date(parsedServiceData.endDate);
  }

  const currentDate = new Date();
  const difference = bookingStart
    ? moment.duration(moment(bookingStart).diff(moment(currentDate))).asMinutes()
    : null;

  //TODO: Replace 'transition/accept' with currentTransaction?.attributes?.lastTransition
  const showVideoCallButton =
    lastTransitions.includes(currentTransaction?.attributes?.lastTransition) &&
    isOnlineType &&
    bookingStart &&
    difference <= 15
      ? true
      : false;

  const shouldShowCallButton = bookingEnd
    ? moment(new Date()).valueOf() > moment(bookingEnd).valueOf()
      ? false
      : true
    : false;

  const trackingHistory = currentTransaction.attributes?.metadata?.tracking_history ?? [];

  // console.log({ showVideoCallButton, shouldShowCallButton, isOnlineType });

  return showFeed ? (
    <div className={classes}>
      <h3 className={css.feedHeading}>
        <FormattedMessage id="TransactionPanel.activityHeading" />
      </h3>
      {initialMessageFailed ? (
        <p className={css.messageError}>
          <FormattedMessage id="TransactionPanel.initialMessageFailed" />
        </p>
      ) : null}
      {fetchMessagesError ? (
        <p className={css.messageError}>
          <FormattedMessage id="TransactionPanel.messageLoadingFailed" />
        </p>
      ) : null}
      {isCurriculum ? (
        <CurriculumActivityFeed
          className={css.feed}
          messages={messages}
          transaction={currentTransaction}
          currentUser={currentUser}
          hasOlderMessages={hasOlderMessages && !fetchMessagesInProgress}
          onOpenReviewModal={onOpenReviewModal}
          onShowOlderMessages={onShowMoreMessages}
          fetchMessagesInProgress={fetchMessagesInProgress}
          digitalMaterial={material}
          curriculumLoading={curriculumLoading}
          isDigital={isDigital}
        />
      ) : (
        <ActivityFeed
          className={css.feed}
          messages={messages}
          transaction={currentTransaction}
          currentUser={currentUser}
          hasOlderMessages={hasOlderMessages && !fetchMessagesInProgress}
          onOpenReviewModal={onOpenReviewModal}
          onShowOlderMessages={onShowMoreMessages}
          fetchMessagesInProgress={fetchMessagesInProgress}
        />
      )}
      <ToastContainer />

      {showVideoCallButton && shouldShowCallButton && isOnlineType && (
        <PrimaryButton onClick={onJoinVideo} inProgress={loading} className="mt-6">
          <FormattedMessage id="TransactionPanel.videoCallButtonText" />
        </PrimaryButton>
      )}
      {trackingHistory.length > 0 && <TrackingPanel trackingHistory={trackingHistory} />}
      <ShippingInfoPanel tx={currentTransaction} isProvider={isProvider} />
    </div>
  ) : null;
};

export default FeedSection;
