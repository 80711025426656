import React from 'react';
import classNames from 'classnames';

import config from '../../config';
import getCountryCodes from '../../translations/countryCodes';
import { FormattedMessage } from '../../util/reactIntl';
import countriesStates from '../../containers/CheckoutPage/ShippingDetails/country-state.json';

import AddressLinkMaybe from './AddressLinkMaybe';

import css from './TransactionPanel.module.css';

const Headline = ({ children }) => {
  return <span style={{ fontWeight: 'bold' }}>{children}</span>;
};

// Functional component as a helper to build ActivityFeed section
const DeliveryInfoMaybe = props => {
  const { className, rootClassName, transaction, listing } = props;
  const classes = classNames(rootClassName || css.deliveryInfoContainer, className);
  const protectedData = transaction?.attributes?.protectedData;
  const deliveryMethod = protectedData?.deliveryMethod;
  const isShipping = deliveryMethod === 'shipping';
  const isPickup = deliveryMethod === 'pickup';

  if (isPickup) {
    const pickupLocation = listing?.attributes?.publicData?.location || {};
    return (
      <div className={classes}>
        <h3 className={css.sectionHeading}>
          <FormattedMessage id="TransactionPanel.pickupInfoHeading" />
        </h3>
        <div className={css.pickupInfoContent}>
          <AddressLinkMaybe
            linkRootClassName={css.pickupAddress}
            location={pickupLocation}
            geolocation={listing?.attributes?.geolocation}
            showAddress={true}
          />
        </div>
      </div>
    );
  } else if (isShipping) {
    const { name, phoneNumber, address } = protectedData?.shippingDetails || {};
    const { line1, line2, city, postalCode, state: stateCode, country: countryCode } =
      address || {};
    const phoneMaybe = !!phoneNumber ? (
      <>
        {phoneNumber}
        <br />
      </>
    ) : null;

    const countryObject = countriesStates.find(c => c.code === countryCode);
    const country = countryObject?.country;

    const state = countryObject.states.find(s => s.code == stateCode).name;

    // const countryCodes = getCountryCodes(config.locale);
    // const countryInfo = countryCodes.find(c => c.code === countryCode);
    // const country = countryInfo?.name;

    return (
      <div className={classes}>
        <h3 className={css.sectionHeading}>
          <FormattedMessage id="TransactionPanel.shippingInfoHeading" />
        </h3>
        <div className={css.shippingInfoContent}>
          <Headline>Name:</Headline> {name}
          <br />
          {phoneMaybe ? (
            <>
              <Headline>Phone: </Headline>
              {phoneMaybe}
            </>
          ) : null}
          <Headline>Address Line 1:</Headline> {line1}
          {line2 ? (
            <>
              <br />
              <Headline> Address Line 2:</Headline> {line2}
            </>
          ) : (
            ''
          )}
          <br />
          <Headline>Postal Code:</Headline> {postalCode}
          <br /> <Headline>City:</Headline> {city}
          <br />
          {state ? (
            <>
              <Headline>State:</Headline> {state} ({stateCode})
            </>
          ) : (
            ''
          )}
          <br />
          <Headline>Country:</Headline> {country} ({countryCode})
          <br />
        </div>
      </div>
    );
  }
  return null;
};

export default DeliveryInfoMaybe;
