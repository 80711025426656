import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { useLocation } from 'react-router-dom';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ListingLink,
  OwnListingLink,
  ExternalLink,
} from '../../components';
import { RiArrowDropDownLine } from 'react-icons/ri';

import css from './TopbarDesktop.module.css';
import { TopbarSearchForm } from '../../forms';
import config from '../../config';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    isCurrentUserClient,
    initialSearchFormValues,
    isLandingPage = false,
  } = props;

  const location = useLocation();

  const [mounted, setMounted] = useState(false);
  const isTeacher = currentUser?.attributes?.profile?.privateData?.userType === 'teacher';
  const isSubscribed = currentUser?.attributes?.profile?.metadata?.membership;

  // console.log(isTeacher, isSubscribed);
  //isTeacher
  //
  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = isLandingPage ? (
    <div className={css.searchLink}></div>
  ) : (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={isLandingPage ? css.inboxLinklandingPage : css.inboxLink}
      name="InboxPage"
      params={{ tab: isTeacher ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {/* <MenuItem key="EditListingPage" disabled={isCurrentUserClient}>
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('EditListingPage'))}
            name="EditListingPage"
            params={{ category: 'experience' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.addExperience" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="EditListingPage">
          {isTeacher ? (
            <OwnListingLink
              listing={currentUserListing}
              listingFetched={currentUserListingFetched}
              className={css.yourListingsLink}
            >
              <div>
                <span className={css.menuItemBorder} />
                {currentUserListing ? (
                  <FormattedMessage id="TopbarDesktop.editYourListingLink" />
                ) : (
                  <FormattedMessage id="TopbarDesktop.addYourListingLink" />
                )}
              </div>
            </OwnListingLink>
          ) : null}
        </MenuItem>
        <MenuItem key="NewListingPageWithCategories">
          {currentUser && isTeacher ? (
            <NamedLink
              className={classNames(
                css.yourListingsLink,
                currentPageClass('NewListingPageWithCategories')
              )}
              name="NewListingPageWithCategories"
              params={{ category: config.curriculum }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.addCurriculum" />
            </NamedLink>
          ) : null}
        </MenuItem>

        <MenuItem key="NewListingPageWithCategories">
          {currentUser && isTeacher ? (
            <NamedLink
              className={classNames(
                css.yourListingsLink,
                currentPageClass('NewListingPageWithCategories')
              )}
              name="NewListingPageWithCategories"
              params={{ category: 'experience' }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.addExperience" />
            </NamedLink>
          ) : null}
        </MenuItem>

        {/* <MenuItem key="NewListingPageWithCategories">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('NewListingPageWithCategories')
            )}
            name="NewListingPageWithCategories"
            params={{ category: config.curriculum }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.addCurriculum" />
          </NamedLink>
        </MenuItem> */}

        <MenuItem key="ManageListingsPage" disabled={isCurrentUserClient}>
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            {isTeacher ? (
              <FormattedMessage id="TopbarDesktop.yourEducatorListingsLink" />
            ) : (
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            )}
          </NamedLink>
        </MenuItem>

        {isTeacher ? (
          //teacher profile link
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLinkEducator" />
            </NamedLink>
          </MenuItem>
        ) : (
          //parent profileLink
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLinkParent" />
            </NamedLink>
          </MenuItem>
        )}
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="CountactUsPage">
          {isSubscribed ? (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('CountactUsPage'))}
              name="ContactUsPage"
              // subscribed={true}
              to={{ hash: 'subscribed' }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.cancelSubscriptionLink" />
            </NamedLink>
          ) : null}
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="SignupPage"
      to={{ state: { from: `${location.pathname}` } }}
      className={isLandingPage ? css.inboxLinklandingPage : css.inboxLink}
    >
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="LoginPage"
      to={{ state: { from: `${location.pathname}` } }}
      className={isLandingPage ? css.inboxLinklandingPage : css.inboxLink}
    >
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const listingLink =
    authenticatedOnClientSide && currentUserListingFetched && currentUserListing ? (
      <ListingLink
        className={isLandingPage ? css.inboxLinklandingPage : css.inboxLink}
        listing={currentUserListing}
        children={
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.viewListing" />
          </span>
        }
      />
    ) : null;

  const createListingLink =
    isAuthenticatedOrJustHydrated && !(currentUserListingFetched && !currentUserListing) ? null : (
      <NamedLink
        className={isLandingPage ? css.inboxLinklandingPage : css.inboxLink}
        name="NewListingPage"
      >
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
    );
  const createParentLink =
    currentUser && isCurrentUserClient ? null : (
      <NamedLink
        className={isLandingPage ? css.inboxLinklandingPage : css.inboxLink}
        name="NewListingParientPage"
      >
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createParentListing" />
        </span>
      </NamedLink>
    );
  const createExperienceLink =
    currentUser && isTeacher ? (
      <NamedLink
        className={isLandingPage ? css.inboxLinklandingPage : css.inboxLink}
        name="NewListingPageWithCategories"
        params={{ category: 'experience' }}
      >
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.addExperience" />
        </span>
      </NamedLink>
    ) : null;
  const curriculumListing =
    currentUser && isTeacher ? (
      <NamedLink
        className={isLandingPage ? css.inboxLinklandingPage : css.inboxLink}
        name="NewListingPageWithCategories"
        params={{ category: config.curriculum }}
      >
        <span className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.addCurriculum" />
        </span>
      </NamedLink>
    ) : null;
  const sectionHelp = (
    <a
      className={isLandingPage ? css.inboxLinklandingPage : css.inboxLink}
      href="http://help.hometribe.com"
      target="__blank"
    >
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.helpLink" />
      </span>
    </a>
  );

  const blogLink = (
    <NamedLink
      name="BlogPostsPage"
      className={isLandingPage ? css.inboxLinklandingPage : css.inboxLink}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.BlogLink" />
      </span>
    </NamedLink>
  );

  const staticPageLinks = (
    <Menu>
      <MenuLabel className={isLandingPage ? css.selectLabelLanding : css.selectLabel}>
        <FormattedMessage id="TopbarDesktop.selectLabel" />
        <span>
          <RiArrowDropDownLine className={css.selectIcon} />
        </span>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="FeaturesForEducatorsPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('FeaturesForEducatorsPage')
            )}
            name="FeaturesForEducatorsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.featuresForEducatorsLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="FeaturesForParentPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('FeaturesForParentPage'))}
            name="FeaturesForParentPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.featuresForParentLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="Pricing">
          <ExternalLink
            className={classNames(css.yourListingsLink, currentPageClass('FeaturesForParentPage'))}
            href="https://help.hometribe.com/en/articles/6570383-pricing"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.pricingLink" />
          </ExternalLink>
        </MenuItem>
        {/* <MenuItem key="NewListingPageWithCategories">
          {currentUser && isTeacher ? (
            <NamedLink
              className={classNames(
                css.yourListingsLink,
                currentPageClass('NewListingPageWithCategories')
              )}
              name="NewListingPageWithCategories"
              params={{ category: config.curriculum }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.addCurriculum" />
            </NamedLink>
          ) : null}
        </MenuItem>

        <MenuItem key="NewListingPageWithCategories">
          {currentUser && isTeacher ? (
            <NamedLink
              className={classNames(
                css.yourListingsLink,
                currentPageClass('NewListingPageWithCategories')
              )}
              name="NewListingPageWithCategories"
              params={{ category: 'experience' }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.addExperience" />
            </NamedLink>
          ) : null}
        </MenuItem> */}
      </MenuContent>
    </Menu>
  );

  const createListingLinkBeforeLogin = (
    <NamedLink
      name="NewListingPage"
      className={isLandingPage ? css.inboxLinklandingPage : css.inboxLink}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.createListingLinkBeforeLogin" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>

      {search}

      {!currentUser ? (
        <>
          {staticPageLinks}
          {sectionHelp}
          {blogLink}
          {createListingLinkBeforeLogin}
        </>
      ) : (
        <>
          {staticPageLinks}
          {isTeacher ? listingLink : null}
          {!isTeacher && currentUser ? createParentLink : null}
          {isTeacher ? createListingLink : null}
          {/* {createExperienceLink} */}
          {/* {curriculumListing} */}
          {/* {createListingLink} */}
          {sectionHelp}
          {blogLink}
          {inboxLink}
          {profileMenu}
        </>
      )}
      {signupLink}
      {loginLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
