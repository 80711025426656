import React, { useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { getDefaultTimeZoneOnBrowser, timestampToDate } from '../../util/dates';
import { LISTING_STATE_DRAFT, DATE_TYPE_DATETIME, propTypes } from '../../util/types';
import {
  Button,
  IconClose,
  IconEdit,
  IconSpinner,
  InlineTextButton,
  ListingLink,
  Modal,
  TimeRange,
} from '../../components';
import {
  EditListingAvailabilityPlanForm,
  EditListingAvailabilityExceptionForm,
  EditListingAvailabilityExperienceForm,
} from '../../forms';
import { getTimeZoneNames } from '../../util/dates';
import Select from 'react-select';
import css from './EditListingAvailabilityPanel.module.css';
import moment from 'moment';
import { listingIsExperience } from '../../containers/ListingPage/ListingPage';

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

// We want to sort exceptions on the client-side, maximum pagination page size is 100,
// so we need to restrict the amount of exceptions to that.
const MAX_EXCEPTIONS_COUNT = 100;

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

/////////////
// Weekday //
/////////////
const findEntry = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.find(d => d.dayOfWeek === dayOfWeek);

const getEntries = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.filter(d => d.dayOfWeek === dayOfWeek);
const formatToAMPM = time => {
  let pieces = time.split(':');
  let hours = +pieces[0];
  let minutes = +pieces[1];
  let am;
  // console.log(hours);
  if (hours == 0) {
    am = true;
    hours = 12;
  } else if (hours > 12) {
    am = false;
    hours = hours - 12;
  } else {
    am = true;
  }

  // console.log({ time, pieces, hours, minutes, am });

  const timeString = `${hours < 10 ? '0' + hours : hours}:${
    minutes < 10 ? '0' + minutes : minutes
  } ${am ? 'AM' : 'PM'}`;

  return timeString;
};
const Weekday = props => {
  const { availabilityPlan, dayOfWeek, openEditModal } = props;
  const hasEntry = findEntry(availabilityPlan, dayOfWeek);

  return (
    <div
      className={classNames(css.weekDay, { [css.blockedWeekDay]: !hasEntry })}
      onClick={() => openEditModal(true)}
      role="button"
    >
      <div className={css.dayOfWeek}>
        <FormattedMessage id={`EditListingAvailabilityPanel.dayOfWeek.${dayOfWeek}`} />
      </div>
      <div className={css.entries}>
        {availabilityPlan && hasEntry
          ? getEntries(availabilityPlan, dayOfWeek).map(e => {
              return (
                <span className={css.entry} key={`${e.dayOfWeek}${e.startTime}`}>{`${formatToAMPM(
                  e.startTime
                )} - ${
                  e.endTime === '00:00' ? formatToAMPM('24:00') : formatToAMPM(e.endTime)
                }`}</span>
              );
            })
          : null}
      </div>
    </div>
  );
};

///////////////////////////////////////////////////
// EditListingAvailabilityExceptionPanel - utils //
///////////////////////////////////////////////////

// Create initial entry mapping for form's initial values
const createEntryDayGroups = (entries = {}) =>
  entries.reduce((groupedEntries, entry) => {
    const { startTime, endTime: endHour, dayOfWeek } = entry;
    const dayGroup = groupedEntries[dayOfWeek] || [];
    return {
      ...groupedEntries,
      [dayOfWeek]: [
        ...dayGroup,
        {
          startTime,
          endTime: endHour === '00:00' ? '24:00' : endHour,
        },
      ],
    };
  }, {});

// Create initial values
const createInitialValues = availabilityPlan => {
  const { timezone, entries } = availabilityPlan || {};
  const tz = timezone || defaultTimeZone();
  return {
    timezone: tz,
    ...createEntryDayGroups(entries),
  };
};

// Create entries from submit values
const createEntriesFromSubmitValues = values =>
  WEEKDAYS.reduce((allEntries, dayOfWeek) => {
    const dayValues = values[dayOfWeek] || [];
    const dayEntries = dayValues.map(dayValue => {
      const { startTime, endTime } = dayValue;
      // Note: This template doesn't support seats yet.
      return startTime && endTime
        ? {
            dayOfWeek,
            seats: 1,
            startTime,
            endTime: endTime === '24:00' ? '00:00' : endTime,
          }
        : null;
    });

    return allEntries.concat(dayEntries.filter(e => !!e));
  }, []);

// Create availabilityPlan from submit values
const createAvailabilityPlan = values => ({
  availabilityPlan: {
    type: 'availability-plan/time',
    timezone: values.timezone,
    entries: createEntriesFromSubmitValues(values),
  },
});

// Ensure that the AvailabilityExceptions are in sensible order.
//
// Note: if you allow fetching more than 100 exception,
// pagination kicks in and that makes client-side sorting impossible.
const sortExceptionsByStartTime = (a, b) => {
  return a.attributes.start.getTime() - b.attributes.start.getTime();
};

//////////////////////////////////
// EditListingAvailabilityPanel //
//////////////////////////////////
const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    availabilityExceptions,
    fetchExceptionsInProgress,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    disabled,
    ready,
    onSubmit,
    onManageDisableScrolling,
    onNextTab,
    submitButtonText,
    updateInProgress,
    errors,
  } = props;
  // Hooks
  const [isEditPlanModalOpen, setIsEditPlanModalOpen] = useState(false);
  const [isEditExceptionsModalOpen, setIsEditExceptionsModalOpen] = useState(false);
  const [valuesFromLastSubmit, setValuesFromLastSubmit] = useState(null);
  const [saveExceptionLoading, setSaveExceptionLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const isCategoryExperience = currentListing?.attributes?.publicData?.category === 'experience';

  const isNextButtonDisabled = isCategoryExperience
    ? !availabilityExceptions?.length
    : !currentListing.attributes.availabilityPlan;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const defaultAvailabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      // { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: 1 },
    ],
  };
  const availabilityPlan = currentListing.attributes.availabilityPlan || defaultAvailabilityPlan;

  const isExperience = listingIsExperience(currentListing);

  const [experienceTimezone, setExperienceTimezone] = useState(
    availabilityPlan?.timezone
      ? {
          value: availabilityPlan?.timezone,
          label: availabilityPlan?.timezone,
        }
      : {}
  );
  const initialValues = valuesFromLastSubmit
    ? valuesFromLastSubmit
    : createInitialValues(availabilityPlan);

  const handleSubmit = values => {
    setValuesFromLastSubmit(values);
    const userData = createAvailabilityPlan(values);

    const entries = userData?.availabilityPlan?.entries;
    // Final Form can wait for Promises to return.
    return onSubmit({
      availabilityPlan: userData?.availabilityPlan,
      publicData: {
        general_availability: entries,
      },
    })
      .then(() => {
        setIsEditPlanModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  const exceptionCount = availabilityExceptions ? availabilityExceptions.length : 0;
  const sortedAvailabilityExceptions = availabilityExceptions.sort(sortExceptionsByStartTime);

  const handleSubmitExperience = values => {
    setValuesFromLastSubmit(values);
    setIsLoading(true);
    const defaultAvailabilityPlan = {
      type: 'availability-plan/time',
      timezone: experienceTimezone.value,
      entries: [
        { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: 0 },
        { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: 0 },
        { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: 0 },
        { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: 0 },
        { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: 0 },
        { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: 0 },
        { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: 0 },
      ],
    };
    return onSubmit({
      availabilityPlan: defaultAvailabilityPlan,
      publicData: {
        experience_entries: (sortedAvailabilityExceptions ?? []).map(e => ({
          start: new Date(e.attributes.start).toISOString(),
          end: new Date(e.attributes.end).toISOString(),
          id: e.id.uuid,
          seats: e.attributes.seats,
        })),
      },
    })
      .then(() => {
        setIsEditPlanModalOpen(false);
        setIsLoading(false);
      })
      .catch(e => {
        setIsLoading(false);
        // Don't close modal if there was an error
      });
  };

  // Save exception click handler
  const saveException = values => {
    const { availability, exceptionStartTime, exceptionEndTime } = values;

    // TODO: add proper seat handling
    const seats = availability === 'available' ? 1 : 0;

    return onAddAvailabilityException({
      listingId: listing.id,
      seats,
      start: timestampToDate(exceptionStartTime),
      end: timestampToDate(exceptionEndTime),
    })
      .then(() => {
        setIsEditExceptionsModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };
  const saveExceptionExperience = values => {
    const { exceptionStartTime, exceptionEndTime, seats } = values;

    // TODO: add proper seat handling
    // const seats = availability === 'available' ? 1 : 0;
    setSaveExceptionLoading(true);
    return onAddAvailabilityException({
      listingId: listing.id,
      seats,
      start: timestampToDate(exceptionStartTime),
      end: timestampToDate(exceptionEndTime),
    })
      .then(async res => {
        await onSubmit({
          publicData: {
            experience_entries: [...(sortedAvailabilityExceptions ?? []), res].map(e => ({
              start: new Date(e.attributes.start).toISOString(),
              end: new Date(e.attributes.end).toISOString(),
              id: e.id.uuid,
              seats: e.attributes.seats,
            })),
          },
        });
        setSaveExceptionLoading(false);
        setIsEditExceptionsModalOpen(false);
      })
      .catch(e => {
        setSaveExceptionLoading(false);
        // Don't close modal if there was an error
      });
  };
  const relevantZonesPattern = new RegExp(
    '^(Africa|America(?!/(Argentina/ComodRivadavia|Knox_IN|Nuuk))|Antarctica(?!/(DumontDUrville|McMurdo))|Asia(?!/Qostanay)|Atlantic|Australia(?!/(ACT|LHI|NSW))|Europe|Indian|Pacific)'
  );
  const options = getTimeZoneNames(relevantZonesPattern)?.map(i => ({ value: i, label: i }));

  return (
    <main className={classes}>
      <h1 className={css.title}>
        {isPublished ? (
          <FormattedMessage
            id="EditListingAvailabilityPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} /> }}
          />
        ) : (
          <FormattedMessage id="EditListingAvailabilityPanel.createListingTitle" />
        )}
      </h1>

      {isCategoryExperience ? (
        <div className={css.experienceTimeZone}>
          <p>
            <FormattedMessage id="EditListingAvailabilityPanel.experienceTimeZone" />
          </p>
          <Select
            options={options}
            placeholder={
              <FormattedMessage id="EditListingAvailabilityPanel.experienceTimeZonePlaceholder" />
            }
            onChange={e => {
              setExperienceTimezone(e);
            }}
            value={experienceTimezone}
          />
        </div>
      ) : (
        <section className={css.section}>
          <header className={css.sectionHeader}>
            <h2 className={css.sectionTitle}>
              <FormattedMessage id="EditListingAvailabilityPanel.defaultScheduleTitle" />
            </h2>
            <InlineTextButton
              className={css.editPlanButton}
              onClick={() => setIsEditPlanModalOpen(true)}
            >
              <IconEdit className={css.editPlanIcon} />{' '}
              <FormattedMessage id="EditListingAvailabilityPanel.edit" />
            </InlineTextButton>
          </header>
          <div className={css.week}>
            {WEEKDAYS.map(w => (
              <Weekday
                dayOfWeek={w}
                key={w}
                availabilityPlan={availabilityPlan}
                openEditModal={setIsEditPlanModalOpen}
              />
            ))}
          </div>
        </section>
      )}
      <section className={css.section}>
        {isCategoryExperience ? null : (
          <header className={css.sectionHeader}>
            <h2 className={css.sectionTitle}>
              {fetchExceptionsInProgress ? (
                <FormattedMessage id="EditListingAvailabilityPanel.availabilityExceptionsTitleNoCount" />
              ) : (
                <FormattedMessage
                  id="EditListingAvailabilityPanel.availabilityExceptionsTitle"
                  values={{ count: exceptionCount }}
                />
              )}
            </h2>
          </header>
        )}
        {fetchExceptionsInProgress ? (
          <div className={css.exceptionsLoading}>
            <IconSpinner />
          </div>
        ) : exceptionCount === 0 ? (
          <div className={css.noExceptions}>
            {isCategoryExperience ? null : (
              <FormattedMessage id="EditListingAvailabilityPanel.noExceptions" />
            )}
          </div>
        ) : (
          <div className={css.exceptions}>
            {sortedAvailabilityExceptions.map(availabilityException => {
              const { start, end, seats } = availabilityException.attributes;
              return (
                <div key={availabilityException.id.uuid} className={css.exception}>
                  <div className={css.exceptionHeader}>
                    <div className={css.exceptionAvailability}>
                      <div
                        className={classNames(css.exceptionAvailabilityDot, {
                          [css.isAvailable]: seats > 0,
                        })}
                      />
                      <div className={css.exceptionAvailabilityStatus}>
                        {seats > 0 ? (
                          <FormattedMessage id="EditListingAvailabilityPanel.exceptionAvailable" />
                        ) : (
                          <FormattedMessage id="EditListingAvailabilityPanel.exceptionNotAvailable" />
                        )}
                      </div>
                    </div>
                    <button
                      className={css.removeExceptionButton}
                      onClick={() =>
                        onDeleteAvailabilityException({ id: availabilityException.id }).then(
                          async res => {
                            if (isExperience) {
                              await onSubmit({
                                publicData: {
                                  experience_entries: (sortedAvailabilityExceptions ?? [])
                                    .filter(ex => ex.id.uuid != res.id.uuid)
                                    .map(e => ({
                                      start: new Date(e.attributes.start).toISOString(),
                                      end: new Date(e.attributes.end).toISOString(),
                                      id: e.id.uuid,
                                      seats: e.attributes.seats,
                                    })),
                                },
                              });
                            }
                          }
                        )
                      }
                    >
                      <IconClose size="normal" className={css.removeIcon} />
                    </button>
                  </div>
                  <TimeRange
                    className={css.timeRange}
                    startDate={start}
                    endDate={end}
                    dateType={DATE_TYPE_DATETIME}
                    timeZone={availabilityPlan.timezone}
                  />
                  {isCategoryExperience ? (
                    <div className={css.timeRange}>Seats: {seats}</div>
                  ) : null}
                </div>
              );
            })}
          </div>
        )}
        {exceptionCount <= MAX_EXCEPTIONS_COUNT ? (
          <InlineTextButton
            className={css.addExceptionButton}
            onClick={() => setIsEditExceptionsModalOpen(true)}
            disabled={disabled}
            ready={ready}
          >
            {isCategoryExperience ? (
              <FormattedMessage id="EditListingAvailabilityPanel.addExperienceException" />
            ) : (
              <FormattedMessage id="EditListingAvailabilityPanel.addException" />
            )}
          </InlineTextButton>
        ) : null}
      </section>
      {errors.showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAvailabilityPanel.showListingFailed" />
        </p>
      ) : null}
      {!isPublished ? (
        <Button
          className={css.goToNextTabButton}
          onClick={async () => {
            isCategoryExperience ? await handleSubmitExperience() : null;
            await onNextTab();
          }}
          inProgress={isLoading}
          disabled={isNextButtonDisabled}
        >
          {submitButtonText}
        </Button>
      ) : isCategoryExperience ? (
        <Button
          className={css.goToNextTabButton}
          onClick={async () => {
            isCategoryExperience ? await handleSubmitExperience() : null;
          }}
          inProgress={isLoading}
          disabled={isNextButtonDisabled}
        >
          {submitButtonText}
        </Button>
      ) : null}
      {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityPlan"
          isOpen={isEditPlanModalOpen}
          onClose={() => setIsEditPlanModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
        >
          <EditListingAvailabilityPlanForm
            formId="EditListingAvailabilityPlanForm"
            listingTitle={currentListing.attributes.title}
            availabilityPlan={availabilityPlan}
            weekdays={WEEKDAYS}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            inProgress={updateInProgress}
            fetchErrors={errors}
          />
        </Modal>
      ) : null}
      {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityExceptions"
          isOpen={isEditExceptionsModalOpen}
          onClose={() => setIsEditExceptionsModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
        >
          {isCategoryExperience ? (
            <EditListingAvailabilityExperienceForm
              formId="EditListingAvailabilityExperienceForm"
              onSubmit={saveExceptionExperience}
              timeZone={availabilityPlan.timezone}
              availabilityExceptions={sortedAvailabilityExceptions}
              updateInProgress={saveExceptionLoading}
              fetchErrors={errors}
            />
          ) : (
            <EditListingAvailabilityExceptionForm
              formId="EditListingAvailabilityExceptionForm"
              onSubmit={saveException}
              timeZone={availabilityPlan.timezone}
              availabilityExceptions={sortedAvailabilityExceptions}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
            />
          )}
        </Modal>
      ) : null}
    </main>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  availabilityExceptions: [],
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  fetchExceptionsInProgress: bool.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
