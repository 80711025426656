import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { ExternalLink, NamedLink } from '../../components';
import { injectIntl } from '../../util/reactIntl';

import css from './SectionExperienceOffered.module.css';

import one from './Images/one.JPG';
import two from './Images/two.JPG';
import three from './Images/three.JPG';
import four from './Images/four.JPG';
class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};
const SectionExperienceOffered = props => {
  const { rootClassName, className, intl } = props;

  const classes = classNames(rootClassName || css.root, className);

  const formattedMessage = id => intl.formatMessage({ id: `SectionProgramsOffered.${id}` });

  const programsOfferedCardOneLabel = formattedMessage('CardOneLabel');
  const programsOfferedCardTwoLabel = formattedMessage('CardTwoLabel');
  const programsOfferedCardThreeLabel = formattedMessage('CardThreeLabel');
  const programsOfferedCardFourLabel = formattedMessage('CardFourLabel');

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionExperienceOffered.title" />
      </div>
      <p className={css.subTitle}>
        <FormattedMessage id="SectionExperienceOffered.subtitle" />
      </p>
      <div className={css.locations}>
        {locationLink(
          programsOfferedCardOneLabel,
          one,
          '?pub_program_offered=has_any:homeschool_co_op'
        )}
        {locationLink(programsOfferedCardTwoLabel, two, '?pub_category=meet_up')}
        {locationLink(
          programsOfferedCardThreeLabel,
          three,
          '?pub_program_offered=has_any:homeschool_co_op'
        )}
        {locationLink(
          programsOfferedCardFourLabel,
          four,
          '?pub_category=teacher&pub_offersExperiences=true'
        )}
      </div>
    </div>
  );
};

SectionExperienceOffered.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionExperienceOffered.propTypes = {
  rootClassName: string,
  className: string,
};

export default injectIntl(SectionExperienceOffered);
