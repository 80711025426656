import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import Video from 'twilio-video';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedRedirect,
  Page,
} from '..';
import { LoadingPage } from '../../containers/LoadingPage/LoadingPage';
// import LocalParticipant from './LocalParticipant';
// import LocalParticipantOnly from './LocalParticipantOnly';
import MultipleParticipantsVideoCall from './MutipleParticipantsVideoCall';
// import RemoteParticipant from './RemoteParticipant';
import StartVideoCallPage from './StartVideoCall';
// import VideoCall from './VideoCall';
import { ensureCurrentUser } from '../../util/data';
import { injectIntl } from '../../util/reactIntl';
import { TopbarContainer } from '../../containers';
import errorImage from '../../assets/error.svg';
// const token =
//   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2RkYWZiMjI3NDA5NGNkMjQ5OGZmNzA0ZjI2NWY5YmQ0LTE2NDMwMDI3NTQiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJjdXN0b21lcklkIiwidmlkZW8iOnsicm9vbSI6InByb3ZpZGVySWQ6cHJvdmlkZXJMaXN0aW5nSWQifX0sImlhdCI6MTY0MzAwMjc1NCwiZXhwIjoxNjQzMDA2MzU0LCJpc3MiOiJTS2RkYWZiMjI3NDA5NGNkMjQ5OGZmNzA0ZjI2NWY5YmQ0Iiwic3ViIjoiQUNiNzUzMDdiMGUyOWQ1NWEwZjdiODBmMGY1Yzk4ZDY5NiJ9.ZJSFLPdUvUlkSwMHhkR02qF7LSF3mR2kokw6yo2RPN4';
function VideoPanel(props) {
  const { token, transaction } = props?.location?.state || {};
  const { user, history, intl } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const currentUser = ensureCurrentUser(user);

  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);

  if (!transaction) {
    return <NamedRedirect name="NotFoundPage" />;
  }

  const currentTransaction = JSON.parse(transaction);
  const roomName = `${currentTransaction.customer.id.uuid}:${currentTransaction.listing.id.uuid}:${currentTransaction.provider.id.uuid}`;

  // const roomName =
  //   '61dbe625-48d2-40d1-ba03-441f1893f066:61dc2bad-b28a-4f1a-b34c-7be34394f1c9:61a46860-1f4b-4167-ba54-bb8ff00b3ed8';

  useEffect(() => {
    const participantConnected = participant => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    };
    const participantDisconnected = participant => {
      setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
    };
    setLoading(true);
    Video.connect(token, {
      name: roomName,
      // video: { width: 720 },
    })
      .then(room => {
        setRoom(room);
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
        room.participants.forEach(participantConnected);
      })
      .catch(async err => {
        alert(err.message);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  const pageTitle = intl.formatMessage({ id: 'LiveWorkshop.PageTitle' });

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return (
      <Page
        title={pageTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: pageTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className="max-w-6xl sm:mx-auto sm:my-4 m-8 text-red-500">
              {/* <h1 className="text-4xl text-center text-red-500 font-bold mb-3">
                Something went wrong
              </h1> */}
              <div className="flex justify-center mt-10 mb-6">
                <img src={errorImage} loading="lazy" alt="error" className="w-96" />
              </div>

              <p className="my-0 mt-4 text-base leading-snug ">
                We were unable to connect you to the live workshop. This may happen due to one of
                the following reasons:
              </p>
              <ul className="pl-6 mt-2" style={{ listStyle: 'disc' }}>
                <li className="leading-tight">You are not using the HTTPS Protocol.</li>
                <li className="leading-tight">
                  You may have denied the camera and microphone permissions.
                </li>
                <li className="leading-tight">Your join token was invalid.</li>
                <li className="leading-tight">Your browser does support the service.</li>
                <li className="leading-tight">
                  The booking for which you are requesting to join the video call may have been
                  completed.
                </li>
              </ul>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

  if (room?.localParticipant && participants.length == 0) {
    // return <LocalParticipantOnly room={room} />;
    return (
      <Page
        title={pageTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: pageTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar></LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <StartVideoCallPage
              txn={currentTransaction}
              room={room}
              participant={room?.localParticipant}
              currentUser={currentUser}
            />
          </LayoutWrapperMain>
        </LayoutSingleColumn>
      </Page>
    );
  }

  return (
    <Page
      title={pageTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: pageTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar></LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <MultipleParticipantsVideoCall
            currentUser={currentUser}
            room={room}
            txn={currentTransaction}
            localParticipant={room?.localParticipant}
            remoteParticipants={participants}
          />
        </LayoutWrapperMain>
      </LayoutSingleColumn>
    </Page>
  );

  // return <VideoCall localParticipant={room?.localParticipant} participants={participants} />;

  // TODO: USE WHEN MULTIPLE PEOPLE JOIN THE MEETING
  // return (
  //   <div>
  //     <h1 className="text-center text-4xl text-gray-600 font-bold">Live Workshop</h1>
  //     <div className="grid grid-cols-3 gap-3 max-w-7xl mx-auto my-6">
  //       {room?.localParticipant && <LocalParticipant participant={room.localParticipant} />}
  //       {participants.map(participant => (
  //         <RemoteParticipant key={participant.sid} participant={participant} />
  //       ))}
  //     </div>
  //   </div>
  // );
}

const mapStateToProps = state => ({ user: state.user.currentUser });

export default compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    null
  )
)(VideoPanel);

// function LocalParticipant({ participant, full }) {
//   const [videoTracks, setVideoTracks] = useState([]);
//   const [audioTracks, setAudioTracks] = useState([]);
//   const videoRef = useRef();
//   const audioRef = useRef();
//   const trackpubsToTracks = trackMap =>
//     Array.from(trackMap.values())
//       .map(publication => publication.track)
//       .filter(track => track !== null);
//   useEffect(() => {
//     const trackSubscribed = track => {
//       if (track.kind === 'video') {
//         setVideoTracks(videoTracks => [...videoTracks, track]);
//       } else {
//         setAudioTracks(audioTracks => [...audioTracks, track]);
//       }
//     };

//     const trackUnsubscribed = track => {
//       if (track.kind === 'video') {
//         setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
//       } else {
//         setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
//       }
//     };
//     setVideoTracks(trackpubsToTracks(participant.videoTracks));
//     setAudioTracks(trackpubsToTracks(participant.audioTracks));

//     participant.on('trackSubscribed', trackSubscribed);
//     participant.on('trackUnsubscribed', trackUnsubscribed);
//     return () => {
//       setVideoTracks([]);
//       setAudioTracks([]);
//       participant.removeAllListeners();
//     };
//   }, [participant]);
//   useEffect(() => {
//     const videoTrack = videoTracks[0];
//     const audioTrack = audioTracks[0];
//     if (videoTrack && audioTrack) {
//       videoTrack.attach(videoRef.current);
//       audioTrack.attach(audioRef.current);
//       return () => {
//         videoTrack.detach();
//         audioTrack.detach();
//       };
//     }
//   }, [videoTracks, audioTracks]);

//   return (
//     <div
//       className={`participant w-full flex ${
//         full ? 'h-full' : 'h-full'
//       }  object-contain shadow-sm aspect-square rounded overflow-hidden shadow-slate-300 border-8 border-white border-solid items-center justify-center`}
//       style={{ transform: 'rotateY(180deg)' }}
//     >
//       <video ref={videoRef} autoPlay={true} className="block aspect-square object-cover" />
//       <audio ref={audioRef} autoPlay={true} />
//     </div>
//   );
// }
