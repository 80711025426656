import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '..';

import css from './SectionLocations2.module.css';

import ncImage from './images/nc-photo.jpg';
import one from './images/one.jpg';
import two from './images/two.jpg';
import three from './images/three.jpg';
import four from './images/four.jpg';
import five from './images/five.jpg';
import { injectIntl } from '../../util/reactIntl';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
        <p className={css.textLabel}>{nameText}</p>
      </div>
      {/* <div className={css.linkText}>{nameText}</div> */}
    </NamedLink>
  );
};

const SectionLocations2 = props => {
  const { rootClassName, className, intl } = props;

  const classes = classNames(rootClassName || css.root, className);

  const formattedMessage = id => intl.formatMessage({ id: `SectionLocations.${id}` });

  const locationOneLabel = formattedMessage('locationOneLabel');
  const locationTwoLabel = formattedMessage('locationTwoLabel');
  const locationThreeLabel = formattedMessage('locationThreeLabel');
  const locationFourLabel = formattedMessage('locationFourLabel');
  const locationFiveLabel = formattedMessage('locationFiveLabel');
  const locationSixLabel = formattedMessage('locationSixLabel');

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations2.title" />
      </div>
      <div className={css.locations}>
        {locationLink(
          locationOneLabel,
          one,
          '?address=Santa%20Cruz%2C%20California%2C%20United%20States&bounds=37.157157949%2C-121.946400655%2C36.948094007%2C-122.226522993'
        )}
        {locationLink(
          locationTwoLabel,
          two,
          '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.0722089014065%2C-116.853120012588%2C32.5348710309019%2C-117.262118935776'
        )}
        {locationLink(
          locationThreeLabel,
          three,
          '?address=Maryland%2C%20United%20States&bounds=39.7228839999824%2C-74.9497336859328%2C37.8575062%2C-79.4876519987074'
        )}
        {locationLink(
          locationFourLabel,
          four,
          '?address=Silicon%20Valley%2C%20Madhapur%2C%20Hyderabad%2C%20Ranga%20Reddy%2C%20Telangana%2C%20India&bounds=17.452879703%2C78.385812218%2C17.446724592%2C78.381187094'
        )}
        {locationLink(
          locationFiveLabel,
          five,
          '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934'
        )}
        {locationLink(
          locationSixLabel,
          ncImage,
          '?address=Sacramento%2C%20California%2C%20United%20States&bounds=38.769524903%2C-121.242183223%2C38.365546113%2C-121.634127998'
        )}
      </div>
    </div>
  );
};

SectionLocations2.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations2.propTypes = {
  rootClassName: string,
  className: string,
};

export default injectIntl(SectionLocations2);
