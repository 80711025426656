import React from 'react';
import { ExternalLink } from '../../components';
import { FaShippingFast, FaDownload } from 'react-icons/fa';
import { txIsCanceled, txIsDeclined } from '../../util/transaction';
import { injectIntl } from '../../util/reactIntl';

function ShippingInfoPanel({ tx, isProvider, intl }) {
  const shippingOrderInfo = tx.attributes?.protectedData?.shippo_order_information;

  const declined = txIsDeclined(tx) || txIsCanceled(tx);
  const isValid = shippingOrderInfo?.status === 'SUCCESS';

  if (!isValid || declined) return null;

  const downloadLabelText = intl.formatMessage({ id: 'TransactionPanel.downloadLabel' });
  const trackPackageText = intl.formatMessage({ id: 'TransactionPanel.trackPackage' });

  const labelButton =
    isProvider && shippingOrderInfo.label_url ? (
      <ExternalLink
        className="w-full text-lg flex gap-2 items-center justify-center bg-blue-500 text-white hover:bg-blue-600 transition duration-150 ease-linear hover:no-underline text-center py-2 sm:py-2 shadow rounded"
        href={shippingOrderInfo.label_url}
      >
        <FaDownload />
        <span>{downloadLabelText}</span>
      </ExternalLink>
    ) : null;

  const trackingButton = shippingOrderInfo?.tracking_url_provider ? (
    <ExternalLink
      className="w-full flex gap-2 text-lg items-center justify-center bg-green-500  text-black hover:bg-green-600 transition duration-150 ease-linear hover:no-underline text-center py-2 sm:py-2 shadow rounded"
      href={shippingOrderInfo?.tracking_url_provider}
    >
      <FaShippingFast className="text-black" />
      <span>{trackPackageText}</span>
    </ExternalLink>
  ) : null;

  return (
    <div className="flex flex-row gap-2 mt-2">
      {labelButton}
      {trackingButton}
    </div>
  );
}

export default injectIntl(ShippingInfoPanel);
