/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

// export const foreignLanguageOptions = [
//   { label: 'French', value: 'fr', key: 'fr' },
//   { label: 'Italian', value: 'it', key: 'it' },
//   { label: 'Spanish', value: 'es', key: 'es' },
//   { label: 'Manderin', value: 'mn', key: 'mn' },
//   { label: 'German', value: 'de', key: 'de' },
//   { label: 'Russian', value: 'ru', key: 'ru' },
//   { label: 'Arabic', value: 'ar', key: 'ar' },
//   { label: 'Latin', value: 'la', key: 'la' },
//   { label: 'Hindi', value: 'hi', key: 'hi' },
//   { label: 'Portuguese', value: 'pt', key: 'pt' },
//   { label: 'Indonesian', value: 'id', key: 'id' },
// ];

import cloudsOne from '../src/BannerImages/clouds-1.jpg';
import cloudsTwo from '../src/BannerImages/clouds-2.jpg';
import natureBgOne from '../src/BannerImages/nature-background-1.jpg';
import natureBgTwo from '../src/BannerImages/nature-background-2.jpg';
import natureBgThree from '../src/BannerImages/nature-background-3.jpg';
import natureBgFour from '../src/BannerImages/nature-background-4.jpg';
import oceanBgOne from '../src/BannerImages/profile-ocean-background-1.jpg';
import oceanBgTwo from '../src/BannerImages/profile-ocean-background-2.jpg';
import oceanBgThree from '../src/BannerImages/profile-ocean-background-3.jpg';
import oceanBgFour from '../src/BannerImages/profile-ocean-background-4.jpg';
import oceanBgFive from '../src/BannerImages/profile-ocean-background-5.jpg';
import oceanBgSix from '../src/BannerImages/profile-ocean-background-6.jpg';
export const featuredListingId = [
  '6204a400-71ab-4540-8c41-80036f129f8f',
  '620f0620-33f5-4d71-b4b7-03fbd5dbeb99',
  '6211173f-50f7-480b-8058-d924f96336fe',
  '62180852-47bb-49a8-9fd4-3f31a9188047',
  '6308f4f2-66c4-4080-bade-9f84a3c0e4b5',
  '6280772d-0357-4429-bc58-1d6c6b32c451',
  '629e77e9-e1a6-452a-908b-cecee9f8279d',
  '624cc571-a226-4974-9602-beafb6d7dedc',
];

export const familyInterestOptions = [
  { label: 'Hiking', value: 'hiking', key: 'hiking' },
  { label: 'Biking', value: 'biking', key: 'biking' },
  { label: 'Surfing', value: 'surfing', key: 'surfing' },
  { label: 'Boating', value: 'boating', key: 'boating' },
  { label: 'Camping', value: 'camping', key: 'camping' },
  { label: 'Baseball', value: 'baseball', key: 'baseball' },
  { label: 'Football', value: 'football', key: 'football' },
  { label: 'Basketball', value: 'basketball', key: 'basketball' },
  { label: 'Volleyball', value: 'volleyball', key: 'volleyball' },
  { label: 'Soccer', value: 'soccer', key: 'soccer' },
  { label: 'Dance', value: 'dance', key: 'dance' },
  { label: 'Martial Arts', value: 'martial-arts', key: 'martial-arts' },
  { label: 'Skateboarding', value: 'skateboarding', key: 'skateboarding' },
  { label: 'Gymnastics', value: 'gymnastics', key: 'gymnastics' },
  { label: 'Golfing', value: 'golfing', key: 'golfing' },
  { label: 'Running', value: 'running', key: 'running' },
  { label: 'Tennis', value: 'tennis', key: 'tennis' },
  { label: 'Rock Climbing', value: 'rock-climbing', key: 'rock-climbing' },
  { label: 'Snow Activities', value: 'snow-activities', key: 'snow-activities' },
  { label: 'Survival Skills', value: 'survival-skills', key: 'survival-skills' },
];

export const favoriteFamilyOutingsOptions = [
  { label: 'Beaches', value: 'beaches', key: 'beaches' },
  { label: 'Forests', value: 'forests', key: 'forests' },
  { label: 'Lakes', value: 'lakes', key: 'lakes' },
  { label: 'Rivers', value: 'rivers', key: 'rivers' },
  { label: 'Mountains', value: 'mountains', key: 'mountains' },
  { label: 'Deserts', value: 'deserts', key: 'deserts' },
  { label: 'Museums', value: 'museums', key: 'museums' },
  { label: 'Movies', value: 'movies', key: 'movies' },
  { label: 'Farms', value: 'farms', key: 'farms' },
  { label: 'Gardens', value: 'gardens', key: 'gardens' },
  { label: 'Restaurants', value: 'restaurants', key: 'restaurants' },
  { label: 'Art Venues', value: 'art-venues', key: 'art-venues' },
  { label: 'Historic Locations', value: 'historic-locations', key: 'historic-locations' },
  { label: 'Road Tripping', value: 'road-tripping', key: 'road-tripping' },
];

export const indoreFamilyInterestOptions = [
  { label: 'Reading', value: 'reading', key: 'reading' },
  { label: 'Writing', value: 'writing', key: 'writing' },
  { label: 'Puzzles', value: 'puzzles', key: 'puzzles' },
  { label: 'Play Dates', value: 'play-dates', key: 'play-dates' },
  { label: 'Coffee Dates', value: 'coffee-dates', key: 'coffee-dates' },
  { label: 'Gardening', value: 'gardening', key: 'gardening' },
  { label: 'Cooking', value: 'cooking', key: 'cooking' },
  { label: 'Video Gaming', value: 'video-gaming', key: 'video-gaming' },
  { label: 'Painting', value: 'painting', key: 'painting' },
  { label: 'Arts and Crafts', value: 'arts-and-crafts', key: 'arts-and-crafts' },
  { label: 'Music', value: 'music', key: 'music' },
  { label: 'Board Games', value: 'board-games', key: 'board-games' },
  { label: 'Backyard Games', value: 'backyard-games', key: 'backyard-games' },
];
export const massUnits = [
  { label: 'g', key: 'g' },
  { label: 'kg', key: 'kg' },
  { label: 'lb', key: 'lb' },
  { label: 'oz', key: 'oz' },
];
export const dimensionUnits = [
  { label: 'cm', key: 'cm' },
  { label: 'm', key: 'm' },
  { label: 'in', key: 'in' },
  { label: 'ft', key: 'ft' },
  { label: 'mm', key: 'mm' },
  { label: 'yd', key: 'yd' },
];

export const curriculumFileFormat = [
  { label: 'Physical', value: 'physical', key: 'physical' },
  { label: 'Digital', value: 'digital', key: 'digital' },
  // { label: 'Google Apps', value: 'google_apps', key: 'google_apps' },
  // { label: 'Canva', value: 'canva', key: 'canva' },
  // { label: 'Microsoft', value: 'microsoft', key: 'microsoft' },
  // { label: 'PDF', value: 'pdf', key: 'pdf' },
  // { label: 'Image', value: 'image', key: 'image' },
  // { label: 'Video', value: 'video', key: 'video' },
];

export const digitalOptions = [
  { label: 'PDF', value: 'pdf', key: 'pdf' },
  { label: 'Image', value: 'image', key: 'image' },
  { label: 'Video', value: 'video', key: 'video' },
  { label: 'Zip', value: 'zip', key: 'zip' },
  { label: 'Downloadable URL Link', value: 'downloadable_url_link', key: 'downloadable_url_link' },
];
export const curriculumResourceType = [
  {
    label: 'Independent Work Packet',
    value: 'independent_work_packet',
    key: 'independent_work_packet',
  },
  { label: 'Worksheets', value: 'worksheets', key: 'worksheets' },
  { label: 'Lesson Plans', value: 'lesson_plans', key: 'lesson_plans' },
  { label: 'Printables', value: 'printables', key: 'printables' },
  { label: 'Activities', value: 'activities', key: 'activities' },
  { label: 'Assessment', value: 'assessment', key: 'assessment' },
  { label: 'Projects', value: 'projects', key: 'projects' },
  { label: 'Books', value: 'books', key: 'books' },
  { label: 'Merchandise', value: 'merchandise', key: 'merchandise' },
  { label: 'Booklet', value: 'booklet', key: 'booklet' },
  { label: 'Course', value: 'course', key: 'course' },
  { label: 'Homeschool Apparel', value: 'homeschool-apparel', key: 'homeschool-apparel' },
  { label: 'Apparel', value: 'apparel', key: 'apparel' },
];
export const curriculumType = [
  { label: 'Math', value: 'math', key: 'math' },
  { label: 'Science', value: 'science', key: 'science' },
  { label: 'History', value: 'history', key: 'history' },
  { label: 'English', value: 'english', key: 'english' },
  { label: 'Reading', value: 'reading', key: 'reading' },
  { label: 'Foreign Language', value: 'foreignLanguage', key: 'foreignLanguage' },
  { label: 'Specialty', value: 'specialty', key: 'specialty' },
  { label: 'Crafting', value: 'crafting', key: 'crafting' },
  { label: 'Merchandise', value: 'merchandise', key: 'merchandise' },
  { label: 'For Parents', value: 'for-parents', key: 'for-parents' },
  { label: 'Educational Toys', value: 'educational-toys', key: 'educational-toys' },
];
export const curriculumGradeSpan = [
  { label: 'Pre-k', value: 'pre-k', key: 'pre-k' },
  { label: 'Kindergarten', value: 'kindergarten', key: 'kindergarten' },
  { label: '1st Grade', value: '1st_grade', key: '1st_grade' },
  { label: '2nd Grade', value: '2nd_grade', key: '2nd_grade' },
  { label: '3rd Grade', value: '3rd_grade', key: '3rd_grade' },
  { label: '4th Grade', value: '4th_grade', key: '4th_grade' },
  { label: '5th Grade', value: '5th_grade', key: '5th_grade' },
  { label: '6th Grade', value: '6th_grade', key: '6th_grade' },
  { label: '7th Grade', value: '7th_grade', key: '7th_grade' },
  { label: '8th Grade', value: '8th_grade', key: '8th_grade' },
  { label: '9th Grade', value: '9th_grade', key: '9th_grade' },
  { label: '10th Grade', value: '10th_grade', key: '10th_grade' },
  { label: '11th Grade', value: '11th_grade', key: '11th_grade' },
  { label: '12th Grade', value: '12th_grade', key: '12th_grade' },
  { label: 'Early Learning', value: 'early_learning', key: 'early_learning' },
  { label: 'For Parents', value: 'for_parents', key: 'for_parents' },
  { label: 'College Level', key: 'college_level', value: 'college_level' },
  { label: 'Adult', key: 'adult', value: 'adult' },
  { label: 'All Ages', key: 'all_ages', value: 'all_ages' },
];

export const curriculumStyle = [
  { label: 'Montessori', value: 'montessori', key: 'montessori' },
  { label: 'Eclectic', value: 'eclectic', key: 'eclectic' },
  { label: 'Waldorf', value: 'waldorf', key: 'waldorf' },
  { label: 'Classical', value: 'classical', key: 'classical' },
  { label: 'Traditional', value: 'traditional', key: 'traditional' },
  { label: 'Unschooling', value: 'unschooling', key: 'unschooling' },
  { label: 'Charlotte Mason', value: 'charlotte_mason', key: 'charlotte_mason' },
  { label: 'Unit Studies', value: 'unit_studies', key: 'unit_studies' },
  {
    label: 'Special Needs Education',
    value: 'special_needs_education',
    key: 'special_needs_education',
  },
  { label: 'Foreign Language', value: 'foreign_language', key: 'foreign_language' },

  { label: 'For Parents', value: 'for_parents', key: 'for_parents' },
  { label: 'Homeschool Apparel', value: 'homeschool-apparel', key: 'homeschool-apparel' },
  { label: 'Apparel', value: 'apparel', key: 'apparel' },
  { label: 'Merchandise', value: 'merchandise', key: 'merchandise' },
  { label: 'Any', value: 'any', key: 'any' },
];
export const tutoringOptions = [
  { label: 'Math', key: 'math', value: 'math' },
  { label: 'Algebra', key: 'algebra', value: 'algebra' },
  { label: 'Statistics', key: 'statistics', value: 'statistics' },
  { label: 'Geometry', key: 'geometry', value: 'geometry' },
  { label: 'Calculus', key: 'calculus', value: 'calculus' },
  { label: 'Science', key: 'science', value: 'science' },
  { label: 'Chemistry', key: 'chemistry', value: 'chemistry' },
  { label: 'Biology', key: 'biology', value: 'biology' },
  { label: 'Physics', key: 'physics', value: 'physics' },
  { label: 'Computer Science', key: 'computer_science', value: 'computer_science' },
  { label: 'Psychology', key: 'psychology', value: 'psychology' },
  { label: 'History', key: 'history', value: 'history' },
  { label: 'Reading', key: 'reading', value: 'reading' },
  { label: 'Spanish', key: 'spanish', value: 'spanish' },
  { label: 'English', key: 'english', value: 'english' },
  { label: 'Language Arts', key: 'language arts', value: 'language arts' },
  { label: 'French', value: 'fr', key: 'fr' },
  { label: 'Italian', value: 'it', key: 'it' },
  { label: 'Manderin', value: 'mn', key: 'mn' },
  { label: 'German', value: 'de', key: 'de' },
  { label: 'Russian', value: 'ru', key: 'ru' },
  { label: 'Arabic', value: 'ar', key: 'ar' },
  { label: 'Latin', value: 'la', key: 'la' },
  { label: 'Hindi', value: 'hi', key: 'hi' },
  { label: 'Portuguese', value: 'pt', key: 'pt' },
  { label: 'Indonesian', value: 'id', key: 'id' },
];

export const experienceCategory = [
  { label: 'Cooking', key: 'cooking', value: 'cooking' },
  { label: 'Art', key: 'art', value: 'art' },
  { label: 'Nature', key: 'nature', value: 'nature' },
  { label: 'Outdoors', key: 'outdoors', value: 'outdoors' },
  { label: 'Video Course', key: 'video_course', value: 'video_course' },
  { label: 'Sports', key: 'sports', value: 'sports' },
  { label: 'Fitness', key: 'fitness', value: 'fitness' },
  { label: 'Music', key: 'music', value: 'music' },
  { label: 'Theater', key: 'theater', value: 'theater' },
  { label: 'Animals', key: 'animals', value: 'animals' },
  { label: 'Education Program', key: 'education_program', value: 'education_program' },
  { label: 'Event', key: 'event', value: 'event' },
  { label: 'Coaching', key: 'coaching', value: 'coaching' },
  {
    label: 'Travel',
    key: 'travel',
    value: 'travel',
  },
  {
    label: 'Vacation',
    key: 'vacation',
    value: 'vacation',
  },
  {
    label: 'Camping',
    key: 'camping',
    value: 'camping',
  },
];

export const contactOptions = [
  { label: 'Parent Led Home Education Pod', key: 'parent_led_home_education_pod' },
  { label: 'Home Education Pod', key: 'home_education_pod' },
  { label: 'Home Education Pod (trading time)', key: 'home_education_pod_trading_time' },
  { label: 'Home Education Co-Op', key: 'home_education_co_op' },
  { label: 'Enrichment/Learning Center', key: 'enrichment_learning_center' },
  { label: 'Resource Center', key: 'resource_center' },
  { label: 'Academy', key: 'academy' },
  { label: 'Microschool ', key: 'microschool' },
  { label: 'Online Program', key: 'online_program' },
  {
    label: 'Travel Company',
    key: 'travel_company',
  },
  {
    label: 'Vacation Company',
    key: 'vacation_company',
  },

  {
    label: 'General Studies',
    key: 'general_studies',
  },
  {
    label: 'Birthing Center',
    key: 'birthing_center',
  },
  {
    label: 'Pregnancy Services',
    key: 'pregnancy_services',
  },
  {
    label: 'Pregnancy Center',
    key: 'pregnancy_center',
  },
  {
    label: 'Homebirth Services',
    key: 'homebirth_services',
  },
  {
    label: 'Womens Wellness Services ',
    key: 'womens_wellness_services',
  },
  // { label: 'Curriculum + Materials', key: 'curriculum_materials' },
];

export const oneInOneInPerson = [
  { label: 'Tutoring', key: 'tutoring_in_person' },
  { label: 'Enrichment Activities', key: 'enrichment_activities_in_person' },
  { label: 'Therapy/Counseling Session', key: 'therapy_counseling_session_in_person' },
  { label: 'Home Education Workshop', key: 'home_education_workshop_in_person' },
  { label: 'Home Education Consultation', key: 'home_education_consultation_in_person' },
  { label: 'Sports Coaching', key: 'sports_coaching_in_person' },
  { label: 'Coaching', key: 'coaching_in_person' },
  { label: 'Record Keeping Services', key: 'record_keeping_services_in_person' },
  { label: 'IEP Services', key: 'iep_services_in_person' },
  { label: 'Doula/Midwife Services', key: 'doula_midwife_services_in_person' },
  {
    label: 'General Studies',
    key: 'general_studies_in_person',
  },
  {
    label: 'Birthing Center',
    key: 'birthing_center_in_person',
  },
  {
    label: 'Pregnancy Services',
    key: 'pregnancy_services_in_person',
  },
  {
    label: 'Pregnancy Center',
    key: 'pregnancy_center_in_person',
  },
  {
    label: 'Homebirth Services',
    key: 'homebirth_services_in_person',
  },
  {
    label: 'Womens Wellness Services ',
    key: 'womens_wellness_services_in_person',
  },
];
export const oneInOneOnline = [
  { label: 'Therapy/Counseling Session', key: 'therapy_counseling_session_online' },
  { label: 'Home Education Workshop', key: 'home_education_workshop_online' },
  { label: 'Home Education Consultation', key: 'home_education_consultation_online' },
  { label: 'Tutoring', key: 'tutoring_online' },
  { label: 'Enrichment Activities', key: 'enrichment_activities_online' },
  { label: 'Coaching', key: 'coaching_online' },
  { label: 'Record Keeping Services', key: 'record_keeping_services_online' },
  { label: 'IEP Services', key: 'iep_services_online' },
  { label: 'Doula/Midwife Services', key: 'doula_midwife_services_online' },
  {
    label: 'General Studies',
    key: 'general_studies_online',
  },
  {
    label: 'Birthing Center',
    key: 'birthing_center_online',
  },
  {
    label: 'Pregnancy Services',
    key: 'pregnancy_services_online',
  },
  {
    label: 'Pregnancy Center',
    key: 'pregnancy_center_online',
  },
  {
    label: 'Homebirth Services',
    key: 'homebirth_services_online',
  },
  {
    label: 'Womens Wellness Services ',
    key: 'womens_wellness_services_online',
  },
];
export const availabilityType = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
];
export const experienceOptions = [
  { label: 'Free', value: 'free', key: 'free' },
  { label: 'Paid', value: 'paid', key: 'paid' },
];
export const experienceTypes = [
  { label: 'Online', value: 'online', key: 'online' },
  { label: 'In-person', value: 'in-person', key: 'in-person' },
];
export const gender = [
  { label: 'Boy', key: 'male', value: 'male' },
  { label: 'Girl', key: 'female', value: 'female' },
];
export const sportsCoaching = [
  { label: 'Football', key: 'football', value: 'football' },
  { label: 'Soccer', key: 'soccer', value: 'soccer' },
  { label: 'Tennis', key: 'tennis', value: 'tennis' },
  { label: 'Volleyball', key: 'volleyball', value: 'volleyball' },
  { label: 'Baseball', key: 'baseball', value: 'baseball' },
  { label: 'Basketball', key: 'basketball', value: 'basketball' },
  { label: 'Golf', key: 'golf', value: 'golf' },
  { label: 'Softball', key: 'softball', value: 'softball' },
];

export const enrichmentActivities = [
  { label: 'Singing', key: 'singing', value: 'singing' },
  { label: 'Theater/Drama', key: 'theater_drama', value: 'theater_drama' },
  { label: 'Acting', key: 'acting', value: 'acting' },
  { label: 'Piano', key: 'piano', value: 'piano' },
  { label: 'Guitar', key: 'guitar', value: 'guitar' },
  { label: 'Drums', key: 'drums', value: 'drums' },
  { label: 'Flute', key: 'flute', value: 'flute' },
  { label: 'Clarinet', key: 'clarinet', value: 'clarinet' },
  { label: 'Saxaphone', key: 'saxaphone', value: 'saxaphone' },
  { label: 'Art', key: 'art', value: 'art' },
  { label: 'Dance', key: 'dance', value: 'dance' },
  { label: 'Yoga', key: 'yoga', value: 'yoga' },
  { label: 'Karate', key: 'karate', value: 'karate' },
  { label: 'Swimming', key: 'swimming', value: 'swimming' },
  { label: 'Gymnastics', key: 'gymnastics', value: 'gymnastics' },
  { label: 'Archery', key: 'archery', value: 'archery' },
  { label: 'Hockey', key: 'hockey', value: 'hockey' },
  { label: 'Surfing', key: 'surfing', value: 'surfing' },
  { label: 'Boxing', key: 'boxing', value: 'boxing' },
  { label: 'Cycling', key: 'cycling', value: 'cycling' },
  { label: 'Running', key: 'running', value: 'running' },
  { label: 'Skateboarding', key: 'skateboarding', value: 'skateboarding' },
  {
    label: 'Travel',
    key: 'travel',
    value: 'travel',
  },
  {
    label: 'Vacation',
    key: 'vacation',
    value: 'vacation',
  },
  {
    label: 'Camping',
    key: 'camping',
    value: 'camping',
  },
];
export const gradeSpanOptions = [
  { label: 'Early Learning (Ages 0-4)', key: 'early_learning', value: 'early_learning' },
  { label: 'Pre-K', key: 'pre_k', value: 'pre_k' },
  { label: 'K-5th', key: 'k_5th', value: 'k_5th' },
  { label: '6th-8th', key: '6th_8th', value: '6th_8th' },
  { label: '9th-12th', key: '9th_12th', value: '9th_12th' },
  { label: 'College Level', key: 'college_level_course', value: 'college_level_course' },
  { label: 'Adult', key: 'adult_course', value: 'adult_course' },
  { label: 'All Ages', key: 'all_ages', value: 'all_ages' },
];
export const teachingStyles = [
  { label: 'Montessori', key: 'montessori', value: 'montessori' },
  { label: 'Eclectic', key: 'ecle', value: 'ecle' },
  { label: 'Waldorf', key: 'waldorf', value: 'waldorf' },
  { label: 'Classical', key: 'classical', value: 'classical' },
  { label: 'Traditional', key: 'traditional', value: 'traditional' },
  { label: 'Unschooling', key: 'unschooling', value: 'unschooling' },
  { label: 'Charlotte Mason', key: 'charlotte-mason', value: 'charlotte-mason' },
  { label: 'Unit Studies', key: 'unit-studies', value: 'unit-studies' },
  {
    label: 'Special Needs Education (SLD,OHI,OT)',
    key: 'special-needs-education',
    value: 'special-needs-education',
  },
  { label: 'Other', key: 'other', value: 'other' },
];
export const backgroundOptions = [
  { label: 'Credentialed Teacher', key: 'credentialed_teacher', value: 'credentialed_teacher' },
  {
    label: 'College/University Degree (BA, BS,)',
    key: 'college_university_degree',
    value: 'college_university_degree',
  },
  { label: 'Signed PSA (Private School Affidavit)', key: 'signed_psa', value: 'signed_psa' },
  {
    label: 'Experience in Public School Teaching',
    key: 'experience_in_public_school_teaching',
    value: 'experience_in_public_school_teaching',
  },
  {
    label: 'Experience in Private School Teaching',
    key: 'experience_in_private_school_teaching',
    value: 'experience_in_private_school_teaching',
  },
  {
    label: 'Home Educator',
    key: 'home_educator',
    value: 'home_educator',
  },
  {
    label: 'MBA/Masters ',
    key: 'mba_masters',
    value: 'mba_masters',
  },
  {
    label: 'CPR/First Aid Certified',
    key: 'cpr_first_aid_certified',
    value: 'cpr_first_aid_certified',
  },
  { label: 'Sports Coach', key: 'sports_coach', value: 'sports_coach' },
];

export const programOffered = [
  {
    label: 'Therapy/Counseling Session',
    key: 'therapy_counseling_session',
    value: 'therapy_counseling_session',
    type: 'online',
  },
  {
    label: 'Home Education Workshop',
    key: 'home_education_workshop',
    value: 'home_education_workshop',
    type: 'online',
  },
  {
    label: 'Home Education Consultation',
    key: 'home_education_consultation',
    value: 'home_education_consultation',
    type: 'online',
  },
  { label: 'Tutoring', key: 'tutoring', value: 'tutoring', type: 'online' },

  {
    label: 'Enrichment Activities',
    key: 'enrichment_activities',
    value: 'enrichment_activities',
    type: 'online',
  },
  { label: 'Tutoring', key: 'tutoring', value: 'tutoring', type: 'in-person' },
  {
    label: 'Enrichment Activities',
    key: 'enrichment_activities',
    value: 'enrichment_activities',
    type: 'in-person',
  },
  {
    label: 'Therapy/Counseling Session',
    key: 'therapy_counseling_session',
    value: 'therapy_counseling_session',
    type: 'in-person',
  },
  {
    label: 'Home Education Workshop',
    key: 'home_education_workshop',
    value: 'home_education_workshop',
    type: 'in-person',
  },
  {
    label: 'Home Education Consultation',
    key: 'home_education_consultation',
    value: 'home_education_consultation',
    type: 'in-person',
  },
  { label: 'Sports Coaching', key: 'sports_coaching', value: 'sports_coaching', type: 'in-person' },
];
export const desiredProgram = [
  { label: 'Homeschool Pod', key: 'homeschool_pod', value: 'homeschool_pod' },
  {
    label: 'Homeschool Pod (trade)',
    key: 'homeschool_pod_for_trade',
    value: 'homeschool_pod_for_trade',
  },
  { label: 'Homeschool Co-Op', key: 'homeschool_co_op', value: 'homeschool_co_op' },
  {
    label: 'Enrichment/Learning Center',
    key: 'enrichment_learning_center',
    value: 'enrichment_learning_center',
  },
  {
    label: 'Resource Center',
    key: 'resource_enrichment_center',
    value: 'resource_enrichment_center',
  },
  { label: 'Charter School', key: 'charter_school', value: 'charter_school' },
  { label: 'Tutoring', key: 'tutoring', value: 'tutoring' },

  {
    label: 'Home Education Workshop (Online)',
    key: 'home_education_workshop',
    value: 'home_education_workshop',
  },
  {
    label: 'Home Education Consultation (Online)',
    key: 'home_education_consultation',
    value: 'home_education_consultation',
  },
  {
    label: 'Home Education Event',
    key: 'home_education_event',
    value: 'home_education_event',
  },
  // { label: 'Free Event', key: 'free_event', value: 'free_event' },
  // { label: 'Free Meet Up', key: 'free_meet_up', value: 'free_meet_up' },
];

// FOR VIDEO CALL SHOW/HIDE IN TRANSACTION PANEL
export const PAID_SERVICES = ['home_education_workshop', 'home_education_consultation'];

export const pricedServices = [
  'homeschool_pod',
  'homeschool_co_op',
  'enrichment_learning_center',
  'home_education_workshop',
  'home_education_consultation',
  'home_education_event',
];

export const userTypes = [
  { label: 'Program/Educator Listings', value: 'teacher', key: 'teacher' },
  { label: 'Parent Request Listings', value: 'parent', key: 'parent' },
  { label: 'Experience', value: 'experience', key: 'experience' },
  { label: 'Local Friends', value: 'meet_up', key: 'meet_up' },
  { label: 'Curriculum', value: 'curriculum', key: 'curriculum' },
];
export const userSignup = [
  { label: 'Educator', value: 'teacher', key: 'teacher' },
  { label: 'Parent', value: 'parent', key: 'parent' },
];
export const herosectionFilter = [
  { label: 'Local Educators', value: 'teacher', key: 'teacher' },
  { label: 'Local Friends', value: 'meet_up', key: 'meet_up' },
];

export const backgroundCheck = [
  { label: 'Show Only Verified Educators', key: 'true' },
  { label: 'Show All Educators', key: null },
];
export const communityMember = [
  { label: 'Show Community Leaders ', key: 'true' },
  { label: 'Show All Educators', key: null },
];
export const experienceFilter = [
  { label: 'Educators Offering Experiences', key: 'true' },
  { label: 'Show All Educators', key: null },
];
export const filters = [
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',
      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  // {
  //   id: 'price',
  //   label: 'Price',
  //   type: 'PriceFilter',
  //   group: 'primary',
  //   // Note: PriceFilter is fixed filter,
  //   // you can't change "queryParamNames: ['price'],"
  //   queryParamNames: ['price'],
  //   // Price filter configuration
  //   // Note: unlike most prices this is not handled in subunits
  //   config: {
  //     min: 0,
  //     max: 1000,
  //     step: 5,
  //   },
  // },
  // {
  //   id: 'keyword',
  //   label: 'Keyword',
  //   type: 'KeywordFilter',
  //   group: 'primary',
  //   // Note: KeywordFilter is fixed filter,
  //   // you can't change "queryParamNames: ['keywords'],"
  //   queryParamNames: ['keywords'],
  //   // NOTE: If you are ordering search results by distance
  //   // the keyword search can't be used at the same time.
  //   // You can turn on/off ordering by distance from config.js file.
  //   config: {},
  // },
  {
    id: 'backgroundCheck',
    label: 'Background Check',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['meta_verified'],
    config: {
      // Optional modes: 'has_any', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...backgroundCheck],
    },
  },
  {
    id: 'community_member',
    label: 'Community Leader',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['meta_community_member'],
    config: {
      // Optional modes: 'has_any', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      // searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...communityMember],
    },
  },
  // {
  //   id: 'experienceFilter',
  //   label: 'Experiences',
  //   type: 'SelectSingleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_offersExperiences'],
  //   config: {
  //     // Optional modes: 'has_any', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [...experienceFilter],
  //   },
  // },
  {
    id: 'category',
    label: 'Listing Type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Optional modes: 'has_any', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...userTypes],
    },
  },
  {
    id: 'teachingStyles',
    label: 'Teaching Style',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_teaching_style'],
    config: {
      // Optional modes: 'has_any', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...teachingStyles],
    },
  },
  {
    id: 'desiredTeachingStyle',
    label: 'Desired Teaching Style',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_teaching_style'],
    config: {
      // Optional modes: 'has_any', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...teachingStyles],
    },
  },
  {
    id: 'contactOptions',
    label: 'Program Offered (contact only)',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_contact_data_filter'],
    config: {
      // Optional modes: 'has_any', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...contactOptions],
    },
  },
  {
    id: 'oneInOneInPerson',
    label: 'One one One In Person',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_one_on_one_in_person'],
    config: {
      // Optional modes: 'has_any', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...oneInOneInPerson],
    },
  },

  {
    id: 'experienceCategory',
    label: 'Educator Experiences',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_experienceCategory'],
    config: {
      // Optional modes: 'has_any', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...experienceCategory],
    },
  },

  {
    id: 'oneInOneOnline',
    label: 'One on One Online',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_one_on_one_online'],
    config: {
      // Optional modes: 'has_any', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...oneInOneOnline],
    },
  },
  // {
  //   id: 'background',
  //   label: 'Background',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_experience'],
  //   config: {
  //     // Optional modes: 'has_any', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_any',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [...backgroundOptions],
  //   },
  // },
  {
    id: 'gradeSpan',
    label: 'Grade Span',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_grade_span'],
    config: {
      // Optional modes: 'has_any', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...gradeSpanOptions],
    },
  },
  {
    id: 'tutoringOptions',
    label: 'Tutoring Options',
    type: 'SelectMultipleFilter',
    group: 'Secondary',
    queryParamNames: ['pub_tutoring_options'],
    config: {
      // Optional modes: 'has_any', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...tutoringOptions],
    },
  },
  {
    id: 'desiredProgram',
    label: 'Desired Program',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_desired_program'],
    config: {
      // Optional modes: 'has_any', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...desiredProgram],
    },
  },
  {
    id: 'enrichmentActivities',
    label: 'Enrichment Activities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_enrichment_activities'],
    config: {
      // Optional modes: 'has_any', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...enrichmentActivities],
    },
  },
  {
    id: 'sportsCoaching',
    label: 'Sports Coaching',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_sports_coaching'],
    config: {
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...sportsCoaching],
    },
  },
  //Local friends
  {
    id: 'family_interest',
    label: 'Active Family Interests',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_family_interest'],
    config: {
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...familyInterestOptions],
    },
  },
  {
    id: 'favoriteFamilyOutingsOptions',
    label: 'Favorite Family Outings',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_favorite_family_outings'],
    config: {
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...favoriteFamilyOutingsOptions],
    },
  },
  {
    id: 'indoreFamilyInterestOptions',
    label: 'Indoor Family Interests',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_indore_family_interest'],
    config: {
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...indoreFamilyInterestOptions],
    },
  },
  ///Experiences fillter
  {
    id: 'experienceTypes',
    label: 'Experience Type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_experienceType'],
    config: {
      options: [...experienceTypes],
    },
  },
  {
    id: 'experienceCategoryFilter',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_experienceCategory'],
    config: {
      options: [...experienceCategory],
    },
  },

  {
    id: 'priceType',
    label: 'Price Type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_priceType'],
    config: {
      options: [...experienceOptions],
    },
  },
  ///Curriculum filter
  {
    id: 'curriculum_grade_span',
    label: 'Grade Span',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_curriculum_grade_span'],
    config: {
      options: [...curriculumGradeSpan],
    },
  },
  {
    id: 'curriculum_style',
    label: 'Curriculum or Materials Style',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_curriculum_style'],
    config: {
      options: [...curriculumStyle],
    },
  },
  {
    id: 'curriculum_type',
    label: 'Curriculum or Materials Type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_curriculum_type'],
    config: {
      options: [...curriculumType],
    },
  },
  {
    id: 'curriculum_resource_type',
    label: 'Resource or Materials Type',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_curriculum_resource_type'],
    config: {
      searchMode: 'has_any',
      options: [...curriculumResourceType],
    },
  },
  {
    id: 'curriculum_file_format',
    label: 'File or Materials Format',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_curriculum_file_format'],
    config: {
      options: [...curriculumFileFormat],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },
    // { key: 'createdAt', label: 'Newest' },
    // { key: '-createdAt', label: 'Oldest' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const bannerImages = [
  { Image: cloudsOne, id: 1 },
  { Image: cloudsTwo, id: 2 },
  { Image: natureBgOne, id: 3 },
  { Image: natureBgTwo, id: 4 },
  { Image: natureBgThree, id: 5 },
  { Image: natureBgFour, id: 6 },
  { Image: oceanBgOne, id: 7 },
  { Image: oceanBgTwo, id: 8 },
  { Image: oceanBgThree, id: 9 },
  { Image: oceanBgFour, id: 10 },
  { Image: oceanBgFive, id: 11 },
  { Image: oceanBgSix, id: 12 },
];

export const sizes = [
  { label: 'XS', key: 'xs', value: 'xs' },
  { label: 'S', key: 's', value: 's' },
  { label: 'M', key: 'm', value: 'm' },
  { label: 'L', key: 'l', value: 'l' },
  { label: 'XL', key: 'xl', value: 'xl' },
  { label: 'XXL', key: 'xxl', value: 'xxl' },
];

export const colors = [
  { label: 'Red', value: 'red', key: 'red' },
  { label: 'Blue', value: 'blue', key: 'blue' },
  { label: 'Green', value: 'green', key: 'green' },
  { label: 'Orange', value: 'orange', key: 'orange' },
  { label: 'White', value: 'white', key: 'white' },
  { label: 'Black', value: 'black', key: 'black' },
  { label: 'Yellow', value: 'yellow', key: 'yellow' },
  { label: 'Purple', value: 'purple', key: 'purple' },
  { label: 'Silver', value: 'silver', key: 'silver' },
  { label: 'Brown', value: 'brown', key: 'brown' },
  { label: 'Gray', value: 'gray', key: 'gray' },
  { label: 'Pink', value: 'pink', key: 'pink' },
  { label: 'Olive', value: 'olive', key: 'olive' },
  { label: 'Maroon', value: 'maroon', key: 'maroon' },
  { label: 'Violet', value: 'violet', key: 'violet' },
  { label: 'Charcoal', value: 'charcoal', key: 'charcoal' },
  { label: 'Magenta', value: 'magenta', key: 'magenta' },
  { label: 'Bronze', value: 'bronze', key: 'bronze' },
  { label: 'Cream', value: 'cream', key: 'cream' },
  { label: 'Gold', value: 'gold', key: 'gold' },
  { label: 'Tan', value: 'tan', key: 'tan' },
  { label: 'Teal', value: 'teal', key: 'teal' },
  { label: 'Mustard', value: 'mustard', key: 'mustard' },
  { label: 'Navy Blue', value: 'navy_blue', key: 'navy_blue' },
  { label: 'Coral', value: 'coral', key: 'coral' },
  { label: 'Burgundy', value: 'burgundy', key: 'burgundy' },
  { label: 'Lavender', value: 'lavender', key: 'lavender' },
  { label: 'Mauve', value: 'mauve', key: 'mauve' },
  { label: 'Peach', value: 'peach', key: 'peach' },
  { label: 'Rust', value: 'rust', key: 'rust' },
  { label: 'Indigo', value: 'indigo', key: 'indigo' },
  { label: 'Ruby', value: 'ruby', key: 'ruby' },
  { label: 'Clay', value: 'clay', key: 'clay' },
  { label: 'Cyan', value: 'cyan', key: 'cyan' },
  { label: 'Azure', value: 'azure', key: 'azure' },
  { label: 'Beige', value: 'beige', key: 'beige' },
  { label: 'Off White', value: 'off_white', key: 'off_white' },
  { label: 'Turquoise', value: 'turquoise', key: 'turquoise' },
  { label: 'Amber', value: 'amber', key: 'amber' },
  { label: 'Mint', value: 'mint', key: 'mint' },
];
