import React from 'react';
import { NamedLink } from '../../components';
import css from './TopBarBanner.module.css';
// import covidIcon from '../SectionCovid/covidIcon.svg';

function TopBarBanner() {
  return (
    <div className={css.container}>
      {/* <img src={covidIcon} /> */}
      Note: This website is in the early stages and you may experience some bugs and technical
      issues. If you would like to report a bug &nbsp;
      <NamedLink name="ContactUsPage">CLICK HERE</NamedLink>
      {/* <button className={css.modalButton} onClick={() => setShow(true)}>
        Learn More
      </button> */}
    </div>
  );
}

export default TopBarBanner;
