import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckboxGroup,
  RequiredHeadline,
} from '../../components';
import config from '../../config';
import css from './EditListingPoliciesForm.module.css';
import arrayMutators from 'final-form-arrays';
import { requiredFieldArrayCheckbox } from '../../util/validators';
export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        one_on_one_in_person,
        publicData,
      } = formRenderProps;
      const { one_on_one_online } = publicData;
      const rulesLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesLabel',
      });
      const rulesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const formatMessage = id => intl.formatMessage({ id: `EditListingPoliciesForm.${id}` });
      const validateTutoringOptions =
        one_on_one_in_person?.includes('tutoring_in_person') ||
        one_on_one_online?.includes('tutoring_online');
      const validateEnrichmentActivities =
        one_on_one_in_person?.includes('enrichment_activities_in_person') ||
        one_on_one_online?.includes('enrichment_activities_online');
      // const validateEnrichmentActivities =
      const validateSportsCoaching = one_on_one_in_person?.includes('sports_coaching_in_person');
      const enrichmentLabel = validateEnrichmentActivities ? (
        <RequiredHeadline
          label={formatMessage('enrichmentLabel')}
          message={intl.formatMessage({
            id: 'EditListingPoliciesForm.enrichmentRequiredLabelMessage',
          })}
        />
      ) : (
        formatMessage('enrichmentLabel')
      );
      // formatMessage('enrichmentLabel');
      const enrichmentRequiredMessage = formatMessage('enrichmentRequiredMessage');

      const sportsCoachingLabel = validateSportsCoaching ? (
        <RequiredHeadline
          label={formatMessage('sportsCoachingLabel')}
          message={intl.formatMessage({
            id: 'EditListingPoliciesForm.sportsCoachingRequiredLabelMessage',
          })}
        />
      ) : (
        formatMessage('sportsCoachingLabel')
      );
      const sportsCoachingRequiredMessage = formatMessage('sportsCoachingRequiredMessage');
      const tutoringLabelMessage = validateTutoringOptions ? (
        <RequiredHeadline
          label={intl.formatMessage({
            id: 'EditListingPoliciesForm.tutoringLabel',
          })}
          message={intl.formatMessage({
            id: 'EditListingPoliciesForm.tutoringRequiredLabelMessage',
          })}
        />
      ) : (
        intl.formatMessage({
          id: 'EditListingPoliciesForm.tutoringLabel',
        })
      );
      const tutoringRequiredMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.tutoringRequired',
      });
      const languagesLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.languagesLabel',
      });
      const languagesRequiredMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.languagesRequired',
      });
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <FieldCheckboxGroup
            className={css.title}
            id="enrichment_activities"
            name="enrichment_activities"
            label={enrichmentLabel}
            options={config.custom.enrichmentActivities}
            twoColumns={config.custom.enrichmentActivities.length > 3}
            validate={
              validateEnrichmentActivities && requiredFieldArrayCheckbox(enrichmentRequiredMessage)
            }
          />
          <FieldCheckboxGroup
            className={css.title}
            id="sports_coaching"
            name="sports_coaching"
            label={sportsCoachingLabel}
            options={config.custom.sportsCoaching}
            twoColumns={config.custom.sportsCoaching.length > 3}
            validate={
              validateSportsCoaching && requiredFieldArrayCheckbox(sportsCoachingRequiredMessage)
            }
          />
          <FieldCheckboxGroup
            className={css.title}
            id="tutoring_options"
            name="tutoring_options"
            label={tutoringLabelMessage}
            options={config.custom.tutoringOptions}
            twoColumns={config.custom.tutoringOptions.length > 3}
            validate={
              validateTutoringOptions && requiredFieldArrayCheckbox(tutoringRequiredMessage)
            }
          />
          {/* <FieldCheckboxGroup
            className={css.title}
            id="foreign_languages"
            name="foreign_languages"
            label={languagesLabelMessage}
            options={config.custom.foreignLanguageOptions}
            twoColumns={config.custom.foreignLanguageOptions.length > 3}
            // validate={requiredFieldArrayCheckbox(languagesRequiredMessage)}
          /> */}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
