import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.module.css';
import config from '../../config';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;
  const { publicData } = listing?.attributes;
  const { bannerImage = 1, bannerImageId, logo_image } = publicData;
  const imagesWithOutLogo = listing?.images?.filter(k => k.id.uuid != logo_image);
  const bImage = listing?.images?.find(k => k.id.uuid === bannerImageId);
  const bannerImageUrl = config.custom.bannerImages?.find(image => image.id === bannerImage)?.Image;
  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
          {actionBar}
          {/* <div
            className={`${css.memberImage} text-white absolute z-[1] font-semibold top-0 tracking-wider right-0 text-xs py-2 px-3 `}
          ></div> */}
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={bImage || firstImage}
            bannerImageUrl={bImage ? null : bannerImageUrl}
            variants={[
              'scaled-xlarge',
              // 'landscape-crop2x',
              // 'landscape-crop4x',
              // 'landscape-crop6x',
            ]}
          />
          {viewPhotosButton}
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={imagesWithOutLogo} />
      </Modal>
    </div>
  );
};

export default SectionImages;
