import React, { useState, useCallback } from 'react';
import { Modal, Button, AddImages, ValidationError } from '../../components';
import getCroppedImg from './CropImage';
import { Field } from 'react-final-form';
import { nonEmptyArray, composeValidators } from '../../util/validators';
import { FormattedMessage } from '../../util/reactIntl';
import Cropper from 'react-easy-crop';
import css from './PhotoUploadWithCrop.module.css';
const ACCEPT_IMAGES = 'image/*';
const PhotoUploadWithCrop = props => {
  const {
    form,
    images,
    imageUploadRequested,
    intl,
    onImageUploadHandler,
    onRemoveImage,
    onManageDisableScrolling,
    aspectRatio,
    isLogo,
    isBanner,
    isMobileBanner,
    id,
    name,
  } = props;

  const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false);
  const [defaultImage, setDefaultImage] = useState(null);
  const [croppedPixels, setCroppedPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const imageValue = isLogo ? 'logoImage' : 'bannerImage';

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedPixels(croppedAreaPixels);
  }, []);
  const toDataURL = url =>
    fetch(url)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(defaultImage, croppedPixels);
    return new Promise((resolve, reject) => {
      toDataURL(croppedImage).then(dataUrl => {
        var fileData = dataURLtoFile(dataUrl, 'imageName.jpg');
        return resolve(fileData);
      });
    });
  };

  const chooseImageText = (
    <span className={css.chooseImageText}>
      <span className={css.chooseImage}>
        <FormattedMessage id="EditListingPhotosForm.chooseImage" />
      </span>
      <span className={css.imageTypes}>
        <FormattedMessage id="EditListingPhotosForm.imageTypes" />
      </span>
    </span>
  );
  return (
    <div>
      <AddImages
        key={id}
        className={css.imagesField}
        images={images}
        thumbnailClassName={isBanner ? css.bannerThumbnail : css.thumbnail}
        savedImageAltText={intl.formatMessage({
          id: 'EditListingPhotosForm.savedImageAltText',
        })}
        onRemoveImage={onRemoveImage}
        isBanner={isBanner}
        isMobileBanner={isMobileBanner}
      >
        {images?.length < 1 ? (
          <Field
            id={id}
            name={id}
            key={id}
            accept={ACCEPT_IMAGES}
            form={null}
            label={chooseImageText}
            type="file"
            disabled={imageUploadRequested}
          >
            {fieldprops => {
              const { accept, input, label, disabled: fieldDisabled } = fieldprops;
              const { name, type } = input;
              const onChange = e => {
                const file = e.target.files[0];
                setDefaultImage(URL.createObjectURL(file));
                setIsSelectionModalOpen(true);
              };
              const inputProps = { accept, id: name, name, onChange, type };
              return (
                <div className={isBanner ? css.addImageWrapperBanner : css.addImageWrapper}>
                  <div className={isBanner ? css.aspectRatioWrapperBanner : css.aspectRatioWrapper}>
                    {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
                    <label htmlFor={name} className={css.addImage}>
                      {label}
                    </label>
                  </div>
                </div>
              );
            }}
          </Field>
        ) : null}
        <Field
          component={props => {
            const { input, meta } = props;
            return (
              <div className={css.imageRequiredWrapper}>
                <input {...input} />
                <ValidationError fieldMeta={meta} />
              </div>
            );
          }}
          id={id}
          name="images"
          type="hidden"
          validate={composeValidators(nonEmptyArray('images'))}
        />
      </AddImages>

      <Modal
        id="EditListingPhotosForm.selectImageAreaA"
        containerClassName={css.selectImageAreaModalContent}
        isOpen={isSelectionModalOpen}
        onClose={() => setIsSelectionModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.addImageWrapperBanner}>
          <div className={css.aspectRatioWrapper}>
            <div className={css.App}>
              <div className={css.cropcontainer}>
                <Cropper
                  image={defaultImage}
                  crop={crop}
                  zoom={zoom}
                  // aspect={4 / 4}
                  aspect={aspectRatio}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div className={css.controls}>
                <input
                  type="range"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={e => {
                    setZoom(e.target.value);
                  }}
                  className={css.zoomrange}
                />
              </div>
            </div>
          </div>
        </div>
        <Button
          className={css.submitImageButton}
          type="button"
          onClick={async e => {
            e.preventDefault();
            try {
              const res = await showCroppedImage();
              await setIsSelectionModalOpen(false);
              form.change(`addImage`, res);
              form.blur(`addImage`);
              await onImageUploadHandler(res, isLogo, isBanner);
            } catch (e) {
              console.log(e);
            }
          }}
        >
          Save
        </Button>
      </Modal>
    </div>
  );
};

export default PhotoUploadWithCrop;
