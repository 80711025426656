import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import Decimal from 'decimal.js';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = (lineItems, participants, expType) => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(expType ? item.lineTotal.amount / participants : item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : config.currency;
  return new Money(amount, currency);
};
//without participants
const lineItemsTotalWithOutParticipants = lineItems => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : config.currency;
  return new Money(amount, currency);
};
/**
 * Checks if line item represents commission
 */
const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
};

/**
 * Returns non-commission, non-reversal line items
 */
const nonCommissionNonReversalLineItems = (transaction, userRole) => {
  const lineItem =
    userRole === 'provider'
      ? transaction.attributes.lineItems
          .filter(item => !isCommission(item) && !item.reversal)
          ?.filter(item => item.code !== 'line-item/shipping-fee')
      : transaction.attributes.lineItems.filter(item => !isCommission(item) && !item.reversal);
  return lineItem;
};

/**
 * Checks if a transaction has a commission line-item for
 * a given user role.
 */
const txHasCommission = (transaction, userRole) => {
  let commissionLineItem = null;

  if (userRole === 'customer') {
    commissionLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_CUSTOMER_COMMISSION
    );
  } else if (userRole === 'provider') {
    commissionLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_PROVIDER_COMMISSION
    );
  }
  return !!commissionLineItem;
};

const LineItemSubTotalMaybe = props => {
  const {
    transaction,
    unitType,
    userRole,
    intl,
    showDate,
    participants,
    optionData,
    label,
    color,
    size,
  } = props;
  const refund = transaction.attributes.lineItems.find(
    item => item.code === unitType && item.reversal
  );

  const otherColor = transaction?.attributes.protectedData?.color;
  const otherSize = transaction?.attributes.protectedData?.size;
  // Show unit purchase line total (unit price * quantity) as a subtotal.
  // PLEASE NOTE that this assumes that the transaction doesn't have other
  // line item types than the defined unit type (e.g. week, month, year).
  const showSubTotal = txHasCommission(transaction, userRole) || refund;

  // all non-reversal, non-commission line items
  const subTotalLineItems = nonCommissionNonReversalLineItems(transaction, userRole);

  // line totals of those line items combined
  let part = participants ? parseInt(participants) : subTotalLineItems?.[0]?.participants;
  let expType = optionData?.experienceType === 'in-person' || '';
  const subTotal = part
    ? lineItemsTotal(subTotalLineItems, part, expType)
    : lineItemsTotalWithOutParticipants(subTotalLineItems);
  const formattedSubTotal = subTotalLineItems.length > 0 ? formatMoney(intl, subTotal) : null;
  let check = optionData?.experienceType === 'in-person' && part ? 'x' + ' ' + parseInt(part) : '';

  return formattedSubTotal && showSubTotal ? (
    <>
      <hr className={css.totalDivider} />
      <div className={css.subTotalLineItem}>
        <span className={css.itemLabel}>
          {label ? label : showDate ? <FormattedMessage id="BookingBreakdown.subTotal" /> : 'Price'}
        </span>
        <span className={css.itemValue}>
          {formattedSubTotal} {check}
        </span>
      </div>
      {size || otherSize ? (
        <div className={css.subTotalLineItem}>
          <span className={css.itemLabel}>Size:</span>
          <span className={css.itemValue}>{size || otherSize}</span>
        </div>
      ) : (
        ''
      )}
      {color || otherColor ? (
        <div className={css.subTotalLineItem}>
          <span className={css.itemLabel}>Color:</span>
          <span>{color || otherColor}</span>
        </div>
      ) : (
        ''
      )}
    </>
  ) : null;
};

LineItemSubTotalMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  userRole: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSubTotalMaybe;
