import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import image from './image.jpeg';
import img from './img.jpeg';
import css from './SectionInformation.module.css';
const SectionInformation = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionInformation.titleLineOne" />
      </div>
      <div className={css.stepTitle}>
        <FormattedMessage id="SectionInformation.description" />
      </div>
      <div className={css.mainContainer}>
        <div className={css.imageContainerLeft}>
          <img src={img} className={css.image} />
        </div>
        <div className={css.imageContainerRight}>
          <img src={image} className={css.image} />
        </div>
      </div>
    </div>
  );
};

SectionInformation.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionInformation.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionInformation;
