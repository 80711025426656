import React from 'react';
import { IconSpinner } from '../../components';
import FieldDiscountInput from './FieldDiscountInput';
import css from './BookingTimeForm.module.css';

function DiscountInput({ value, onValidateDiscount, loading, success, failure, onChange }) {
  const applyButton = (
    <button
      type="button"
      onClick={onValidateDiscount}
      disabled={!value}
      className="border-none bg-[#7bb9ca] min-w-[80px] disabled:cursor-not-allowed disabled:bg-gray-400 hover:cursor-pointer hover:bg-[#546e7a] transition duration-150 ease-out -m-[2px] px-6 text-white font-semibold"
    >
      {loading ? <IconSpinner className={css.rootClassName} /> : 'Apply'}
    </button>
  );
  return (
    <FieldDiscountInput
      id="discount"
      name="discount"
      label="Discount"
      success={success}
      failure={failure}
      placeholder="e.g., DISCOUNT10"
      applyButton={applyButton}
      onChange={onChange}
      value={value}
    />
  );
}

export default DiscountInput;
