import pick from 'lodash/pick';
import config from '../../config';
import {
  confirmCurriculumPayment,
  createShippingOrderWithShippo,
  fetchShippingMethodsWithRatesFromShippo,
  initiatePrivileged,
  initiateShippoPrivileged,
  transitionPrivileged,
} from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  isPrivileged,
  TRANSITION_REQUEST_PAYMENT_FOR_PHYSICAL_CURRICULUM,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import moment from 'moment';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const FETCH_SHIPPING_METHODS_REQUEST = 'app/CheckoutPage/FETCH_SHIPPING_METHODS_REQUEST';
export const FETCH_SHIPPING_METHODS_SUCCESS = 'app/CheckoutPage/FETCH_SHIPPING_METHODS_SUCCESS';
export const FETCH_SHIPPING_METHODS_ERROR = 'app/CheckoutPage/FETCH_SHIPPING_METHODS_ERROR';

export const CREATE_SHIPPING_ORDER_REQUEST = 'app/CheckoutPage/CREATE_SHIPPING_ORDER_REQUEST';
export const CREATE_SHIPPING_ORDER_SUCCESS = 'app/CheckoutPage/CREATE_SHIPPING_ORDER_SUCCESS';
export const CREATE_SHIPPING_ORDER_ERROR = 'app/CheckoutPage/CREATE_SHIPPING_ORDER_ERROR';

export const RESET_SHIPPING_DETAILS_CHANGED = 'app/CheckoutPage/RESET_SHIPPING_DETAILS_CHANGED';

export const UPDATING_SHIPPING_PRICE = 'app/CheckoutPage/UPDATING_SHIPPING_PRICE';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  fetchShippingMethodsInProgress: false,
  shippingMethods: [],
  fetchShippingMethodsError: null,
  updatingShippingPriceInProgress: false,
  createShippingOrderInProgress: false,
  createShippingOrderError: null,
  noShippingProvider: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };
    case FETCH_SHIPPING_METHODS_REQUEST:
      return {
        ...state,
        fetchShippingMethodsInProgress: true,
        fetchShippingMethodsError: null,
        shippingMethods: [],
        noShippingProvider: null,
      };
    case FETCH_SHIPPING_METHODS_SUCCESS:
      return {
        ...state,
        fetchShippingMethodsInProgress: false,
        shippingMethods: payload.rates,
        noShippingProvider: payload.no_provider,
      };
    case FETCH_SHIPPING_METHODS_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        fetchShippingMethodsInProgress: false,
        fetchShippingMethodsError: payload,
        noShippingProvider: null,
      };

    case RESET_SHIPPING_DETAILS_CHANGED:
      return {
        ...state,
        fetchShippingMethodsInProgress: false,
        fetchShippingMethodsError: null,
        shippingMethods: [],
        noShippingProvider: null,
      };

    case UPDATING_SHIPPING_PRICE:
      return { ...state, updatingShippingPriceInProgress: payload };

    case CREATE_SHIPPING_ORDER_REQUEST:
      return { ...state, createShippingOrderInProgress: true, createShippingOrderError: null };

    case CREATE_SHIPPING_ORDER_SUCCESS:
      return { ...state, createShippingOrderInProgress: false };

    case CREATE_SHIPPING_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, createShippingOrderInProgress: false, createShippingOrderError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const fetchShippingMethodsRequest = () => ({ type: FETCH_SHIPPING_METHODS_REQUEST });
export const fetchShippingMethodsSuccess = payload => ({
  type: FETCH_SHIPPING_METHODS_SUCCESS,
  payload,
});
export const fetchShippingMethodsError = e => ({
  type: FETCH_SHIPPING_METHODS_ERROR,
  error: true,
  payload: e,
});

export const createShippingOrderRequest = () => ({ type: CREATE_SHIPPING_ORDER_REQUEST });
export const createShippingOrderSuccess = () => ({ type: CREATE_SHIPPING_ORDER_SUCCESS });
export const createShippingOrderError = e => ({
  type: CREATE_SHIPPING_ORDER_ERROR,
  error: true,
  payload: e,
});

// export const setShippingDetailsChanged = () => ({ type: SHIPPING_DETAILS_CHANGED });
export const resetShippingDetailsChanged = () => ({ type: RESET_SHIPPING_DETAILS_CHANGED });
export const updatingShippingPrice = payload => ({ type: UPDATING_SHIPPING_PRICE, payload });
/* ================ Thunks ================ */

export const speculateTransactionWithShippo = (orderParams, transactionId = null) => async (
  dispatch,
  getState
) => {
  dispatch(updatingShippingPrice(true));
  const transition = TRANSITION_REQUEST_PAYMENT_FOR_PHYSICAL_CURRICULUM;

  const { deliveryMethod, shipment_id, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  const shipmentIdMaybe = shipment_id ? { shipment_id } : {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
    ...shipmentIdMaybe,
  };

  const transitionParams = {
    ...orderParams,
    orderData,
    cardToken: 'CheckoutPage_speculative_card_token',
    protectedData: {
      service: orderParams.service,
      event: orderParams.event,
    },
    metadata: {
      ...(orderParams.coupon
        ? { coupon: orderParams.coupon, category: 'curriculum' }
        : { category: 'curriculum' }),
    },
  };

  const bodyParams = {
    processAlias: config.curriculumShippingProcessAlias,
    transition,
    params: transitionParams,
  };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
    dispatch(updatingShippingPrice(false));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    dispatch(updatingShippingPrice(false));
    return dispatch(speculateTransactionError(storableError(e)));
  };

  return initiateShippoPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
    .then(handleSuccess)
    .catch(handleError);
};

export const fetchAllShippingMethods = shippingAddress => (dispatch, getState, sdk) => {
  dispatch(fetchShippingMethodsRequest());
  // dispatch(resetShippingDetailsChanged());
  fetchShippingMethodsWithRatesFromShippo(shippingAddress)
    .then(res => {
      dispatch(fetchShippingMethodsSuccess(res));
    })
    .catch(err => {
      const error = err?.response?.data?.message ?? err?.message ?? 'Unknown error';
      dispatch(fetchShippingMethodsError(error));
    });
};

export const createShippingOrder = orderParams => (dispatch, getState, sdk) => {
  dispatch(createShippingOrderRequest());
  return createShippingOrderWithShippo({ tx: orderParams.id.uuid })
    .then(response => {
      const order = orderParams;
      dispatch(createShippingOrderSuccess(order.id));
      return order;
    })
    .catch(err => {
      const error = err?.response?.data?.message ?? err?.message ?? 'Unknown error';
      dispatch(createShippingOrderError(error));
    });
};

export const initiateOrderWithShipping = (orderParams, transactionId) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(initiateOrderRequest());

  const shipmentId = orderParams?.protectedData?.shippingDetails?.shipment_id;

  if (!shipmentId) return;

  const shipmentIdMaybe = shipmentId ? { shipment_id: shipmentId } : {};

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const transition = TRANSITION_REQUEST_PAYMENT_FOR_PHYSICAL_CURRICULUM;

  const { deliveryMethod, quantity, color, size, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
    color,
    size,
    ...shipmentIdMaybe,
  };

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };

  const bodyParams = {
    processAlias: config.curriculumShippingProcessAlias,
    transition,
    params: transitionParams,
  };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };

  return initiateShippoPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
    .then(handleSucces)
    .catch(handleError);
};

export const initiateFreeOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);
  const isExperience = !!orderParams?.experience;

  let bookingData;
  let processAlias;
  let params;
  if (isExperience) {
    bookingData = {
      startDate: orderParams.bookingStart,
      endDate: orderParams.bookingEnd,
      participants: orderParams.participants,
      coupon: orderParams.coupon,
    };

    processAlias = config.bookingProcessAlias;
    params = {
      ...orderParams,
      protectedData: {
        participants: orderParams.participants,
        selectedId: orderParams.selectedId,
      },
      metadata: {
        ...(orderParams.coupon ? { coupon: orderParams.coupon } : {}),
        selectedId: orderParams.selectedId,
      },
    };
  } else {
    bookingData = {
      startDate: orderParams.bookingStart,
      endDate: orderParams.bookingEnd,
      service: orderParams.service,
      event: orderParams.event,
      participants: orderParams.participants,
      optionData: orderParams.optionData,
    };

    params = {
      ...orderParams,
      protectedData: {
        service: orderParams.service,
        event: orderParams.event,
      },
      metadata: {
        ...(orderParams?.serviceData ? { serviceData: orderParams.serviceData } : {}),
      },
    };
  }

  // ! CREATE NEW PROCESS FOR FREE BOOKING
  // const processAlias = config.freeBookingProcessAlias;

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params,
      }
    : {
        processAlias,
        transition,
        params,
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      bookingStart: orderParams.bookingStart,
      bookingEnd: orderParams.bookingEnd,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const confirmFreePayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.id,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const speculateFreeTransaction = (orderParams, transactionId) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
    service: orderParams.service,
    participants: orderParams.participants,
    optionData: orderParams.optionData,
  };

  const params = {
    ...orderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
    protectedData: {
      service: orderParams.service,
      event: orderParams.event,
    },
    metadata: {
      ...(orderParams?.serviceData ? { serviceData: orderParams.serviceData } : {}),
    },
  };

  // ! CREATE NEW PROCESS FOR FREE BOOKING
  // const processAlias = config.freeBookingProcessAlias;
  const processAlias = config.bookingProcessAlias;

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params,
      }
    : {
        processAlias,
        transition,
        params,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    const { listingId, bookingStart, bookingEnd } = params;
    log.error(e, 'speculate-transaction-failed', {
      listingId: listingId.uuid,
      bookingStart,
      bookingEnd,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());
  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const isExperience = !!orderParams?.experience;
  const isCurriculum = !!orderParams?.curriculum;
  const isPhysicalProduct = orderParams?.isPhysicalProduct;
  let bookingData;
  let processAlias;
  let params;
  if (isExperience) {
    bookingData = {
      startDate: orderParams.bookingStart,
      endDate: orderParams.bookingEnd,
      participants: orderParams.participants,
      coupon: orderParams.coupon,
    };

    processAlias = config.bookingProcessAlias;

    params = {
      ...orderParams,
      protectedData: {
        participants: orderParams.participants,
        selectedId: orderParams.selectedId,
      },
      metadata: {
        ...(orderParams.coupon ? { coupon: orderParams.coupon } : {}),
        selectedId: orderParams.selectedId,
      },
    };
  } else if (isCurriculum) {
    bookingData = {
      startDate: orderParams.bookingStart,
      endDate: orderParams.bookingEnd,
      coupon: orderParams.coupon,
    };

    processAlias = isPhysicalProduct
      ? config.curriculumShippingProcessAlias
      : config.curriculumDefaultProcessAlias;

    params = {
      ...orderParams,
      protectedData: {},
      metadata: {
        ...(orderParams.coupon
          ? {
              coupon: orderParams.coupon,
              category: 'curriculum',
              material_link: orderParams.materialUrlLink,
            }
          : { category: 'curriculum', material_link: orderParams.materialUrlLink }),
      },
    };
  } else {
    bookingData = {
      startDate: orderParams.bookingStart,
      endDate: orderParams.bookingEnd,
      service: orderParams.service,
      event: orderParams.event,
      participants: orderParams.participants,
      optionData: orderParams.optionData,
      coupon: orderParams.coupon,
    };
    processAlias = orderParams.event ? config.eventProcessAlias : config.bookingProcessAlias;

    params = {
      ...orderParams,
      protectedData: {
        service: orderParams.service,
        event: orderParams.event,
        participants: orderParams.participants,
      },
      metadata: {
        ...(orderParams?.serviceData ? { serviceData: orderParams.serviceData } : {}),
        ...(orderParams.coupon ? { coupon: orderParams.coupon } : {}),
      },
    };
  }

  // return console.log(params  );

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: params,
      }
    : {
        // processAlias: config.bookingProcessAlias,
        processAlias: processAlias,
        transition,
        params: params,
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      bookingStart: orderParams.bookingStart,
      bookingEnd: orderParams.bookingEnd,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());
  // console.log('hit confirm payment');
  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};
export const confirmCurriculum = (orderParams, id) => (dispatch, getState, sdk) => {
  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };
  dispatch(confirmPaymentRequest());
  return confirmCurriculumPayment({
    bodyParams,
    id,
  })
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};
export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isCurriculum = orderParams?.isCurriculum;
  const isPhysicalProduct = orderParams?.isPhysicalProduct;

  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);
  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
    service: orderParams.service,
    event: orderParams.event,
    participants: orderParams.participants,
    optionData: orderParams.optionData,
    coupon: orderParams.coupon,
  };
  let processAlias = orderParams.event ? config.eventProcessAlias : config.bookingProcessAlias;
  let params;
  if (isCurriculum) {
    processAlias = isPhysicalProduct
      ? config.curriculumShippingProcessAlias
      : config.curriculumDefaultProcessAlias;
    params = {
      ...orderParams,
      cardToken: 'CheckoutPage_speculative_card_token',
      protectedData: {
        service: orderParams.service,
        event: orderParams.event,
      },
      metadata: {
        ...(orderParams.coupon
          ? {
              coupon: orderParams.coupon,
              category: 'curriculum',
              material_link: orderParams.materialUrlLink,
            }
          : { category: 'curriculum', material_link: orderParams.materialUrlLink }),
      },
    };
  } else {
    params = {
      ...orderParams,
      cardToken: 'CheckoutPage_speculative_card_token',
      protectedData: {
        service: orderParams.service,
        event: orderParams.event,
      },
      metadata: {
        ...(orderParams?.serviceData ? { serviceData: orderParams.serviceData } : {}),
        ...(orderParams.coupon ? { coupon: orderParams.coupon } : {}),
      },
    };
  }

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params,
      }
    : {
        // processAlias: config.bookingProcessAlias,
        processAlias: processAlias,
        transition,
        params,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    const { listingId, bookingStart, bookingEnd } = params;
    log.error(e, 'speculate-transaction-failed', {
      listingId: listingId.uuid,
      bookingStart,
      bookingEnd,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};
