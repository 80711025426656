import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import EditListingCurriculumDetailsForm from './EditListingCurriculumDetailsForm';
import config from '../../config';
import css from './EditListingCurriculumDetailPanel.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
const EditListingCurriculumDetailPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;
  const [initialProps, setInitialProps] = useState({});

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData, privateData, metadata, price } = currentListing.attributes;
  // console.log({ currentListing });
  const currentStockRaw = currentListing?.currentStock?.attributes?.quantity;
  // console.log({ currentStockRaw });
  const currentStock = typeof currentStockRaw != null ? currentStockRaw : 1;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingCurriculumDetailPanel.title"
      values={{
        listingTitle: <ListingLink listing={listing} />,
      }}
    />
  ) : (
    <FormattedMessage id="EditListingCurriculumDetailPanel.createListingTitle" />
  );

  const initialValues = React.useMemo(() => {
    if (!initialProps)
      return {
        stock: currentStock,
        url_link: privateData.url_link,
        digital_file_type: config.custom.digitalOptions?.find(
          item => item.key == publicData.digital_file_type
        ),
        material: metadata.material || [],
        // stock: publicData.stock,
        curriculum_grade_span: publicData.curriculum_grade_span,
        //  config.custom.curriculumGradeSpan?.find(
        //   item => item.key == publicData.curriculum_grade_span
        // ),
        curriculum_style: config.custom.curriculumStyle?.find(
          item => item.key == publicData.curriculum_style
        ),
        curriculum_type: config.custom.curriculumType?.find(
          item => item.key == publicData.curriculum_type
        ),
        curriculum_resource_type: publicData?.curriculum_resource_type?.map(item =>
          config.custom.curriculumResourceType?.find(item1 => item1.key == item)
        ),
        curriculum_file_format: config.custom.curriculumFileFormat?.find(
          item => item.key == publicData.curriculum_file_format
        ),
        color: publicData?.color?.map(item =>
          config.custom.colors?.find(item1 => item1.key == item)
        ),
        size: publicData?.size?.map(item => config.custom.sizes?.find(item1 => item1.key == item)),
      };
    return {
      // stock: publicData.stock,
      stock: currentStock,
      url_link: privateData.url_link,
      digital_file_type: config.custom.digitalOptions?.find(
        item => item.key == publicData.digital_file_type
      ),
      material: metadata.material || [],
      curriculum_grade_span: publicData.curriculum_grade_span,
      // config.custom.curriculumGradeSpan?.find(
      //   item => item.key == publicData.curriculum_grade_span
      // ),
      curriculum_style: config.custom.curriculumStyle?.find(
        item => item.key == publicData.curriculum_style
      ),
      curriculum_type: config.custom.curriculumType?.find(
        item => item.key == publicData.curriculum_type
      ),
      curriculum_resource_type: publicData?.curriculum_resource_type?.map(item =>
        config.custom.curriculumResourceType?.find(item1 => item1.key == item)
      ),
      curriculum_file_format: config.custom.curriculumFileFormat?.find(
        item => item.key == publicData.curriculum_file_format
      ),
      color: publicData?.color?.map(item => config.custom.colors?.find(item1 => item1.key == item)),
      size: publicData?.size?.map(item => config.custom.sizes?.find(item1 => item1.key == item)),
      ...initialProps,
    };
  }, [initialProps]);
  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingCurriculumDetailsForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            curriculum_grade_span,
            curriculum_style,
            curriculum_type,
            curriculum_resource_type,
            curriculum_file_format,
            size,
            color,
            stock,
            digital_file_type,
            url_link,
          } = values;
          const hasStockQuantityChanged = stock && currentStockRaw !== stock;

          const oldTotal = currentStockRaw != null ? currentStockRaw : null;
          const stockUpdateMaybe =
            hasStockQuantityChanged && curriculum_file_format?.key == 'physical'
              ? {
                  stockUpdate: {
                    oldTotal,
                    newTotal: parseInt(stock),
                  },
                }
              : {};
          const updateValues = {
            ...stockUpdateMaybe,
            publicData: {
              curriculum_grade_span: curriculum_grade_span,
              curriculum_style: curriculum_style?.key,
              curriculum_type: curriculum_type?.key,
              curriculum_resource_type: curriculum_resource_type?.map(item => item.key),
              curriculum_file_format: curriculum_file_format?.key,
              size: size?.map(item => item.key),
              color: color?.map(item => item.key),
              digital_file_type: digital_file_type?.key,
              url_link,
              stock,
            },
            privateData: {
              url_link,
            },
          };

          setInitialProps({
            ...updateValues,
            curriculum_grade_span,
            curriculum_style,
            curriculum_type,
            curriculum_resource_type,
            curriculum_file_format,
            stock,
            size,
            color,
            url_link,
            digital_file_type,
          });
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        certificateOptions={certificateOptions}
        listing={listing}
      />
    </div>
  );
};

EditListingCurriculumDetailPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingCurriculumDetailPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingCurriculumDetailPanel;
