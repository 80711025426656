import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { OwnListingLink } from '../../components';

import css from './SectionHowItWorksSecond.module.css';
import imageOne from './images/one.png';
import imageTwo from './images/two.png';
import imageThree from './images/three.png';
const SectionHowItWorksSecond = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorksSecond.titleLineOne" />
        {/* <br />
        <FormattedMessage id="SectionHowItWorksSecond.titleLineTwo" /> */}
      </div>
      <div className={css.steps}>
        <div className={css.step}>
          <h2 className={css.stepTitle}>
            <img src={imageOne} className={css.image} />
            <br />
            {/* <FormattedMessage id="SectionHowItWorksSecond.part1Title" /> */}
          </h2>

          <p className={css.subTitle}>
            <FormattedMessage id="SectionHowItWorksSecond.part1Text" />
          </p>
        </div>

        <div className={css.step}>
          <h2 className={css.stepTitle}>
            <img src={imageTwo} className={css.image} />
            <br />
            {/* <FormattedMessage id="SectionHowItWorksSecond.part2Title" /> */}
          </h2>
          <p className={css.subTitle}>
            <FormattedMessage id="SectionHowItWorksSecond.part2Text" />
          </p>
        </div>

        <div className={css.step}>
          <h2 className={css.stepTitle}>
            <img src={imageThree} className={css.image} />
            <br />
            {/* <FormattedMessage id="SectionHowItWorksSecond.part3Title" /> */}
          </h2>
          <p className={css.subTitle}>
            <FormattedMessage id="SectionHowItWorksSecond.part3Text" />
          </p>
        </div>
      </div>
      <div className={css.createListingLink}>
        {/* <OwnListingLink listing={currentUserListing} listingFetched={currentUserListingFetched}>
          <FormattedMessage id="SectionHowItWorksSecond.createListingLink" />
        </OwnListingLink> */}
      </div>
    </div>
  );
};

SectionHowItWorksSecond.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionHowItWorksSecond.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionHowItWorksSecond;
