import React, { useState, useCallback } from 'react';

import { Field, Form as FinalForm } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { isUploadImageOverLimitError } from '../../util/errors';

import css from './ProfileSettingsForm.module.css';
import cssCrop from '../../components/PhotoUploadWithCrop/PhotoUploadWithCrop.module.css';
import Cropper from 'react-easy-crop';
import { Button, Modal } from '../../components';
import getCroppedImg from '../../components/PhotoUploadWithCrop/CropImage';
const ACCEPT_IMAGES = 'image/*';
const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
  // We are just checking the value for now
  console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
};

const ProfilePhotoCrop = props => {
  const { chooseAvatarLabel, uploadImageError, uploadInProgress, onImageUpload, form } = props;

  const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false);
  const [defaultImage, setDefaultImage] = useState(null);
  const [croppedPixels, setCroppedPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedPixels(croppedAreaPixels);
  }, []);
  const toDataURL = url =>
    fetch(url)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(defaultImage, croppedPixels);
    return new Promise((resolve, reject) => {
      toDataURL(croppedImage).then(dataUrl => {
        var fileData = dataURLtoFile(dataUrl, 'imageName.jpg');
        return resolve(fileData);
      });
    });
  };
  return (
    <div>
      <Field
        accept={ACCEPT_IMAGES}
        id="profileImage"
        name="profileImage"
        label={chooseAvatarLabel}
        type="file"
        form={null}
        uploadImageError={uploadImageError}
        disabled={uploadInProgress}
      >
        {fieldProps => {
          const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
          const { name, type } = input;
          const onChange = async e => {
            const file = e.target.files[0];
            //   const isImageSizeValid = await validateImg(file);
            //   if (isImageSizeValid) {
            form.change(`profileImage`, file);
            form.blur(`profileImage`);
            if (file != null) {
              setDefaultImage(URL.createObjectURL(file));
              setIsSelectionModalOpen(true);
              //   const tempId = `${file.name}_${Date.now()}`;
              //   onImageUpload({ id: tempId, file });
            }
            //   }
          };

          let error = null;

          if (isUploadImageOverLimitError(uploadImageError)) {
            error = (
              <div className={css.error}>
                <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
              </div>
            );
          } else if (uploadImageError) {
            error = (
              <div className={css.error}>
                <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
              </div>
            );
          }

          return (
            <div className={css.uploadAvatarWrapper}>
              <label className={css.label} htmlFor={id}>
                {label}
              </label>
              <input
                accept={accept}
                id={id}
                name={name}
                className={css.uploadAvatarInput}
                disabled={disabled}
                onChange={onChange}
                type={type}
              />
              {error}
            </div>
          );
        }}
      </Field>
      <Modal
        id="EditListingPhotosForm.selectImageAreaA"
        containerClassName={cssCrop.selectImageAreaModalContent}
        isOpen={isSelectionModalOpen}
        onClose={() => setIsSelectionModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={cssCrop.addImageWrapperBanner}>
          <div className={cssCrop.aspectRatioWrapper}>
            <div className={cssCrop.App}>
              <div className={cssCrop.cropcontainer}>
                <Cropper
                  image={defaultImage}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 4}
                  //   aspect={aspectRatio}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div className={cssCrop.controls}>
                <input
                  type="range"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={e => {
                    setZoom(e.target.value);
                  }}
                  className={cssCrop.zoomrange}
                />
              </div>
            </div>
          </div>
        </div>
        <Button
          className={cssCrop.submitImageButton}
          type="button"
          onClick={async e => {
            e.preventDefault();
            try {
              const file = await showCroppedImage();
              await setIsSelectionModalOpen(false);
              const tempId = `${file.name}_${Date.now()}`;
              onImageUpload({ id: tempId, file });
            } catch (e) {
              console.log(e);
            }
          }}
        >
          Save
        </Button>
      </Modal>
    </div>
  );
};

export default ProfilePhotoCrop;
