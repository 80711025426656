import React from 'react';
import { useIntl } from 'react-intl';
import { Tabs, Tab } from 'react-tabs-scrollable';
import ExperienceCard from './ExperienceCard';
const SimpleTabs = props => {
  const { experiences = [], cardStyle, defaultImage } = props;
  const [activeTab, setActiveTab] = React.useState(1);
  const intl = useIntl();
  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

  return (
    <Tabs
      activeTab={activeTab}
      onTabClick={onTabClick}
      tabsScrollAmount={1}
      animationDuration={400}
      hideNavBtnsOnMobile={false}
    >
      {experiences?.map((p, index) => (
        <Tab key={index}>
          <div className={cardStyle}>
            <ExperienceCard
              id={p.id.uuid}
              key={p.id.uuid}
              metadata={p?.attributes?.metadata}
              title={p?.attributes?.title}
              description={p?.attributes?.description}
              publicData={p?.attributes?.publicData}
              images={p?.images}
              createdAt={p?.attributes?.createdAt}
              intl={intl}
              price={p?.attributes?.price}
              authorName={p?.author?.attributes?.profile?.displayName}
              author={p?.author}
              defaultImage={defaultImage}
            />
          </div>
        </Tab>
      ))}
    </Tabs>
  );
};

export default SimpleTabs;
