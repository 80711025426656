import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';

import css from './ListingPage.module.css';
function SectionChildrensMaybe(props) {
  const { publicData } = props;
  const { child } = publicData;
  //   console.log(child);
  return child?.length > 0 ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.childrenTitle" />
      </h2>
      <p className={css.description}>
        {child?.map((child, index) => (
          <li key={index}>
            {child?.name}
            <span className={css.gender}>
              {config.custom.gender.find(item => item.key === child?.gender)?.label}
            </span>
          </li>
        ))}
      </p>
    </div>
  ) : null;
}

export default SectionChildrensMaybe;
