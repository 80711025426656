import React, { useState, useEffect, useMemo } from 'react';
import FieldFileUpload from '../FieldFileUpload/FieldFileUpload';
import css from './EditListingCurriculumDetailsForm.module.css';
import axios from 'axios';
import IconSpinner from '../IconSpinner/IconSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { TiDelete } from 'react-icons/ti';
import {
  decrementCounter,
  incrementCounter,
  resetCounter,
  saveFileLinks,
} from '../../containers/EditListingPage/EditListingPage.duck';
import Loading from '../Loading/Loading';
import DotSpinner from '../DotSpinner/DotSpinner';
const MAX_SIZE = 5 * 1024 * 1024 * 1024;
function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
const Material = props => {
  const { form, values, listing } = props;
  const dispatch = useDispatch();
  const [hitDelete, setHitDelete] = useState(false);
  const state = useSelector(state => state.EditListingPage);
  const { counter } = state;
  // console.log('counter', counter);
  const [loading, setLoading] = useState(false);
  const { fileLinks = [] } = state;
  const [size, setSize] = useState(0);
  useEffect(() => {
    const items = [];
    let size = 0;
    (async () => {
      try {
        setLoading(true);
        const materialInDb = await axios.get(`/get-material?listingId=${listing?.id.uuid}`);
        materialInDb?.data?.data?.forEach((i, index) => {
          items.push({
            file: i.file,
            size: i.size,
            loading: false,
            index: i.index,
            label: i.label,
          });
          size += i.size;
        });
        setSize(size);
        setLoading(false);
        dispatch(saveFileLinks(items));
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    })();
    // values?.material?.forEach((i, index) => {
    //   items.push({
    //     file: i.file,
    //     size: i.size,
    //     loading: false,
    //     index: i.index,
    //     label: i.label,
    //   });
    //   size += i.size;
    // });
    // console.log('items', items);
  }, []);
  useEffect(() => {
    if (typeof window === 'undefined') return;
    const container = document.getElementById('container');
    container.scrollTop = container.scrollHeight;
    let size = 0;
    fileLinks?.forEach((i, index) => {
      size += i.size;
    });
    setSize(size);
  }, [fileLinks]);

  const uploadFile = async file => {
    // setLoading(true);
    const formData = new FormData();
    // setSize(size + file.size);
    if (size >= MAX_SIZE) {
      // dispatch(saveFileLinks([...fileLinks?.filter(l => l?.index !== index)]));
      return;
    }
    formData.append('photo', file);
    formData.append('listingId', listing?.id.uuid);
    dispatch(incrementCounter());
    // formData.append('index', index);
    try {
      const res = await axios.post('/upload-file', formData);
      const { data } = res.data;
      console.log('hit');
      // console.log('data', data);
      // const newLinks = [...fileLinks, { file: link, size: size, loading: false, index: index }];
      dispatch(saveFileLinks(data));
      dispatch(decrementCounter());

      // if (counter === 0) {
      //   setLoading(false);
      // }
      // form.change('material', [...values?.material, { link, size }]);
    } catch (e) {
      console.log(e);
      dispatch(resetCounter());
      // dispatch(saveFileLinks([...fileLinks?.filter(l => l?.index !== index)]));
      // setLoading(false);
    }
  };
  // console.log(fileLinks);
  const deleteFile = async (link, index) => {
    // console.log('link', link);
    // console.log('index', { index, fileIndex: link?.index });
    setHitDelete(true);
    try {
      dispatch(
        saveFileLinks([
          ...fileLinks?.map(l => (l?.file === link.file ? { ...l, loading: true } : l)),
        ])
      );
      setSize(size - link?.size);
      const formData = new FormData();
      formData.append('link', link?.file);
      formData.append('listingId', listing?.id.uuid);
      await axios.post('/delete-file', formData);
      dispatch(saveFileLinks([...fileLinks?.filter(l => l?.file !== link.file)]));
      // form.change('material', [...values?.material?.filter(l => l !== link.file)]);
      setHitDelete(false);
    } catch (e) {
      console.log(e);
      setHitDelete(false);
      dispatch(saveFileLinks([...fileLinks?.filter(l => l?.file !== link.file)]));
    }
  };

  return (
    <div className={css.mainContainer}>
      <h3 className={css.title}>
        Material{' '}
        <span className={css.size}>
          ({formatBytes(size)}/{formatBytes(MAX_SIZE)})
        </span>
      </h3>
      <ul className={css.fileContainer} id="container">
        {fileLinks?.map((file, index) => (
          <div className={css.container}>
            <li className={css.uploadedFiles} key={index}>
              {file.loading ? (
                <Loading />
              ) : (
                <a href={file?.file} className={css.link} target="__blank">
                  {file?.label || file.file?.split('/').pop()}
                  {/* {file.file?.split('/').pop()} */}
                </a>
              )}
            </li>
            {file.loading ? null : (
              <div className={hitDelete ? css.deleteContainerDisabled : css.deleteContainer}>
                <TiDelete
                  className={hitDelete ? css.deleteDisabled : css.delete}
                  type="button"
                  onClick={async () => {
                    if (hitDelete) return;
                    if (!file.loading) await deleteFile(file, file.index);
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </ul>
      {size >= MAX_SIZE ? null : (
        <FieldFileUpload
          className={css.title}
          name="photo"
          id="photo"
          label={
            <div className={css.file}>
              <div className={css.uploadLabel}>
                {counter > 0 || loading ? (
                  <p className={css.spinner}>
                    <DotSpinner />{' '}
                  </p>
                ) : (
                  'upload File'
                )}
              </div>
            </div>
          }
          center={false}
          showUploading={true}
          // uploading={loading}
          onSelectFile={async file => {
            const arrayFile = Object.keys(file).map(key => file[key]);

            [...arrayFile].map(async (item, i) => {
              // dispatch(
              //   saveFileLinks([
              //     ...fileLinks,
              //     {
              //       file: file?.name,
              //       size: file.size,
              //       loading: true,
              //       index: fileLinks?.length + 1,
              //     },
              //   ])
              // );
              // setTimeout(async () => {
              await uploadFile(item);
              // }, 2000);

              // await uploadFile(item, fileLinks?.length + 1);
            });

            //   console.log('onSelectFile -> arrayFile', arrayFile);
            return;
            if (file?.length) {
              for (let i = 0; i < file.length; i++) {
                setLinks([...links, { file: file?.name, loading: true, index: links?.length }]);
                await uploadFile(file[i], links?.length);
              }
            }
          }}
          multiple
        />
      )}
    </div>
  );
};

export default Material;
