import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';

import nyImage from './images/image2.png';
import laImage from './images/image3.png';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <div to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
    </div>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locations}>
        <div className={css.location}>
          <div className={css.imageWrapper}>
            <div className={css.aspectWrapper}>
              <LazyImage src={nyImage} alt={nyImage} className={css.locationImage} />
            </div>
          </div>
          <div className={css.linkText}>
            <a
              href="http://help.hometribe.com/en/articles/5879292-the-homeschooling-starter-kit"
              target="__blank"
            >
              {' '}
              <FormattedMessage
                id="SectionLocations.listingsInLocationRead"
                // values={{ location: nameText }}
              />
            </a>
          </div>
        </div>
        <div className={css.location}>
          <div className={css.imageWrapper}>
            <div className={css.aspectWrapper}>
              <LazyImage src={laImage} alt={laImage} className={css.locationImage} />
            </div>
          </div>
          <div className={css.linkText}>
            <a
              href="http://help.hometribe.com/en/articles/5890385-for-teachers-how-to-shift-from-public-school-to-home-education"
              target="__blank"
            >
              {' '}
              <FormattedMessage
                id="SectionLocations.listingsInLocationRead"
                // values={{ location: nameText }}
              />
            </a>
          </div>
        </div>

        {/* {locationLink(
          'Home Educator Offering Extracurricular Enrichment Classes',
          laImage,
          '?pub_program_offered=has_any:enrichment_learning_center'
        )} */}
        {/* {locationLink(
          'Home Educator Offering Tutoring Services',
          location4,
          '?pub_program_offered=has_any:tutoring'
        )} */}
        {/* {locationLink(
          'Family Seeking Homeschool Friends for Play Dates/Fun Outings',
          sfImage,
          '?pub_program_offered=has_any:lifeCoaching'
        )} */}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
