import moment from 'moment';
import React from 'react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { MdSystemUpdateAlt } from 'react-icons/md';

// const tracking_history = [
//   {
//     object_created: '2022-05-28T17:03:53.248Z',
//     object_updated: null,
//     object_id: 'f2efedad34a14584b7fd140dd0092cac',
//     status: 'UNKNOWN',
//     status_details: 'The carrier has received the electronic shipment information.',
//     status_date: '2022-05-24T08:43:53.248Z',
//     substatus: null,
//     location: {
//       city: 'San Francisco',
//       state: 'CA',
//       zip: '94103',
//       country: 'US',
//     },
//   },
//   {
//     object_created: '2022-05-28T17:03:53.248Z',
//     object_updated: null,
//     object_id: '0b3ebf84abe04a5bb2112ea12fe3bb35',
//     status: 'TRANSIT',
//     status_details: 'Your shipment has departed from the origin.',
//     status_date: '2022-05-25T10:48:53.248Z',
//     substatus: null,
//     location: {
//       city: 'San Francisco',
//       state: 'CA',
//       zip: '94103',
//       country: 'US',
//     },
//   },
//   {
//     object_created: '2022-05-28T17:03:53.248Z',
//     object_updated: null,
//     object_id: '90321dd5307a4d899a027a5ae4f2fd0f',
//     status: 'FAILURE',
//     status_details:
//       'The Postal Service has identified a problem with the processing of this item and you should contact support to get further information.',
//     status_date: '2022-05-26T12:53:53.248Z',
//     substatus: null,
//     location: { city: 'Memphis', state: 'TN', zip: '37501', country: 'US' },
//   },
//   {
//     object_created: '2022-05-28T17:03:53.248Z',
//     object_updated: null,
//     object_id: '76cefeabe9924d3bacd8c3ada4e5382d',
//     status: 'DELIVERED',
//     status_details: 'Your shipment has been delivered.',
//     status_date: '2022-05-27T14:58:53.248Z',
//     substatus: null,
//     location: { city: 'Chicago', state: 'IL', zip: '60611', country: 'US' },
//   },
// ];

function TrackingPanel({ trackingHistory }) {
  return (
    <div className="my-4">
      <Timeline>
        {trackingHistory.map(t => (
          <TimelineEvent
            key={t.object_id}
            title={'Status: ' + t.status}
            createdAt={moment(t.status_date).format('MMMM Do YYYY, h:mm:ss a')}
            icon={<MdSystemUpdateAlt className="text-green-700 text-base" />}
          >
            {t.status_details}
          </TimelineEvent>
        ))}
      </Timeline>
    </div>
  );
}

TrackingPanel.defaultProps = {
  trackingHistory: [],
};

export default TrackingPanel;
