import React from 'react';
import css from './SectionOwnExperiences.module.css';
import { injectIntl } from '../../../util/reactIntl';
import { IconSpinner } from '../../../components';
import classNames from 'classnames';
import defaultImage from './default.jpeg';
import SimpleTabs from './SimpleTabs';
const SectionOwnExperiences = ({ loading, error, experiences, intl, isMobileLayout }) => {
  if (error) {
    return (
      <div>
        <span className={css.error}>
          {intl.formatMessage({
            id: 'ListingPage.SectionOwnExperiences.error',
          })}
        </span>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={css.loadingContainer}>
        <p>
          <IconSpinner />
        </p>
      </div>
    );
  }

  return (
    <div className={classNames(css.productsWrapper)}>
      {experiences?.length > 0 ? (
        <h3 className={`text-left text-2xl font-bold mt-6 ${css.allColumns}`}>
          {intl.formatMessage({ id: 'listingPage.experienceTitle' })}-({experiences?.length})
        </h3>
      ) : null}

      <SimpleTabs
        experiences={experiences}
        cardStyle={css.card}
        defaultImage={defaultImage}
        isMobileLayout={isMobileLayout}
      />
    </div>
  );
};

export default injectIntl(SectionOwnExperiences);
