import React, { Component, useEffect } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, IconSpinner, NamedLink, PrimaryButton } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import moment from 'moment';
import css from './BookingTimeForm.module.css';

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applied: false,
      loading: false,
      discountSuccess: null,
      discountFailure: null,
      coupon: '',
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit({ ...e, coupon: this.state.coupon });
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const {
      bookingStartTime,
      bookingEndTime,
      service,
      eventDate,
      participants,
    } = formValues.values;

    const startEventDate = new Date(
      moment()
        .add(1, 'hours')
        .format('YYYY-MM-DD HH:00:00')
    );
    const endEventDate = new Date(
      moment()
        .add(2, 'hours')
        .format('YYYY-MM-DD HH:00:00')
    );

    let startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    let endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    const isSameTime = bookingStartTime === bookingEndTime;

    const selectedService = service ? JSON.parse(service).key : null;
    const selectedServiceType = service ? JSON.parse(service).type : null;
    const selectedServiceExp = service ? JSON.parse(service).experienceType : null;
    const isBooking =
      selectedServiceType == 'one-on-one-online' || selectedServiceType == 'one-on-one-in-person';
    let event = false;
    if (isBooking) {
      startDate = startDate;
      endDate = endDate;
      event = false;
    } else {
      startDate = startEventDate;
      endDate = endEventDate;
      event = true;
    }

    const isPricedService = this.props.breakdownOptions.map(k => k.key).includes(selectedService);

    const couponMaybe =
      this.state.coupon && this.state.applied ? { coupon: this.state.coupon } : {};

    if (
      startDate &&
      endDate &&
      // bookingStartTime &&
      // bookingEndTime &&
      // !isSameTime &&
      service &&
      !this.props.fetchLineItemsInProgress &&
      isPricedService
    ) {
      this.props.onFetchTransactionLineItems({
        bookingData: {
          startDate,
          endDate,
          service: selectedService,
          event,
          participants,
          optionData: selectedServiceExp,
          ...couponMaybe,
        },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, groupOptions, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    // if (!unitPrice) {
    //   return (
    //     <div className={classes}>
    //       {/* <p className={css.error}>
    //         <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
    //       </p> */}
    //     </div>
    //   );
    // }
    // if (unitPrice.currency !== config.currency) {
    //   return (
    //     <div className={classes}>
    //       <p className={css.error}>
    //         <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
    //       </p>
    //     </div>
    //   );
    // }

    const { applied, discountFailure, discountSuccess, loading, coupon } = this.state;
    const updateState = newState => this.setState(newState);

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        applied={applied}
        failure={discountFailure}
        success={discountSuccess}
        loading={loading}
        coupon={coupon}
        updateState={updateState}
        handleOnChange={this.handleOnChange}
        render={fieldRenderProps => {
          const {
            applied,
            failure,
            success,
            loading,
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            allowedPrograms,
            publicData,
            showContactUser,
            onContactUser,
            eventPriceTitle,
            handleEvent,
            isSubscribed,
            currentUser,
            allOptions,
            breakdownOptions,
            handleEventBookingSubmit,
            test,
            coupon,
            handleOnChange,
          } = fieldRenderProps;
          const eventDate = publicData?.event_date;

          useEffect(() => {
            if (applied) {
              updateState({ applied: false, discountSuccess: null, discountFailure: null });
            }
          }, [coupon]);

          useEffect(() => {
            if (values.service) {
              updateState({
                coupon: '',
                applied: false,
                discountSuccess: null,
                discountFailure: null,
              });
            }
          }, [values.service]);

          const isEventExpired = moment(new Date(eventDate)).unix() < moment(new Date()).unix();
          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;
          const selectedService = values.service ? JSON.parse(values.service) : null;
          const participants = values.participants ? values.participants : null;
          const isBooking =
            selectedService?.type == 'one-on-one-online' ||
            selectedService?.type == 'one-on-one-in-person';
          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });
          const startEventDate = new Date(
            moment()
              .add(1, 'hours')
              .format('YYYY-MM-DD HH:00:00')
          );
          const endEventDate = new Date(
            moment()
              .add(2, 'hours')
              .format('YYYY-MM-DD HH:00:00')
          );
          let startDate = startTime ? timestampToDate(startTime) : null;
          let endDate = endTime ? timestampToDate(endTime) : null;
          if (isBooking) {
            startDate = startDate;
            endDate = endDate;
          } else {
            startDate = startEventDate;
            endDate = endEventDate;
          }
          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  participants,
                  endDate,
                  timeZone,
                  showDate: isBooking ? true : false,
                }
              : null;

          // const showEstimatedBreakdown =
          //   // values?.service &&
          //   // config.custom.pricedServices.includes(values?.service) &&
          //   bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;
          const showEstimatedBreakdown =
            // values?.service &&
            // config.custom.pricedServices.includes(values?.service) &&
            bookingData &&
            lineItems &&
            !fetchLineItemsInProgress &&
            !fetchLineItemsError &&
            this.props.breakdownOptions.map(k => k.key).includes(selectedService?.key);
          const optionData = selectedService
            ? allOptions.find(o => o.key == selectedService.key)
            : null;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                participants={participants}
                lineItems={lineItems}
                optionData={optionData}
              />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          // const optionData = selectedService
          //   ? allOptions.find(o => o.key == selectedService.key)
          //   : null;
          // console.log('optionData', optionData?.key, isBooking);
          const hasBookingOptionSelect =
            optionData?.key && isBooking
              ? breakdownOptions.map(k => k.key).includes(optionData.key)
              : null;

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  applied={applied}
                  failure={failure}
                  success={success}
                  loading={loading}
                  coupon={coupon}
                  updateState={updateState}
                  handleOnChange={handleOnChange}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  groupOptions={groupOptions}
                  allowedPrograms={allowedPrograms}
                  publicData={publicData}
                  FormattedMessage={FormattedMessage}
                  allOptions={allOptions}
                  showContactUser={showContactUser}
                  currentUser={currentUser}
                  onContactUser={onContactUser}
                  handleEventBookingSubmit={handleEventBookingSubmit}
                  test={test}
                  isSubscribed={isSubscribed}
                  bookingInfoMaybe={bookingInfoMaybe}
                  loadingSpinnerMaybe={loadingSpinnerMaybe}
                  bookingInfoErrorMaybe={bookingInfoErrorMaybe}
                  formApi={form}
                />
              ) : null}
              {hasBookingOptionSelect ? (
                <>
                  {bookingInfoMaybe}
                  {loadingSpinnerMaybe}
                  {bookingInfoErrorMaybe}
                </>
              ) : null}
              {/* {isEventExpired && values?.service == 'home_education_event' ? (
                <div className={css.eventExpire}>
                  <FormattedMessage id="BookingTimeForm.eventExpireMessage" />
                </div>
              ) : values?.service == 'home_education_event' ? (
                <>
                  {form.change('eventDate', eventDate)}
                  <div className={css.eventContainer}>
                    <div className={css.eventDate}>
                      <FormattedMessage id="BookingTimeForm.eventLabel" />
                    </div>
                    <div>
                      <FormattedMessage id="BookingTimeForm.eventPriceLabel" />
                    </div>
                  </div>
                  <div className={css.eventContainer}>
                    <div className={css.eventDate}>{moment(eventDate).format('DD-MM-YYYY')}</div>
                    <div>{eventPriceTitle}</div>
                  </div>
                </>
              ) : null} */}

              {hasBookingOptionSelect ? (
                <>
                  <p className={css.smallPrint}>
                    <FormattedMessage
                      id={
                        isOwnListing
                          ? 'BookingTimeForm.ownListing'
                          : 'BookingTimeForm.youWontBeChargedInfo'
                      }
                    />
                  </p>
                  <div className={submitButtonClasses}>
                    <PrimaryButton type="submit">
                      <FormattedMessage id="BookingTimeForm.requestToBook" />
                    </PrimaryButton>
                  </div>
                </>
              ) : null
              // (
              //   hasBookingOptionSelect && (
              //     <div className={submitButtonClasses}>
              //       <NamedLink name="MembershipPage" className={css.membershipButton}>
              //         <FormattedMessage id="BookingTimeForm.requestToBook" />
              //       </NamedLink>
              //     </div>
              //   )
              // )
              }
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
