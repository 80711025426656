import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import css from './SectionBlog.module.css';
import { injectIntl } from '../../util/reactIntl';
import NamedLink from '../NamedLink/NamedLink';
import IconSpinner from '../IconSpinner/IconSpinner';
import config from '../../config';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);
const getTitle = title => (title?.length > 70 ? title?.substring(0, 67) + '...' : title);
const blogLink = (name, post, image, id) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="ListingPage" className={css.location} params={{ id, slug: '' }}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText} title={name}>
        {nameText}
        <p className={css.linkPost} title={post}>
          {getTitle(post)}
        </p>
      </div>
    </NamedLink>
  );
};

const SectionBlog = props => {
  const { rootClassName, className, intl, listings, loading, error } = props;
  const classes = classNames(rootClassName || css.root, className);
  // console.log(listings);

  const listingIds = config.custom.featuredListingId;

  const errorMessage = intl.formatMessage({ id: 'LandingPage.featuredEducatorError' });
  if (error) {
    return (
      <div>
        <span className={css.error}>{errorMessage}</span>
      </div>
    );
  }
  if (listings?.length < 1) {
    return null;
  }
  if (loading) {
    return (
      <div className={css.loadingContainer}>
        <p>
          <IconSpinner style={{ style: { height: '50px', width: '50px' } }} />
        </p>
      </div>
    );
  }
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionBlog.title" />
      </div>
      <div className={css.locations}>
        {listings?.map((l, index) => {
          return (
            <>
              {blogLink(
                listings?.find(listing => listing.id.uuid === listingIds[index])?.attributes?.title,
                listings?.find(listing => listing.id.uuid === listingIds[index])?.attributes
                  ?.description,
                listings?.find(item => item.id.uuid === listingIds[index])?.author?.profileImage
                  ?.attributes?.variants?.default?.url,
                listingIds[index]
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

SectionBlog.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionBlog.propTypes = {
  rootClassName: string,
  className: string,
};

export default injectIntl(SectionBlog);
