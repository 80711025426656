import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { MdOutlineCallEnd, MdScreenShare, MdStopScreenShare } from 'react-icons/md';
import {
  BsFillMicFill,
  BsFillMicMuteFill,
  BsFillCameraVideoOffFill,
  BsFillCameraVideoFill,
} from 'react-icons/bs';
import { LocalVideoTrack } from 'twilio-video';

function MultipleParticipantsVideoCall({
  room,
  localParticipant,
  remoteParticipants,
  history,
  txn,
  currentUser,
}) {
  const [audioMuted, setAudioMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [screenShared, setScreenShared] = useState(false);

  const screenTrack = useRef(null);
  const disconnectCall = () => {
    localParticipant.tracks?.forEach(function(trackPublication) {
      trackPublication.track.stop();
    });
    // JUDGE LINK ON THE BASIS OF USER ROLE
    const linkPath = txn.customer.id.uuid == currentUser.id.uuid ? 'order' : 'sale';
    history.replace(`/${linkPath}/${txn.id.uuid}/details`);
  };

  const onShareScreen = async () => {
    if (!room?.localParticipant) return;
    if (!screenShared)
      try {
        const stream = await navigator.mediaDevices.getDisplayMedia();
        screenTrack.current = new LocalVideoTrack(stream.getTracks()[0]);

        if (stream.getTracks?.()?.[0])
          stream.getTracks()[0].onended = function() {
            room.localParticipant.unpublishTrack(screenTrack.current);
            screenTrack.current.stop();
            screenTrack.current = null;
            setScreenShared(false);
          };

        room.localParticipant.publishTrack(screenTrack.current);

        setScreenShared(true);
      } catch (e) {
        console.log(e);
        alert('Could not share the screen.');
      }
    else {
      room.localParticipant.unpublishTrack(screenTrack.current);
      screenTrack.current.stop();
      screenTrack.current = null;

      setScreenShared(false);
    }
  };

  return (
    <div className="relative h-screen w-screen bg-gray-900 overflow-hidden lg:overflow-auto">
      {/* Local Person View */}
      <h2 className="text-gray-400 text-center text-3xl mt-0 pt-6">Live Workshop</h2>

      <div className="relative h-4/5 flex  w-full items-center justify-center">
        <div className="flex  h-4/5  items-center justify-center mt-6">
          {remoteParticipants?.map(r => <Remote key={r.sid} participant={r} />)}
        </div>

        {/* Remote */}
        <div className="absolute right-5 lg:right-10 top-5 border-4 border-solid border-slate-400 rounded lg:top-1/2 h-40">
          {localParticipant && <Local participant={localParticipant} />}
        </div>
      </div>

      {/* Action Buttons */}
      <div className="absolute bottom-20 md:bottom-10 left-1/2 -translate-x-1/2 flex gap-4">
        <button
          onClick={() => {
            const action = !videoMuted;
            localParticipant?.videoTracks?.forEach(function(trackPublication) {
              trackPublication.track[action ? 'disable' : 'enable']();
            });
            setVideoMuted(action);
          }}
          type="button"
          role="control"
          className="flex items-center justify-center w-12 h-12 rounded-full border-none bg-white shadow cursor-pointer hover:bg-gray-200 transition duration-200"
        >
          {videoMuted ? (
            <BsFillCameraVideoOffFill className="text-lg" />
          ) : (
            <BsFillCameraVideoFill className="text-lg" />
          )}
        </button>
        <button
          onClick={() => {
            const action = !audioMuted;
            localParticipant?.audioTracks?.forEach(function(trackPublication) {
              trackPublication.track[action ? 'disable' : 'enable']();
            });
            setAudioMuted(action);
          }}
          type="button"
          role="control"
          className="flex items-center justify-center w-12 h-12 rounded-full border-none bg-white shadow cursor-pointer hover:bg-gray-200 transition duration-200"
        >
          {audioMuted ? (
            <BsFillMicMuteFill className="text-lg" />
          ) : (
            <BsFillMicFill className="text-lg" />
          )}
        </button>
        <button
          type="button"
          role="control"
          onClick={disconnectCall}
          className="flex items-center justify-center w-12 h-12 rounded-full border-none  shadow bg-red-500 cursor-pointer hover:bg-red-600 transition duration-200"
        >
          <MdOutlineCallEnd className="text-white text-lg" />
        </button>
        {/* <button
          onClick={onShareScreen}
          type="button"
          role="control"
          disabled={!room?.localParticipant}
          className="flex items-center justify-center w-12 h-12 rounded-full border-none bg-white shadow cursor-pointer hover:bg-gray-200 transition duration-200"
        >
          {screenShared ? (
            <MdStopScreenShare className="text-lg" />
          ) : (
            <MdScreenShare className="text-lg" />
          )}
        </button> */}
      </div>
    </div>
  );
}

export default withRouter(MultipleParticipantsVideoCall);

function Local(props) {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [audioMuted, setAudioMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const { participant } = props;

  const videoRef = useRef();
  const audioRef = useRef();

  const disconnect = () => {
    participant.tracks?.forEach(function(trackPublication) {
      trackPublication.track.stop();
    });
  };

  const trackpubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null);
  useEffect(() => {
    const trackSubscribed = track => {
      if (track.kind === 'video') {
        // console.log(track);
        // videoTracks => [...videoTracks, track]
        setVideoTracks([...videoTracks, track]);
      } else {
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    };
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    window.addEventListener('beforeunload', disconnect);
    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);
  useEffect(() => {
    const videoTrack = videoTracks[0];
    const audioTrack = audioTracks[0];
    if (videoTrack && audioTrack) {
      videoTrack.attach(videoRef.current);
      audioTrack.attach(audioRef.current);
      return () => {
        videoTrack.detach();
        audioTrack.detach();
      };
    }
  }, [videoTracks, audioTracks]);

  const disconnectCall = () => {
    participant.tracks?.forEach(function(trackPublication) {
      trackPublication.track.stop();
    });
    // JUDGE LINK ON THE BASIS OF USER ROLE
    // const linkPath = transaction.customer.id.uuid == currentUser.id.uuid ? 'orders' : 'sales';
    // history.replace(`/inbox/${linkPath}`);
    history.replace('/');
  };

  const connected = participant?.state == 'connected';
  return (
    <>
      <video
        ref={videoRef}
        autoPlay={true}
        className="block h-full object-contain rounded-md"
        style={{ transform: 'rotateY(180deg)' }}
      />
      <audio ref={audioRef} autoPlay={true} />
    </>
  );
}

function Remote(props) {
  const { participant } = props;
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [audioMuted, setAudioMuted] = useState(false);

  const videoRef = useRef();
  const audioRef = useRef();
  const trackpubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null);
  useEffect(() => {
    const trackSubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => [...videoTracks, track]);
      } else {
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    };
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);
  useEffect(() => {
    const videoTrack = videoTracks[0];
    const audioTrack = audioTracks[0];
    if (videoTrack && audioTrack) {
      videoTrack.attach(videoRef.current);
      audioTrack.attach(audioRef.current);
      return () => {
        videoTrack.detach();
        audioTrack.detach();
      };
    }
  }, [videoTracks, audioTracks]);
  return (
    <>
      <video
        className="block h-full object-contain w-full max-w-4xl  rounded-md"
        autoPlay={true}
        ref={videoRef}
      />
      <audio ref={audioRef} autoPlay={true} />
    </>
  );
}
