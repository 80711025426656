import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { Form, Button } from '../../components';
import config from '../../config';
import css from './EditListingExperienceForm.module.css';
import arrayMutators from 'final-form-arrays';
import ProgramOfferedContact from './ProgramOfferedContact';
import { types as sdkTypes } from '../../util/sdkLoader';
import ProgramOfferedInPerson from './ProgramOfferedInPerson';
import ProgramOfferedOnline from './ProgramOfferedOnline';
import moment from 'moment';
const { Money } = sdkTypes;

const TITLE_MAX_LENGTH = 60;

const EditListingExperienceFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
      } = formRenderProps;

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingExperienceForm.updateFailed" />
        </p>
      ) : null;
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const priceRequiredMessage = intl.formatMessage({
        id: 'EditListingExperienceForm.priceRequired',
      });
      const priceRequired = validators.required(priceRequiredMessage);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );

      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingExperienceForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingExperienceForm.showListingFailed" />
        </p>
      ) : null;
      const programOfferedContactMessage = intl.formatMessage({
        id: 'EditListingExperienceForm.programOfferedContactLabel',
      });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <div className={css.contactOnlyContainer}>
            <h3>{programOfferedContactMessage}</h3>
            {config.custom.contactOptions?.map(item => (
              <ProgramOfferedContact
                label={item.label}
                id={item.key}
                values={values}
                style={css.title}
                priceValidators={priceValidators}
                intl={intl}
              />
            ))}
          </div>
          <ProgramOfferedInPerson
            intl={intl}
            values={values}
            style={css.title}
            priceValidators={priceValidators}
          />
          <ProgramOfferedOnline
            intl={intl}
            values={values}
            style={css.title}
            priceValidators={priceValidators}
          />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingExperienceFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingExperienceFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingExperienceFormComponent);
