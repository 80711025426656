import React, { Component, useEffect } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../../../util/reactIntl';
import { timestampToDate } from '../../../../util/dates';
import { propTypes } from '../../../../util/types';
import config from '../../../../config';
import { Form, IconSpinner, PrimaryButton } from '../../../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';

import css from './BookingTimeForm.module.css';

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applied: false,
      loading: false,
      discountSuccess: null,
      discountFailure: null,
      coupon: '',
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit({ ...e, coupon: this.state.coupon });
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { bookingStartTime, bookingEndTime, participants } = formValues.values;
    const { publicData } = this.props;
    const isFreeType = publicData.priceType == 'free';

    if (typeof participants == 'undefined' || isFreeType) return;

    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;
    console.log({ startDate, endDate, bookingStartTime, bookingEndTime, participants });
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime === bookingEndTime;

    const couponMaybe =
      this.state.coupon && this.state.applied ? { coupon: this.state.coupon } : {};

    if (bookingStartTime && bookingEndTime && !isSameTime && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate, ...couponMaybe, participants: +participants },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    // if (!unitPrice) {
    //   return (
    //     <div className={classes}>
    //       <p className={css.error}>
    //         <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
    //       </p>
    //     </div>
    //   );
    // }
    // if (unitPrice.currency !== config.currency) {
    //   return (
    //     <div className={classes}>
    //       <p className={css.error}>
    //         <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
    //       </p>
    //     </div>
    //   );
    // }

    const { applied, discountFailure, discountSuccess, loading, coupon } = this.state;
    const updateState = newState => this.setState(newState);

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        applied={applied}
        failure={discountFailure}
        success={discountSuccess}
        loading={loading}
        coupon={coupon}
        updateState={updateState}
        handleOnChange={this.handleOnChange}
        render={fieldRenderProps => {
          const {
            applied,
            failure,
            success,
            loading,
            publicData,
            showContactUser,
            onContactUser,
            isSubscribed,
            currentUser,
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            handleOnChange,
          } = fieldRenderProps;

          useEffect(() => {
            if (applied) {
              updateState({ applied: false, discountSuccess: null, discountFailure: null });
            }
          }, [coupon]);

          useEffect(() => {
            if (values.service) {
              updateState({
                coupon: '',
                applied: false,
                discountSuccess: null,
                discountFailure: null,
              });
            }
          }, [values.service]);

          const isFreeType = publicData.priceType == 'free';

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  timeZone,
                  showDate: true,
                }
              : null;

          const participants = values?.participants ? +values?.participants : null;
          const maxSeats = values?.maxSeats ? +values?.maxSeats : null;

          const showEstimatedBreakdown =
            bookingData &&
            lineItems &&
            !fetchLineItemsInProgress &&
            !fetchLineItemsError &&
            participants &&
            participants > 0 &&
            maxSeats >= participants &&
            !isFreeType;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                lineItems={lineItems}
                participants={participants}
                optionData={{
                  experienceType: 'in-person',
                }}
              />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const submitDisabled =
            !(
              values?.bookingStartTime &&
              values?.bookingEndTime &&
              values?.bookingStartDate?.date &&
              values?.bookingEndDate?.date &&
              values?.participants > 0
            ) || loading;

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  applied={applied}
                  failure={failure}
                  success={success}
                  loading={loading}
                  coupon={coupon}
                  updateState={updateState}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  publicData={publicData}
                  showContactUser={showContactUser}
                  currentUser={currentUser}
                  onContactUser={onContactUser}
                  isSubscribed={isSubscribed}
                  bookingInfoMaybe={bookingInfoMaybe}
                  loadingSpinnerMaybe={loadingSpinnerMaybe}
                  bookingInfoErrorMaybe={bookingInfoErrorMaybe}
                  formApi={form}
                  handleOnChange={handleOnChange}
                />
              ) : null}

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              {!isFreeType && (
                <p className={css.smallPrint}>
                  <FormattedMessage
                    id={
                      isOwnListing
                        ? 'BookingTimeForm.ownListing'
                        : 'BookingTimeForm.youWontBeChargedInfo'
                    }
                  />
                </p>
              )}
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" disabled={submitDisabled}>
                  <FormattedMessage id="BookingTimeForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
