import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingExperienceForm } from '../../forms';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
import css from './EditListingExperiencePanel.module.css';

const EditListingExperiencePanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;
  const [initialProps, setInitialProps] = useState({});
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingExperiencePanel.title"
      values={{
        listingTitle: <ListingLink listing={listing} />,
      }}
    />
  ) : (
    <FormattedMessage id="EditListingExperiencePanel.createListingTitle" />
  );
  const combineData = (name, type) => {
    return name?.length > 0 ? { [name[0]]: type?.value } : null;
  };
  const normalizeContactData = values => {
    const {
      parent_led_home_education_pod,
      home_education_pod,
      home_education_pod_trading_time,
      home_education_co_op,
      enrichment_learning_center,
      resource_center,
      charter_school,
      psp_satellite_school,
      record_keeping_services,
      hiring_an_educator,
      iep,
      curriculum_materials,
      curriculum_materials_type,
      academy,
      doula_midwife_services,
      parent_led_home_education_pod_type,
      home_education_pod_type,
      home_education_pod_trading_time_type,
      home_education_co_op_type,
      enrichment_learning_center_type,
      resource_center_type,
      charter_school_type,
      psp_satellite_school_type,
      record_keeping_services_type,
      hiring_an_educator_type,
      iep_type,
      doula_midwife_services_type,
      academy_type,
      microschool,
      microschool_type,
      online_program,
      online_program_type,
      travel_company,
      travel_company_type,
      vacation_company,
      vacation_company_type,
      general_studies,
      general_studies_type,
      birthing_center,
      birthing_center_type,
      pregnancy_services,
      pregnancy_services_type,
      pregnancy_center,
      pregnancy_center_type,
      homebirth_services,
      homebirth_services_type,
      womens_wellness_services,
      womens_wellness_services_type,
    } = values;

    let result = {
      ...combineData(doula_midwife_services, doula_midwife_services_type),
      ...combineData(iep, iep_type),
      ...combineData(parent_led_home_education_pod, parent_led_home_education_pod_type),
      ...combineData(home_education_pod, home_education_pod_type),
      ...combineData(home_education_pod_trading_time, home_education_pod_trading_time_type),
      ...combineData(home_education_co_op, home_education_co_op_type),
      ...combineData(enrichment_learning_center, enrichment_learning_center_type),
      ...combineData(resource_center, resource_center_type),
      ...combineData(online_program, online_program_type),
      ...combineData(travel_company, travel_company_type),
      ...combineData(vacation_company, vacation_company_type),
      ...combineData(pregnancy_services, pregnancy_services_type),
      ...combineData(general_studies, general_studies_type),
      ...combineData(birthing_center, birthing_center_type),
      ...combineData(pregnancy_center, pregnancy_center_type),
      ...combineData(homebirth_services, homebirth_services_type),
      ...combineData(womens_wellness_services, womens_wellness_services_type),

      ...combineData(charter_school, charter_school_type),
      ...combineData(psp_satellite_school, psp_satellite_school_type),
      ...combineData(record_keeping_services, record_keeping_services_type),
      ...combineData(hiring_an_educator, hiring_an_educator_type),
      ...combineData(academy, academy_type),
      ...combineData(curriculum_materials, curriculum_materials_type),
      ...combineData(microschool, microschool_type),
    };

    return { result, contactData: Object.keys(result) };
  };
  const normalizePrograms = values => {
    const {
      tutoring_in_person,
      enrichment_activities_in_person,
      therapy_counseling_session_in_person,
      home_education_workshop_in_person,
      home_education_consultation_in_person,
      sports_coaching_in_person,
      coaching_in_person,
      general_studies_in_person,
      birthing_center_in_person,
      pregnancy_services_in_person,
      pregnancy_center_in_person,
      homebirth_services_in_person,
      womens_wellness_services_in_person,
    } = values;
    let resultArray = [];
    tutoring_in_person?.length > 0 && resultArray.push(tutoring_in_person[0]);
    enrichment_activities_in_person?.length > 0 &&
      resultArray.push(enrichment_activities_in_person[0]);
    therapy_counseling_session_in_person?.length > 0 &&
      resultArray.push(therapy_counseling_session_in_person[0]);
    home_education_workshop_in_person?.length > 0 &&
      resultArray.push(home_education_workshop_in_person[0]);
    home_education_consultation_in_person?.length > 0 &&
      resultArray.push(home_education_consultation_in_person[0]);
    sports_coaching_in_person?.length > 0 && resultArray.push(sports_coaching_in_person[0]);
    coaching_in_person?.length > 0 && resultArray.push(coaching_in_person[0]);
    general_studies_in_person?.length > 0 && resultArray.push(general_studies_in_person[0]);
    birthing_center_in_person?.length > 0 && resultArray.push(birthing_center_in_person[0]);
    pregnancy_services_in_person?.length > 0 && resultArray.push(pregnancy_services_in_person[0]);
    pregnancy_center_in_person?.length > 0 && resultArray.push(pregnancy_center_in_person[0]);
    homebirth_services_in_person?.length > 0 && resultArray.push(homebirth_services_in_person[0]);
    womens_wellness_services_in_person?.length > 0 &&
      resultArray.push(womens_wellness_services_in_person[0]);
    return resultArray;
  };
  const normalizeOnlinePrograms = values => {
    const {
      therapy_counseling_session_online,
      home_education_workshop_online,
      home_education_consultation_online,
      tutoring_online,
      enrichment_activities_online,
      coaching_online,
      online_program,
      general_studies_online,
      birthing_center_online,
      pregnancy_services_online,
      pregnancy_center_online,
      homebirth_services_online,
      womens_wellness_services_online,
    } = values;
    let resultArray = [];
    online_program?.length > 0 && resultArray.push(online_program[0]);
    therapy_counseling_session_online?.length > 0 &&
      resultArray.push(therapy_counseling_session_online[0]);
    home_education_workshop_online?.length > 0 &&
      resultArray.push(home_education_workshop_online[0]);
    home_education_consultation_online?.length > 0 &&
      resultArray.push(home_education_consultation_online[0]);
    tutoring_online?.length > 0 && resultArray.push(tutoring_online[0]);
    enrichment_activities_online?.length > 0 && resultArray.push(enrichment_activities_online[0]);
    coaching_online?.length > 0 && resultArray.push(coaching_online[0]);
    general_studies_online?.length > 0 && resultArray.push(general_studies_online[0]);
    birthing_center_online?.length > 0 && resultArray.push(birthing_center_online[0]);
    pregnancy_services_online?.length > 0 && resultArray.push(pregnancy_services_online[0]);
    pregnancy_center_online?.length > 0 && resultArray.push(pregnancy_center_online[0]);
    homebirth_services_online?.length > 0 && resultArray.push(homebirth_services_online[0]);
    womens_wellness_services_online?.length > 0 &&
      resultArray.push(womens_wellness_services_online[0]);
    return resultArray;
  };

  const onlineInitialValue = () => {
    const data = publicData?.one_on_one_online;
    let result = {};
    data?.map(
      i =>
        (result = {
          ...result,
          [i]: [i],
          [i + '_price']: publicData?.[`${i}_price`]
            ? new Money(publicData?.[`${i}_price`], config.currency)
            : null,
        })
    );
    return result;
  };
  const inPersonInitialValue = () => {
    const data = publicData?.one_on_one_in_person;
    let result = {};
    data?.map(
      i =>
        (result = {
          ...result,
          [i]: [i],
          [i + '_price']: publicData?.[`${i}_price`]
            ? new Money(publicData?.[`${i}_price`], config.currency)
            : null,
        })
    );
    return result;
  };

  const normalizeContactInitialValue = () => {
    if (!publicData?.contact_data) return {};
    const data = Object?.keys(publicData?.contact_data);
    let result = {};
    data?.map(
      i =>
        (result = {
          ...result,
          [i]: [i],
          [`${i}_type`]: config.custom.availabilityType?.find(
            k => k.value == publicData?.contact_data[i]
          ),
          [i + '_price']: publicData?.[`${i}_price`]
            ? new Money(publicData?.[`${i}_price`], config.currency)
            : null,
        })
    );
    return result;
  };

  const initialValues = React.useMemo(() => {
    if (!initialProps)
      return {
        ...inPersonInitialValue(),
        ...onlineInitialValue(),
        ...normalizeContactInitialValue(),
      };
    return {
      ...inPersonInitialValue(),
      ...onlineInitialValue(),
      ...normalizeContactInitialValue(),
      ...initialProps,
    };
  }, [initialProps]);
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingExperienceForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            tutoring_in_person,
            enrichment_activities_in_person,
            tutoring_in_person_price,
            enrichment_activities_in_person_price,
            therapy_counseling_session_in_person,
            therapy_counseling_session_in_person_price,
            home_education_workshop_in_person,
            home_education_workshop_in_person_price,
            home_education_consultation_in_person,
            home_education_consultation_in_person_price,
            sports_coaching_in_person,
            sports_coaching_in_person_price,
            coaching_in_person,
            coaching_in_person_price,
            therapy_counseling_session_online,
            home_education_workshop_online,
            home_education_consultation_online,
            tutoring_online,
            enrichment_activities_online,
            therapy_counseling_session_online_price,
            home_education_workshop_online_price,
            home_education_consultation_online_price,
            tutoring_online_price,
            enrichment_activities_online_price,
            parent_led_home_education_pod,
            home_education_pod,
            home_education_pod_trading_time,
            home_education_co_op,
            enrichment_learning_center,
            resource_center,
            charter_school,
            psp_satellite_school,
            record_keeping_services,
            hiring_an_educator,
            iep,
            doula_midwife_services,
            parent_led_home_education_pod_price,
            home_education_pod_price,
            home_education_pod_trading_time_price,
            home_education_co_op_price,
            enrichment_learning_center_price,
            resource_center_price,
            charter_school_price,
            psp_satellite_school_price,
            record_keeping_services_price,
            hiring_an_educator_price,
            iep_price,
            doula_midwife_services_price,
            parent_led_home_education_pod_type,
            home_education_pod_type,
            curriculum_materials_type,
            curriculum_materials_price,
            home_education_pod_trading_time_type,
            home_education_co_op_type,
            enrichment_learning_center_type,
            resource_center_type,
            charter_school_type,
            psp_satellite_school_type,
            record_keeping_services_type,
            academy,
            academy_price,
            academy_type,
            hiring_an_educator_type,
            iep_type,
            doula_midwife_services_type,
            coaching_online,
            coaching_online_price,
            curriculum_materials,
            microschool,
            microschool_price,
            microschool_type,
            online_program,
            online_program_price,
            online_program_type,
            travel_company,
            travel_company_price,
            travel_company_type,
            vacation_company,
            vacation_company_price,
            vacation_company_type,
            womens_wellness_services,
            womens_wellness_services_price,
            womens_wellness_services_type,

            homebirth_services,
            homebirth_services_price,
            homebirth_services_type,

            pregnancy_center,
            pregnancy_center_price,
            pregnancy_center_type,

            pregnancy_services,
            pregnancy_services_price,
            pregnancy_services_type,

            birthing_center,
            birthing_center_price,
            birthing_center_type,

            general_studies,
            general_studies_price,
            general_studies_type,

            general_studies_online,
            general_studies_online_price,
            birthing_center_online,
            birthing_center_online_price,
            pregnancy_services_online,
            pregnancy_services_online_price,
            pregnancy_center_online,
            pregnancy_center_online_price,
            homebirth_services_online,
            homebirth_services_online_price,
            womens_wellness_services_online,
            womens_wellness_services_online_price,

            general_studies_in_person,
            birthing_center_in_person,
            pregnancy_services_in_person,
            pregnancy_center_in_person,
            homebirth_services_in_person,
            womens_wellness_services_in_person,
            general_studies_in_person_price,
            birthing_center_in_person_price,
            pregnancy_services_in_person_price,
            pregnancy_center_in_person_price,
            homebirth_services_in_person_price,
            womens_wellness_services_in_person_price,
          } = values;
          // {
          //   label:'General Studies',
          //   key:'general_studies',

          // },{
          //   label:'Birthing Center',
          //   key:'birthing_center',
          // },{
          //   label:'Pregnancy Services',
          //   key:'pregnancy_services',
          // },{
          //   label:'Pregnancy Center',
          //   key:'pregnancy_center',
          // },{
          //   label:'Homebirth Services',
          //   key:'homebirth_services',
          // },{
          //   label:'Womens Wellness Services ',
          //   key:'womens_wellness_services',
          // }
          const updateValues = {
            publicData: {
              one_on_one_in_person: normalizePrograms(values),
              tutoring_in_person_price:
                tutoring_in_person?.length > 0 ? tutoring_in_person_price?.amount : null,
              general_studies_in_person_price:
                general_studies_in_person?.length > 0
                  ? general_studies_in_person_price?.amount
                  : null,
              birthing_center_in_person_price:
                birthing_center_in_person?.length > 0
                  ? birthing_center_in_person_price?.amount
                  : null,
              pregnancy_services_in_person_price:
                pregnancy_services_in_person?.length > 0
                  ? pregnancy_services_in_person_price?.amount
                  : null,
              pregnancy_center_in_person_price:
                pregnancy_center_in_person?.length > 0
                  ? pregnancy_center_in_person_price?.amount
                  : null,
              homebirth_services_in_person_price:
                homebirth_services_in_person?.length > 0
                  ? homebirth_services_in_person_price?.amount
                  : null,
              womens_wellness_services_in_person_price:
                womens_wellness_services_in_person?.length > 0
                  ? womens_wellness_services_in_person_price?.amount
                  : null,
              enrichment_activities_in_person_price:
                enrichment_activities_in_person?.length > 0
                  ? enrichment_activities_in_person_price?.amount
                  : null,
              therapy_counseling_session_in_person_price:
                therapy_counseling_session_in_person?.length > 0
                  ? therapy_counseling_session_in_person_price?.amount
                  : null,
              home_education_workshop_in_person_price:
                home_education_workshop_in_person?.length > 0
                  ? home_education_workshop_in_person_price?.amount
                  : null,
              home_education_consultation_in_person_price:
                home_education_consultation_in_person?.length > 0
                  ? home_education_consultation_in_person_price?.amount
                  : null,
              sports_coaching_in_person_price:
                sports_coaching_in_person?.length > 0
                  ? sports_coaching_in_person_price?.amount
                  : null,
              coaching_in_person_price:
                coaching_in_person?.length > 0 ? coaching_in_person_price?.amount : null,

              coaching_online_price:
                coaching_online?.length > 0 ? coaching_online_price?.amount : null,
              one_on_one_online: normalizeOnlinePrograms(values),
              therapy_counseling_session_online_price:
                therapy_counseling_session_online?.length > 0
                  ? therapy_counseling_session_online_price?.amount
                  : null,
              general_studies_online_price:
                general_studies_online?.length > 0 ? general_studies_online_price?.amount : null,
              birthing_center_online_price:
                birthing_center_online?.length > 0 ? birthing_center_online_price?.amount : null,
              pregnancy_services_online_price:
                pregnancy_services_online?.length > 0
                  ? pregnancy_services_online_price?.amount
                  : null,
              pregnancy_center_online_price:
                pregnancy_center_online?.length > 0 ? pregnancy_center_online_price?.amount : null,
              homebirth_services_online_price:
                homebirth_services_online?.length > 0
                  ? homebirth_services_online_price?.amount
                  : null,
              womens_wellness_services_online_price:
                womens_wellness_services_online?.length > 0
                  ? womens_wellness_services_online_price?.amount
                  : null,
              home_education_workshop_online_price:
                home_education_workshop_online?.length > 0
                  ? home_education_workshop_online_price?.amount
                  : null,
              home_education_consultation_online_price:
                home_education_consultation_online?.length > 0
                  ? home_education_consultation_online_price?.amount
                  : null,
              tutoring_online_price:
                tutoring_online?.length > 0 ? tutoring_online_price?.amount : null,
              enrichment_activities_online_price:
                enrichment_activities_online?.length > 0
                  ? enrichment_activities_online_price?.amount
                  : null,
              contact_data: normalizeContactData(values)?.result,
              contact_data_filter: normalizeContactData(values)?.contactData,
              parent_led_home_education_pod_price:
                parent_led_home_education_pod?.length > 0
                  ? parent_led_home_education_pod_price?.amount
                  : null,

              curriculum_materials_price:
                curriculum_materials?.length > 0 ? curriculum_materials_price?.amount : null,
              home_education_pod_price:
                home_education_pod?.length > 0 ? home_education_pod_price?.amount : null,
              home_education_pod_trading_time_price:
                home_education_pod_trading_time?.length > 0
                  ? home_education_pod_trading_time_price?.amount
                  : null,
              home_education_co_op_price:
                home_education_co_op?.length > 0 ? home_education_co_op_price?.amount : null,
              enrichment_learning_center_price:
                enrichment_learning_center?.length > 0
                  ? enrichment_learning_center_price?.amount
                  : null,
              online_program_price:
                online_program?.length > 0 ? online_program_price?.amount : null,
              travel_company_price:
                travel_company?.length > 0 ? travel_company_price?.amount : null,
              vacation_company_price:
                vacation_company?.length > 0 ? vacation_company_price?.amount : null,
              womens_wellness_services_price:
                womens_wellness_services?.length > 0
                  ? womens_wellness_services_price?.amount
                  : null,
              homebirth_services_price:
                homebirth_services?.length > 0 ? homebirth_services_price?.amount : null,
              pregnancy_center_price:
                pregnancy_center?.length > 0 ? pregnancy_center_price?.amount : null,
              pregnancy_services_price:
                pregnancy_services?.length > 0 ? pregnancy_services_price?.amount : null,
              birthing_center_price:
                birthing_center?.length > 0 ? birthing_center_price?.amount : null,
              //contact
              general_studies_price:
                general_studies?.length > 0 ? general_studies_price?.amount : null,
              resource_center_price:
                resource_center?.length > 0 ? resource_center_price?.amount : null,
              charter_school_price:
                charter_school?.length > 0 ? charter_school_price?.amount : null,
              psp_satellite_school_price:
                psp_satellite_school?.length > 0 ? psp_satellite_school_price?.amount : null,
              record_keeping_services_price:
                record_keeping_services?.length > 0 ? record_keeping_services_price?.amount : null,
              hiring_an_educator_price:
                hiring_an_educator?.length > 0 ? hiring_an_educator_price?.amount : null,
              iep_price: iep?.length > 0 ? iep_price?.amount : null,
              doula_midwife_services_price:
                doula_midwife_services?.length > 0 ? doula_midwife_services_price?.amount : null,
              academy_price: academy?.length > 0 ? academy_price?.amount : null,
              microschool_price: microschool?.length > 0 ? microschool_price?.amount : null,
            },
          };
          setInitialProps({
            ...updateValues.publicData,
            tutoring_in_person,
            enrichment_activities_in_person,
            tutoring_in_person_price,
            enrichment_activities_in_person_price,
            therapy_counseling_session_in_person,
            therapy_counseling_session_in_person_price,
            home_education_workshop_in_person,
            home_education_workshop_in_person_price,
            home_education_consultation_in_person,
            home_education_consultation_in_person_price,
            sports_coaching_in_person,
            sports_coaching_in_person_price,
            therapy_counseling_session_online,
            home_education_workshop_online,
            home_education_consultation_online,
            tutoring_online,
            enrichment_activities_online,
            therapy_counseling_session_online_price,
            home_education_workshop_online_price,
            home_education_consultation_online_price,
            tutoring_online_price,
            enrichment_activities_online_price,
            parent_led_home_education_pod,
            home_education_pod,
            home_education_pod_trading_time,
            home_education_co_op,
            enrichment_learning_center,
            microschool,
            resource_center,
            charter_school,
            psp_satellite_school,
            record_keeping_services,
            hiring_an_educator,
            curriculum_materials,
            curriculum_materials_type,
            curriculum_materials_price,
            iep,
            doula_midwife_services,
            parent_led_home_education_pod_price,
            home_education_pod_price,
            home_education_pod_trading_time_price,
            home_education_co_op_price,
            enrichment_learning_center_price,
            microschool_price,
            resource_center_price,
            charter_school_price,
            psp_satellite_school_price,
            record_keeping_services_price,
            hiring_an_educator_price,
            iep_price,
            doula_midwife_services_price,
            parent_led_home_education_pod_type,
            home_education_pod_type,
            home_education_pod_trading_time_type,
            home_education_co_op_type,
            enrichment_learning_center_type,
            microschool_type,
            resource_center_type,
            online_program,
            online_program_price,
            online_program_type,
            charter_school_type,
            psp_satellite_school_type,
            record_keeping_services_type,
            hiring_an_educator_type,
            iep_type,
            doula_midwife_services_type,
            academy,
            academy_price,
            academy_type,
            coaching_in_person,
            coaching_in_person_price,
            coaching_online,
            coaching_online_price,
            travel_company,
            travel_company_price,
            travel_company_type,
            vacation_company,
            vacation_company_price,
            vacation_company_type,
            womens_wellness_services,
            womens_wellness_services_price,
            womens_wellness_services_type,
            homebirth_services,
            homebirth_services_price,
            homebirth_services_type,
            pregnancy_center,
            pregnancy_center_price,
            pregnancy_center_type,
            pregnancy_services,
            pregnancy_services_price,
            pregnancy_services_type,
            birthing_center,
            birthing_center_price,
            birthing_center_type,
            general_studies,
            general_studies_price,
            general_studies_type,
            general_studies_online,
            general_studies_online_price,
            birthing_center_online,
            birthing_center_online_price,
            pregnancy_services_online,
            pregnancy_services_online_price,
            pregnancy_center_online,
            pregnancy_center_online_price,
            homebirth_services_online,
            homebirth_services_online_price,
            womens_wellness_services_online,
            womens_wellness_services_online_price,
            general_studies_in_person,
            birthing_center_in_person,
            pregnancy_services_in_person,
            pregnancy_center_in_person,
            homebirth_services_in_person,
            womens_wellness_services_in_person,
            general_studies_in_person_price,
            birthing_center_in_person_price,
            pregnancy_services_in_person_price,
            pregnancy_center_in_person_price,
            homebirth_services_in_person_price,
            womens_wellness_services_in_person_price,
          });
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingExperiencePanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingExperiencePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingExperiencePanel;
