import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';
import config from '../../config';
import { PropertyGroup } from '../../components';
import { IoIosCheckmarkCircle } from 'react-icons/io';
const SectionTeacherDetails = props => {
  const { publicData, metadata } = props;
  let {
    teaching_experience,
    grade_span,
    teaching_style,
    enrichment_activities,
    sports_coaching,
    other_teaching_style,
    category,
    foreign_languages,
  } = publicData;

  let teachingStyle = teaching_style?.includes('other')
    ? [...teaching_style, other_teaching_style]?.filter(item => item !== 'other')
    : teaching_style;
  let teachingStyleConfig = other_teaching_style
    ? [
        ...config.custom.teachingStyles,
        { key: other_teaching_style, value: other_teaching_style, label: other_teaching_style },
      ]
    : config.custom.teachingStyles;
  ////////////section background experience

  let selectedExperienceConfigOptions = config.custom.backgroundOptions?.filter(o =>
    teaching_experience?.find(s => s === o.key)
  );
  if (metadata?.verified) {
    teaching_experience?.push('verified');
    selectedExperienceConfigOptions.push({
      key: 'verified',
      value: 'verified',
      label: 'Background check verified',
    });
  }
  const experienceSection =
    teaching_experience?.length > 0 ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.backgroundExperienceTitle" />
          {metadata?.verified ? (
            <span title="Background Verified">
              <IoIosCheckmarkCircle className={css.validateLogo} />
            </span>
          ) : null}
        </h2>
        <PropertyGroup
          id="ListingPage.backgroundExperience"
          options={selectedExperienceConfigOptions}
          selectedOptions={teaching_experience}
          twoColumns={selectedExperienceConfigOptions.length > 1}
        />
      </div>
    ) : null;
  const selectedLanguages = config.custom.foreignLanguageOptions?.filter(o =>
    foreign_languages?.find(s => s === o.key)
  );
  const languageSection =
    foreign_languages?.length > 0 ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.languageTitle" />
        </h2>
        <PropertyGroup
          id="ListingPage.language"
          options={selectedLanguages}
          selectedOptions={foreign_languages}
          twoColumns={selectedLanguages.length > 1}
        />
      </div>
    ) : null;
  /////////////////////////////////////////
  ////////////section grade span
  const selectedGradeSpanConfigOptions = config.custom.gradeSpanOptions?.filter(o =>
    grade_span?.find(s => s === o.key)
  );
  const gradeSpanSection =
    grade_span?.length > 0 ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.gradeSpanTitle" />
        </h2>
        <PropertyGroup
          id="ListingPage.gradeSpan"
          options={selectedGradeSpanConfigOptions}
          selectedOptions={grade_span}
          twoColumns={selectedGradeSpanConfigOptions.length > 1}
        />
      </div>
    ) : null;
  /////////////////////////////////////////
  ////////////section teaching_style
  const selectedTeachingStyleConfigOptions = teachingStyleConfig?.filter(o =>
    teachingStyle?.find(s => s === o.key)
  );

  const teachingStyleSection =
    teaching_style?.length > 0 ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          {category === 'parent' ? (
            <FormattedMessage id="ListingPage.desiredTeachingStyleTitle" />
          ) : (
            <FormattedMessage id="ListingPage.teachingStyleTitle" />
          )}
        </h2>
        <PropertyGroup
          id="ListingPage.teachingStyle"
          options={selectedTeachingStyleConfigOptions}
          selectedOptions={teachingStyle}
          twoColumns={selectedTeachingStyleConfigOptions.length > 1}
        />
      </div>
    ) : null;
  /////////////////////////////////////////
  ////////////section enrichment_activities
  const selectedEnrichmentActivitiesConfigOptions = config.custom.enrichmentActivities?.filter(o =>
    enrichment_activities?.find(s => s === o.key)
  );
  const enrichmentActivtiesSection =
    enrichment_activities?.length > 0 ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.enrichmentActivtiesTitle" />
        </h2>
        <PropertyGroup
          id="ListingPage.enrichmentActivties"
          options={selectedEnrichmentActivitiesConfigOptions}
          selectedOptions={enrichment_activities}
          twoColumns={selectedEnrichmentActivitiesConfigOptions.length > 1}
        />
      </div>
    ) : null;
  /////////////////////////////////////////
  ////////////section sports_coaching
  const selectedSportsCoachingConfigOptions = config.custom.sportsCoaching?.filter(o =>
    sports_coaching?.find(s => s === o.key)
  );
  const sportsCoachingSection =
    sports_coaching?.length > 0 ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.sportsCoachingTitle" />
        </h2>
        <PropertyGroup
          id="ListingPage.sportsCoaching"
          options={selectedSportsCoachingConfigOptions}
          selectedOptions={sports_coaching}
          twoColumns={selectedSportsCoachingConfigOptions.length > 1}
        />
      </div>
    ) : null;
  /////////////////////////////////////////
  return (
    <>
      {experienceSection}
      {gradeSpanSection}
      {teachingStyleSection}
      {enrichmentActivtiesSection}
      {languageSection}
      {sportsCoachingSection}
    </>
  );
};

export default SectionTeacherDetails;
