import React from 'react';
import { FieldCheckbox, FieldCurrencyInput, FieldSelectModern } from '../../components';
import { requiredFieldArrayCheckbox } from '../../util/validators';
import config from '../../config';
import { injectIntl } from 'react-intl';
function ProgramOfferedBooking(props) {
  const { values, style, label, id, priceValidators, intl } = props;
  const pricePlaceholderMessage = intl.formatMessage({
    id: 'EditListingExperienceForm.pricePlaceholder',
  });
  return (
    <div>
      <FieldCheckbox id={id} name={id} label={label} value={id} />
      {values?.[id]?.length > 0 && (
        <div>
          <FieldCurrencyInput
            id={`${id}_price`}
            name={`${id}_price`}
            className={style}
            label="Price"
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />
        </div>
      )}
    </div>
  );
}

export default injectIntl(ProgramOfferedBooking);
