import React from 'react';
import css from './RequiredHeadline.module.css';
function RequiredHeadline(props) {
  const { label, message } = props;
  return (
    <div className={css.labelText}>
      {label}
      <span className={css.message}>{`(${message})`}</span>
    </div>
  );
}

export default RequiredHeadline;
