import defaultMessages from '../translations/en.json';

const initialState = {
  messages: defaultMessages,
  loaded: false,
};

export default function reducer(state = initialState) {
  return state;
}
