import React from 'react';
import { FieldCheckbox, FieldCurrencyInput, FieldSelectModern } from '../../components';
import { requiredFieldArrayCheckbox } from '../../util/validators';
import config from '../../config';
function ProgramOfferedContact(props) {
  const { values, style, label, id, priceValidators } = props;
  return (
    <div>
      <FieldCheckbox id={id} name={id} label={label} value={id} />
      {values?.[id]?.length > 0 && (
        <div>
          <FieldSelectModern
            id={`${id}_type`}
            name={`${id}_type`}
            label="Type"
            className={style}
            options={config.custom.availabilityType}
            validate={requiredFieldArrayCheckbox(`${label} Type Required`)}
          />
          <FieldCurrencyInput
            id={`${id}_price`}
            name={`${id}_price`}
            className={style}
            label="Price"
            placeholder="Choose a price"
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />
        </div>
      )}
    </div>
  );
}

export default ProgramOfferedContact;
