import React, { Component, useEffect } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { nonEmptyArray, composeValidators } from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { AddImages, Button, Form, PhotoUploadWithCrop, ValidationError } from '../../components';
import config from '../../config';

import css from './EditListingPhotosForm.module.css';

const ACCEPT_IMAGES = 'image/*';

export class EditListingPhotosFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { imageUploadRequested: false, imageError: false };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
  }

  onImageUploadHandler(file, isLogo = false, isBanner = false) {
    if (file) {
      this.setState({ imageUploadRequested: true });
      this.props
        .onImageUpload({
          id: `${file.name}_${Date.now()}`,
          file,
          isLogoUpload: isLogo,
          isBannerUpload: isBanner,
        })
        .then(() => {
          this.setState({ imageUploadRequested: false });
        })
        .catch(() => {
          this.setState({ imageUploadRequested: false });
        });
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        // initialValues={{ images: this.props.images }}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            images,
            imageUploadRequested,
            intl,
            invalid,
            onImageUploadHandler,
            onRemoveImage,
            disabled,
            ready,
            saveActionMsg,
            updated,
            logo,
            banner,
            values,
            updateInProgress,
            onManageDisableScrolling,
          } = formRenderProps;

          const chooseImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseImage" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypes" />
              </span>
            </span>
          );

          const imageRequiredMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.imageRequired',
          });
          const tournamentLogoMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.tournamentLogoMessage',
          });
          const otherTournamentMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.otherTournamentMessage',
          });
          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
            </p>
          ) : null;

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds([...images, ...logo]);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;
          const submitReady = (updated && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;
          const validateBanner = values?.bannerImage || banner?.length > 0;
          const submitDisabled =
            invalid ||
            disabled ||
            submitInProgress ||
            imageUploadRequested ||
            ready ||
            !validateBanner;
          const validateImg = file => {
            let img = new Image();
            img.src = window.URL.createObjectURL(file);
            return new Promise((resolve, reject) => {
              img.onload = () => {
                const isValid = img.height - img.width;
                console.log(Math.abs(isValid));
                if (Math.abs(isValid) > 200) {
                  this.setState({ imageError: true });
                  return resolve(false);
                } else {
                  this.setState({ imageError: false });
                  return resolve(true);
                }
              };
            });
          };
          const classes = classNames(css.root, className);
          // console.log('test', this.submittedImages);
          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedImages = [...images, ...logo];
                handleSubmit(e);
              }}
            >
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                </p>
              ) : null}
              {/* logo */}
              <p className={css.bannerText}>
                <FormattedMessage id="EditListingPhotosForm.featuredPhoto" />
              </p>
              <PhotoUploadWithCrop
                form={form}
                fetchErrors={fetchErrors}
                className={className}
                images={logo}
                imageUploadRequested={imageUploadRequested}
                intl={intl}
                onImageUploadHandler={onImageUploadHandler}
                onRemoveImage={onRemoveImage}
                updateInProgress={updateInProgress}
                onManageDisableScrolling={onManageDisableScrolling}
                isLogo={true}
                aspectRatio={4 / 3}
                isBanner={false}
                id="logo"
              />
              {uploadImageFailed}
              {this.state.imageError && (
                <p className={css.errorMessage}>
                  <FormattedMessage id="EditListingPhotosForm.errorMessage" />
                </p>
              )}

              <p className={css.bannerText}>Banner Image</p>
              <PhotoUploadWithCrop
                form={form}
                fetchErrors={fetchErrors}
                className={className}
                images={banner}
                imageUploadRequested={imageUploadRequested}
                intl={intl}
                onImageUploadHandler={onImageUploadHandler}
                onRemoveImage={onRemoveImage}
                aspectRatio={6 / 2}
                updateInProgress={updateInProgress}
                onManageDisableScrolling={onManageDisableScrolling}
                isLogo={false}
                isBanner={true}
                id="banner"
              />
              <p className={css.bannerText}>OR</p>
              <div className={css.bannerContainer}>
                {values?.bannerImage ? (
                  <div>
                    <p className={css.bannerText}>
                      <FormattedMessage id="EditListingPhotosForm.bannerPhoto" />
                    </p>
                    <img src={values.bannerImage.Image} alt="banner" className={css.banner} />
                  </div>
                ) : null}
              </div>
              <p className={css.bannerText}>
                <FormattedMessage id="EditListingPhotosForm.selectBannerPhoto" />
              </p>

              <div className={css.bannerContainer}>
                {config.custom.bannerImages?.map(image => (
                  <div className={css.image}>
                    <img
                      src={image.Image}
                      alt="banner"
                      className={values?.bannerImage?.id === image.id ? css.banner : css.banner}
                      onClick={e => form.change('bannerImage', image)}
                    />
                  </div>
                ))}
              </div>
              <p className={css.tip}>
                <FormattedMessage id="EditListingPhotosForm.addImagesTip" />
              </p>
              <br />
              <p className={css.bannerText}>
                <FormattedMessage id="EditListingPhotosForm.otherPhotos" />
              </p>
              <AddImages
                className={css.imagesField}
                images={images}
                thumbnailClassName={css.thumbnail}
                savedImageAltText={intl.formatMessage({
                  id: 'EditListingPhotosForm.savedImageAltText',
                })}
                onRemoveImage={onRemoveImage}
              >
                <Field
                  id="addImage"
                  name="addImage"
                  accept={ACCEPT_IMAGES}
                  form={null}
                  label={chooseImageText}
                  type="file"
                  disabled={imageUploadRequested}
                >
                  {fieldprops => {
                    const { accept, input, label, disabled: fieldDisabled } = fieldprops;

                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`addImage`, file);
                      form.blur(`addImage`);
                      onImageUploadHandler(file);
                    };
                    const inputProps = { accept, id: name, name, onChange, type };
                    return (
                      <div className={css.addImageWrapper}>
                        <div className={css.aspectRatioWrapper}>
                          {fieldDisabled ? null : (
                            <input {...inputProps} className={css.addImageInput} />
                          )}
                          <label htmlFor={name} className={css.addImage}>
                            {label}
                          </label>
                        </div>
                      </div>
                    );
                  }}
                </Field>

                <Field
                  component={props => {
                    const { input, meta } = props;
                    return (
                      <div className={css.imageRequiredWrapper}>
                        <input {...input} />
                        <ValidationError fieldMeta={meta} />
                      </div>
                    );
                  }}
                  name="images"
                  type="hidden"
                  // validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                />
              </AddImages>
              {uploadImageFailed}
              {/* <div className={css.bannerContainer}>
                {values?.bannerImage ? (
                  <div>
                    <p className={css.bannerText}>
                      <FormattedMessage id="EditListingPhotosForm.bannerPhoto" />
                    </p>
                    <img src={values.bannerImage.Image} alt="banner" className={css.banner} />
                  </div>
                ) : null}
              </div>
              <p className={css.bannerText}>
                <FormattedMessage id="EditListingPhotosForm.selectBannerPhoto" />
              </p>

              <div className={css.bannerContainer}>
                {config.custom.bannerImages?.map(image => (
                  <div className={css.image}>
                    <img
                      src={image.Image}
                      alt="banner"
                      className={values?.bannerImage?.id === image.id ? css.banner : css.banner}
                      onClick={e => form.change('bannerImage', image)}
                    />
                  </div>
                ))}
              </div> */}
              {/* <p className={css.tip}>
                <FormattedMessage id="EditListingPhotosForm.addImagesTip" />
              </p> */}
              {publishListingFailed}
              {showListingFailed}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null, images: [], logo: [] };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  logo: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
