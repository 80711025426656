import React, { useState } from 'react';
import { compose } from 'redux';
import { string, shape } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';

import { HeroForm } from '../../forms';
import classNames from 'classnames';
import { NamedLink, TypeButtons } from '../../components';
import { withViewport } from '../../util/contextHelpers';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import { SiGoogleclassroom } from 'react-icons/si';
import { MdOutlineEmojiPeople } from 'react-icons/md';
import { GiTeacher } from 'react-icons/gi';
import { FaUserFriends } from 'react-icons/fa';
import css from './SectionHero.module.css';
const MAX_MOBILE_SCREEN_WIDTH = 768;

const SectionHeroComponent = props => {
  const {
    rootClassName,
    className,
    currentSearchParams,
    history,
    location,
    viewport,
    introId,
    intl,
  } = props;
  const [typeParam, setTypeParam] = useState('teacher');
  const [queryTypeA, setQueryTypeA] = useState('');
  const [queryTypeB, setQueryTypeB] = useState('');
  const [queryTypeC, setQueryTypeC] = useState('');
  const [clickedBtn, setClickedBtn] = useState(false);
  const [clickedBtnB, setClickedBtnB] = useState(false);
  const [clickedBtnC, setClickedBtnC] = useState(false);
  const [clickedBtnD, setClickedBtnD] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const classes = classNames(rootClassName || css.root, className);
  const { address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // Submit handler that is used once user
  // selects the location from the prediction
  // box
  const handleSubmit = values => {
    const { search, selectedPlace } = values.location;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    let decodedA = decodeURIComponent(queryTypeA);
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds,
      pub_category: typeParam ? typeParam : undefined,
      pub_program_offered: queryTypeC || undefined,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  const locationFieldsPresent = config.sortSearchByDistance
    ? address && origin && bounds
    : address && bounds;
  const initialSearchFormValues = {
    location: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
      : null,
  };
  const search = (
    <HeroForm
      className={css.searchLink}
      onSubmit={handleSubmit}
      initialValues={initialSearchFormValues}
      showSearch={showSearch}
      isLocalFriend={typeParam === 'meet_up'}
    />
  );

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const heroTitleBreak = isMobileLayout ? ' ' : <br />;
  const locationLink = (name, searchQuery) => {
    const nameText = <span className={css.locationName}>{name}</span>;
    return (
      <NamedLink
        name="SearchPage"
        to={{ search: searchQuery }}
        className={css.location}
        style={{ textDecoration: 'none' }}
      >
        <span>{name}</span>
      </NamedLink>
    );
  };
  const checkClickedBtn = id => {
    if (id === '1') {
      setClickedBtn(true);
      setClickedBtnB(false);
      setClickedBtnC(false);
      setClickedBtnD(false);
    } else if (id === '2') {
      setClickedBtnB(true);
      setClickedBtn(false);
      setClickedBtnC(false);
      setClickedBtnD(false);
    } else if (id === '3') {
      setClickedBtnC(true);
      setClickedBtn(false);
      setClickedBtnB(false);
      setClickedBtnD(false);
    } else if (id === '4') {
      setClickedBtnD(true);
      setClickedBtn(false);
      setClickedBtnB(false);
      setClickedBtnC(false);
    }
  };
  const setFocus = async () => {
    await setShowSearch(true);
    document?.getElementById('search-location')?.focus();
  };
  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" values={{ break: heroTitleBreak }} />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        {/* <h2 className={css.heroSubTitleSecond}>
          <FormattedMessage id="SectionHero.subTitleSecond" />
        </h2> */}
        <div className={css.searchLinks}>
          <div className={`${css.childrenDivs}`}>
            <button
              className={css.btnLoc}
              style={
                clickedBtn
                  ? {
                      backgroundColor: '#9bbac4',
                      opacity: 0.9,
                      border: '4px solid #fff',
                    }
                  : {
                      backgroundColor: '#9bbac4',
                      opacity: 0.9,
                    }
              }
              onClick={() => {
                checkClickedBtn('1');
                history.push({
                  pathname: '/s',
                  search:
                    '?pub_one_on_one_online=has_any%3Atherapy_counseling_session_online%2Chome_education_workshop_online%2Chome_education_consultation_online%2Ctutoring_online%2Cenrichment_activities_online%2Ccoaching_online%2Crecord_keeping_services_online%2Cdoula_midwife_services_online%2Ciep_services_online',
                });
              }}
            >
              {/* <SiGoogleclassroom className={css.reactIcon} /> */}
              <span className={css.location}>
                <FormattedMessage id="SectionHero.NavBtnOnlineClasses" />
              </span>
            </button>
          </div>
          <div className={`${css.childrenDivs}`}>
            <button
              className={css.btnLoc}
              style={
                clickedBtn
                  ? {
                      backgroundColor: '#8d93c2',
                      opacity: 0.9,
                      border: '4px solid #fff',
                    }
                  : {
                      backgroundColor: '#8d93c2',
                      opacity: 0.9,
                    }
              }
              onClick={() => {
                checkClickedBtn('1');
                history.push({
                  pathname: '/s',
                  search: '?pub_category=experience',
                });
              }}
            >
              {/* <SiGoogleclassroom className={css.reactIcon} /> */}
              <span className={css.location}>
                Experiences
                {/* <FormattedMessage id="SectionHero.SectionHero.experienceLabel" /> */}
              </span>
            </button>
          </div>
          <div className={`${css.childrenDivs}`}>
            <button
              className={css.btnLoc}
              style={
                clickedBtn
                  ? {
                      backgroundColor: '#466e7a',
                      opacity: 0.9,
                      border: '4px solid #fff',
                    }
                  : {
                      backgroundColor: '#466e7a',
                      opacity: 0.9,
                    }
              }
              onClick={() => {
                checkClickedBtn('1');
                history.push({
                  pathname: '/s',
                  search: '?pub_category=curriculum',
                });
              }}
            >
              <span className={css.location}>
                <FormattedMessage id="SectionHero.curriculumMaterials" />
              </span>
            </button>
          </div>
          <div className={`${css.childrenDivs} ${css.anotherTwo}`}>
            <button
              className={css.btnLoc}
              style={
                clickedBtnB
                  ? {
                      backgroundColor: '#83b8aa',
                      opacity: 0.9,
                      border: '4px solid #fff',
                    }
                  : { backgroundColor: '#83b8aa', opacity: 0.9 }
              }
              onClick={() => {
                checkClickedBtn('2');
                setFocus();
                setQueryTypeC('has_any:homeschool_co_op');
              }}
            >
              {/* <MdOutlineEmojiPeople className={css.reactIcon} /> */}
              <span className={css.location}>
                <FormattedMessage id="SectionHero.NavBtnCoOps" />
              </span>
            </button>
          </div>
          <div className={`${css.childrenDivs} ${css.anotherTwo}`}>
            <button
              className={css.btnLoc}
              style={
                clickedBtnC
                  ? {
                      backgroundColor: '#7397cc',
                      opacity: 0.9,
                      border: '4px solid #fff',
                    }
                  : { backgroundColor: '#7397cc', opacity: 0.9 }
              }
              onClick={() => {
                checkClickedBtn('3');
                setFocus();
                setTypeParam('teacher');
              }}
            >
              {/* <GiTeacher className={css.reactIcon} /> */}
              <span className={css.location}>
                <FormattedMessage id="SectionHero.NavBtnLocalEducators" />
              </span>
            </button>
          </div>
          <div className={`${css.childrenDivs} ${css.anotherTwo}`}>
            <button
              className={css.btnLoc}
              style={
                clickedBtnD
                  ? {
                      backgroundColor: '#b1c3cc',
                      opacity: 0.9,
                      border: '4px solid #fff',
                    }
                  : { backgroundColor: '#b1c3cc', opacity: 0.9 }
              }
              onClick={async () => {
                checkClickedBtn('4');
                setFocus();

                setTypeParam('meet_up');
              }}
            >
              {/* <FaUserFriends className={css.reactIcon} /> */}
              <span className={css.location}>
                <FormattedMessage id="SectionHero.NavBtnLocalFriend" />
              </span>
            </button>
          </div>
        </div>
        <div className={css.search}>{search}</div>
      </div>
    </div>
  );
};

SectionHeroComponent.defaultProps = { rootClassName: null, className: null, introId: null };

SectionHeroComponent.propTypes = {
  rootClassName: string,
  className: string,
  // These are passed from Page to keep SectionHero rendering aware of location changes
  location: shape({
    search: string.isRequired,
  }).isRequired,
};
const SectionHero = compose(withViewport)(SectionHeroComponent);

export default SectionHero;
