import React from 'react';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, injectIntl } from 'react-intl';
const { Money } = sdkTypes;
import css from './ListingPage.module.css';

function SectionOneOnOneOnline(props) {
  const { publicData, intl } = props;
  const { one_on_one_online } = publicData;
  const options = one_on_one_online
    ?.filter(k => k !== 'online_program')
    ?.map(item => ({
      ...config.custom.oneInOneOnline?.find(o => o.key === item),
      price: publicData[`${item}_price`],
    }));
  return one_on_one_online?.length > 0 ? (
    <div className={css.rootContainer}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.oneInOneOnlineTitle" />
      </h2>
      {options.map((item, index) => (
        <div className={css.oneInOneInPersonContainer} key={index}>
          <span>{item?.label}</span>
          <span className={css.price}>
            {formatMoney(intl, new Money(item?.price, config.currency))}
          </span>
        </div>
      ))}
    </div>
  ) : null;
}

export default injectIntl(SectionOneOnOneOnline);
