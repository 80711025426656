import React, { Component, useEffect } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { timestampToDate } from '../../../util/dates';
import { propTypes } from '../../../util/types';
import config from '../../../config';
import { Form, IconSpinner, PrimaryButton, FieldSelectModern } from '../../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
// import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import axios from 'axios';
import css from './BookingTimeForm.module.css';
import moment from 'moment';
import DiscountInput from '../../../forms/BookingTimeForm/DiscountInput';
import { fieldSelectModernRequired } from '../../../util/validators';
export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applied: false,
      loading: false,
      discountSuccess: null,
      discountFailure: null,
      coupon: '',
      size: '',
      color: '',
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit({
      ...e,
      coupon: this.state.coupon,
      size: this.state.size,
      color: this.state.color,
    });
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange() {
    const { publicData } = this.props;

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    // const isSameTime = bookingStartTime === bookingEndTime;

    const couponMaybe =
      this.state.coupon && this.state.applied ? { coupon: this.state.coupon } : {};
    const sizeMaybe = this.state.size ? { size: this.state.size } : '';
    const colorMaybe = this.state.color ? { color: this.state.color } : '';
    if (!this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData: {
          ...couponMaybe,
          category: publicData?.category,
          ...sizeMaybe,
          ...colorMaybe,
        },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const { applied, discountFailure, discountSuccess, loading, coupon, size, color } = this.state;
    const updateState = newState => this.setState(newState);
    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        applied={applied}
        failure={discountFailure}
        success={discountSuccess}
        loading={loading}
        coupon={coupon}
        size={size}
        color={color}
        updateState={updateState}
        handleOnChange={this.handleOnChange}
        render={fieldRenderProps => {
          const {
            applied,
            failure,
            success,
            loading,
            publicData,
            endDatePlaceholder,
            startDatePlaceholder,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            handleOnChange,
            form,
          } = fieldRenderProps;
          if (typeof window === 'undefined') {
            return null;
          }
          const sizeData = publicData?.size?.map(item =>
            config.custom.sizes?.find(item1 => item1.key == item)
          );
          const colorData = publicData?.color?.map(item =>
            config.custom.colors?.find(item1 => item1.key == item)
          );

          useEffect(() => {
            if (applied) {
              updateState({ applied: false, discountSuccess: null, discountFailure: null });
            }
          }, [coupon]);

          useEffect(() => {
            if (values.service) {
              updateState({
                coupon: '',
                applied: false,
                discountSuccess: null,
                discountFailure: null,
              });
            }
          }, [values.service]);
          useEffect(() => {
            this.handleOnChange();
          }, []);
          const isFreeType = publicData.priceType == 'free';

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const startDate = moment().unix();
          const endDate = moment(startDate)
            .add(1, 'hours')
            .unix();

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  //   timeZone,
                  showDate: false,
                }
              : null;

          const showEstimatedBreakdown = bookingData && lineItems;

          const onValidateDiscount = async () => {
            updateState({
              applied: false,
              loading: true,
              discountSuccess: null,
              discountFailure: null,
            });
            try {
              const response = await axios.post('/booking-discount', { code: coupon });
              // formApi.change('coupon', coupon);
              updateState({ discountSuccess: response.data.message });
            } catch (e) {
              const err = e?.response?.data?.message || e.message;
              updateState({ discountFailure: err });
            } finally {
              updateState({ loading: false, applied: true });
              handleOnChange({ values: values });
            }
          };
          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const submitDisabled = loading;

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />

              <div className={css.formRow}>
                <div className={classNames(css.field, css.startDate)}>
                  <DiscountInput
                    loading={loading}
                    onValidateDiscount={onValidateDiscount}
                    success={success}
                    failure={failure}
                    onChange={e => updateState({ coupon: e.target.value })}
                    value={coupon}
                  />
                </div>
              </div>
              {publicData?.color && publicData?.size ? (
                <div style={{ marginBottom: '1rem', marginTop: '10px' }}>
                  <FieldSelectModern
                    id="sizes"
                    name="sizes"
                    onChange={e => updateState({ size: e.label })}
                    label="Size"
                    value={size}
                    placeholder={size ? size : 'Select a size'}
                    options={sizeData}
                  />
                  <div style={{ marginBottom: '12px' }}></div>
                  <FieldSelectModern
                    id="colors"
                    name="colors"
                    onChange={e => updateState({ color: e.label })}
                    label="Color"
                    value={color}
                    placeholder={color ? color : 'Select a color'}
                    options={colorData}
                  />
                </div>
              ) : (
                ''
              )}
              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              {!isFreeType && (
                <p className={css.smallPrint}>
                  <FormattedMessage
                    id={
                      isOwnListing
                        ? 'BookingTimeForm.ownListing'
                        : 'BookingTimeForm.youWontBeChargedInfo'
                    }
                  />
                </p>
              )}
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" disabled={submitDisabled}>
                  <FormattedMessage id="BookingTimeForm.requestToBookCurriculum" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
