import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';
import config from '../../config';
import { PropertyGroup } from '../../components';
import SectionOneOnOneInPerson from './SectionOneOnOneInPerson';
import SectionOneOnOneOnline from './SectionOneOnOneOnline';
import SectionContactOptions from './SectionContactOptions';
const SectionProgramOffered = props => {
  const { publicData } = props;

  const {
    program_offered,
    desired_program,
    category,
    one_on_one_in_person,
    one_on_one_online,
    contact_data,
    intl,
    tutoring_options,
  } = publicData;
  const selectedProgramDesired = config.custom.desiredProgram?.filter(o =>
    desired_program?.find(s => s === o.key)
  );
  const selectedTutoringOptions = config.custom.tutoringOptions?.filter(o =>
    tutoring_options?.find(s => s === o.key)
  );
  const contactOptions = contact_data ? Object.keys(contact_data) : [];
  const selectedProgramOffered = config.custom.programOffered?.filter(o =>
    program_offered?.find(s => s === o.key)
  );
  const desiredProgramSection =
    desired_program?.length > 0 && category == 'parent' ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.desiredProgramTitle" />
        </h2>
        <PropertyGroup
          id="ListingPage.desiredProgram"
          options={selectedProgramDesired}
          selectedOptions={desired_program}
          twoColumns={selectedProgramDesired.length > 1}
        />
      </div>
    ) : null;
  const sectionTutoringOptions =
    tutoring_options?.length > 0 ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.tutoringOptionsTitle" />
        </h2>
        <PropertyGroup
          id="ListingPage.tutoringOptions"
          options={selectedTutoringOptions}
          selectedOptions={tutoring_options}
          twoColumns={selectedTutoringOptions.length > 1}
        />
      </div>
    ) : null;

  return (
    <>
      {sectionTutoringOptions}

      <SectionOneOnOneInPerson publicData={publicData} intl={intl} />
      <SectionOneOnOneOnline publicData={publicData} intl={intl} />
      {contact_data && <SectionContactOptions publicData={publicData} intl={intl} />}
      {desiredProgramSection}
    </>
  );
};

export default SectionProgramOffered;
