import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  maxLength,
  required,
  composeValidators,
  fieldSelectModernRequired,
  moneySubUnitAmountAtLeast,
  minimumPriceOnlineValidate,
  urlValidate,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldSelectModern,
  FieldCurrencyInput,
  FieldTextInput,
  FieldCheckboxGroup,
} from '../../components';
import css from './EditListingCurriculumDetailsForm.module.css';
import arrayMutators from 'final-form-arrays';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import Material from './Material';
const { Money } = sdkTypes;
const TITLE_MAX_LENGTH = 60;

const EditListingCurriculumDetailsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
        listing,
      } = formRenderProps;
      const [checkPrice, setCheckPrice] = useState(false);
      function maximumPriceValidate(price, type, index) {
        if (price >= 1000 && type?.value == 'online') {
          setCheckPrice(false);
        } else if (type?.value == 'online') {
          setIndexValue(index);
          setCheckPrice(true);
        } else {
          setCheckPrice(false);
        }
      }

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCurriculumDetailsForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCurriculumDetailsForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCurriculumDetailsForm.showListingFailed" />
        </p>
      ) : null;
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const priceRequiredMessage = intl.formatMessage({
        id: 'EditListingExperienceForm.priceRequired',
      });
      const priceRequired = required(priceRequiredMessage);
      const minPriceRequired = moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const priceValidators = config.listingMinimumPriceSubUnits
        ? composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      console.log('vals ->', values);
      const curType =
        values?.curriculum_resource_type?.find(l => l.value === 'apparel')?.value === 'apparel';
      const curFile = values?.curriculum_file_format?.value === 'physical';
      console.log('cur', curType, curFile);
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldCheckboxGroup
            className={css.title}
            id="curriculum_grade_span"
            name="curriculum_grade_span"
            label={<FormattedMessage id="EditListingCurriculumDetailsForm.curriculumGradeSpan" />}
            placeholder={
              <FormattedMessage id="EditListingCurriculumDetailsForm.curriculumGradeSpanPlaceholder" />
            }
            options={config.custom.curriculumGradeSpan}
            validate={fieldSelectModernRequired(
              <FormattedMessage id="EditListingCurriculumDetailsForm.curriculumGradeSpanRequired" />
            )}
            twoColumns={true}
          />
          <FieldSelectModern
            className={css.title}
            id="curriculum_style"
            name="curriculum_style"
            label={<FormattedMessage id="EditListingCurriculumDetailsForm.curriculumStyle" />}
            placeholder={
              <FormattedMessage id="EditListingCurriculumDetailsForm.curriculumStylePlaceholder" />
            }
            options={config.custom.curriculumStyle}
            validate={fieldSelectModernRequired(
              <FormattedMessage id="EditListingCurriculumDetailsForm.curriculumStyleRequired" />
            )}
          />
          <FieldSelectModern
            className={css.title}
            id="curriculum_type"
            name="curriculum_type"
            label={<FormattedMessage id="EditListingCurriculumDetailsForm.curriculumType" />}
            placeholder={
              <FormattedMessage id="EditListingCurriculumDetailsForm.curriculumTypePlaceholder" />
            }
            options={config.custom.curriculumType}
            validate={fieldSelectModernRequired(
              <FormattedMessage id="EditListingCurriculumDetailsForm.curriculumTypeRequired" />
            )}
          />
          <FieldSelectModern
            className={css.title}
            id="curriculum_resource_type"
            name="curriculum_resource_type"
            label={
              <FormattedMessage id="EditListingCurriculumDetailsForm.curriculumResourceType" />
            }
            placeholder={
              <FormattedMessage id="EditListingCurriculumDetailsForm.curriculumResourceTypePlaceholder" />
            }
            options={config.custom.curriculumResourceType}
            validate={fieldSelectModernRequired(
              <FormattedMessage id="EditListingCurriculumDetailsForm.curriculumResourceTypeRequired" />
            )}
            isMulti
          />
          <FieldSelectModern
            className={css.title}
            id="curriculum_file_format"
            name="curriculum_file_format"
            label={<FormattedMessage id="EditListingCurriculumDetailsForm.curriculumFileFormat" />}
            placeholder={
              <FormattedMessage id="EditListingCurriculumDetailsForm.curriculumFileFormatPlaceholder" />
            }
            options={config.custom.curriculumFileFormat}
            validate={fieldSelectModernRequired(
              <FormattedMessage id="EditListingCurriculumDetailsForm.curriculumFileFormatRequired" />
            )}
            onFieldChange={e => {
              form.change('stock', null);
              form.change('url_link', null);
              form.change('digital_file_type', null);
            }}
          />
          {curType && curFile ? (
            <>
              <FieldSelectModern
                className={css.title}
                id="size"
                name="size"
                label={<FormattedMessage id="EditListingCurriculumDetailsForm.sizes" />}
                placeholder={
                  <FormattedMessage id="EditListingCurriculumDetailsForm.sizesPlaceholder" />
                }
                options={config.custom.sizes}
                validate={fieldSelectModernRequired(
                  <FormattedMessage id="EditListingCurriculumDetailsForm.sizeTypeRequired" />
                )}
                isMulti={true}
              />
              <FieldSelectModern
                className={css.title}
                id="color"
                name="color"
                label={<FormattedMessage id="EditListingCurriculumDetailsForm.colors" />}
                placeholder={
                  <FormattedMessage id="EditListingCurriculumDetailsForm.colorPlaceholder" />
                }
                options={config.custom.colors}
                validate={fieldSelectModernRequired(
                  <FormattedMessage id="EditListingCurriculumDetailsForm.colorTypeRequired" />
                )}
                isMulti={true}
              />
            </>
          ) : (
            ''
          )}
          {values?.curriculum_file_format?.key === 'digital' ? (
            <FieldSelectModern
              className={css.title}
              id="digital_file_type"
              name="digital_file_type"
              label="Digital File Type"
              placeholder="Select Digital File Type"
              options={config.custom.digitalOptions}
              onFieldChange={() => {
                form.change('url_link', null);
              }}
              validate={fieldSelectModernRequired('Digital File Type is required')}
            />
          ) : null}
          {values?.curriculum_file_format?.key === 'physical' ? (
            <FieldTextInput
              className={css.title}
              id="stock"
              name="stock"
              type="number"
              label={<FormattedMessage id="EditListingCurriculumDetailsForm.stock" />}
              placeholder={intl.formatMessage({
                id: 'EditListingCurriculumDetailsForm.stockPlaceholder',
              })}
              validate={required(
                <FormattedMessage id="EditListingCurriculumDetailsForm.stockRequired" />
              )}
            />
          ) : null}
          {values?.digital_file_type?.value === 'downloadable_url_link' ? (
            <FieldTextInput
              className={css.title}
              id="url_link"
              name="url_link"
              type="text"
              label={<FormattedMessage id="EditListingCurriculumDetailsForm.urlLink" />}
              placeholder={intl.formatMessage({
                id: 'EditListingCurriculumDetailsForm.urlLinkPlaceholder',
              })}
              validate={composeValidators(
                required(
                  <FormattedMessage id="EditListingCurriculumDetailsForm.urlLinkRequired" />
                ),
                urlValidate(
                  <FormattedMessage id="EditListingCurriculumDetailsForm.urlLinkInvalid" />
                )
              )}
            />
          ) : (
            values?.digital_file_type?.value && (
              <Material listing={listing} form={form} values={values} />
            )
          )}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingCurriculumDetailsFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingCurriculumDetailsFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingCurriculumDetailsFormComponent);
