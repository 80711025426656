import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import image from './image.jpeg';
import blogImage from './blog.JPG';
import advocate from './advocate.jpg';
import help from './help.JPG';
import { NamedLink, ExternalLink } from '../../components';
import css from './SectionBlogImage.module.css';
const SectionBlogImage = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.mainContainer}>
        <div className={css.imageContainer}>
          <div className={css.textContainer}>
            <div className={css.title}>Blogs</div>
            <p className={css.subTitle}>
              <FormattedMessage id="SectionBlogImage.subTitle" />
            </p>
          </div>
          <NamedLink
            name="BlogPostsPage"
            className={css.location}
            style={{ textDecoration: 'none' }}
          >
            <img src={blogImage} className={css.image} />
          </NamedLink>
        </div>
        <div className={css.imageContainer}>
          <div className={css.textContainer}>
            {/* <div className={css.title}>Become an Education Advocate</div> */}
            <div className={css.title}>
              <FormattedMessage id="SectionJoinTeam.title" />
            </div>
            <p className={css.subTitle}>
              <FormattedMessage id="SectionJoinTeam.subTitle" />
            </p>
          </div>

          <ExternalLink
            href="https://help.hometribe.com/en/articles/6570389-become-a-home-tribe-education-advocate"
            // name="BlogPostsPage"
            className={css.location}
            style={{ textDecoration: 'none' }}
          >
            <img src={advocate} className={css.image} />
          </ExternalLink>
        </div>
        <div className={css.imageContainer}>
          <div className={css.textContainer}>
            <div className={css.title}>
              <FormattedMessage id="SectionHelp.title" />
            </div>
            <p className={css.subTitle}>
              <FormattedMessage id="SectionHelp.subTitle" />
            </p>
          </div>

          <ExternalLink
            href="http://help.hometribe.com"
            className={css.location}
            style={{ textDecoration: 'none' }}
          >
            <img src={help} className={css.image} />
          </ExternalLink>
        </div>
      </div>
    </div>
  );
};

SectionBlogImage.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionBlogImage.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionBlogImage;
